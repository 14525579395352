// import React from "react";
// import { Box, Grid, Typography, useTheme } from "@mui/material";
// import { KeyAssumptionsData } from "../../../../types/CashFlow";
// import { tokens } from "../../../../theme";
// import Slider from "@mui/material/Slider";

// interface KeyAssumptionsProps {
//   keyAssumptions: KeyAssumptionsData | null;
//   updateKeyAssumptions: (updates: Partial<KeyAssumptionsData>) => void;
// }

// const KeyAssumptions: React.FC<KeyAssumptionsProps> = ({
//   keyAssumptions,
//   updateKeyAssumptions,
// }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const handleChange = (field: keyof KeyAssumptionsData, value: number) => {
//     updateKeyAssumptions({ [field]: value });
//   };

//   return (
//     <Box mb={4} alignContent={"start"} flexDirection={"column"} gap={3} display={"flex"} justifyContent={"start"} >
//       <Typography variant="h5" gutterBottom mb={0}>
//         Key Assumptions
//       </Typography>
//       <Grid container alignItems="center" display={"flex"}  spacing={0}>
//         <Grid item xs={12} gap={2} sm={6}>
//           <Typography id="capital-growth-rate-slider" gutterBottom>
//             Capital Growth Rate: {keyAssumptions?.capitalGrowthRate}%
//           </Typography>
//           <Slider
//             aria-labelledby="capital-growth-rate-slider"
//             value={keyAssumptions?.capitalGrowthRate || 0}
//             onChange={(event, newValue) =>
//               updateKeyAssumptions({ capitalGrowthRate: newValue as number })
//             }
//             valueLabelDisplay="auto"
//             step={0.1}
//             marks
//             min={0}
//             max={100}
//             sx={{ color: colors.greenAccent[500],width:'80%' }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Typography id="rental-growth-rate-slider" gutterBottom>
//             Rental Growth Rate: {keyAssumptions?.rentalGrowthRate}%
//           </Typography>
//           <Slider
//             aria-labelledby="rental-growth-rate-slider"
//             value={keyAssumptions?.rentalGrowthRate || 0}
//             onChange={(event, newValue) =>
//               handleChange("rentalGrowthRate", newValue as number)
//             }
//             valueLabelDisplay="auto"
//             step={0.1}
//             marks
//             min={0}
//             max={100}
//             sx={{ color: colors.greenAccent[500],width:'80%' }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Typography id="inflation-rate-slider" gutterBottom>
//             Inflation Rate: {keyAssumptions?.inflationRate}%
//           </Typography>
//           <Slider
//             aria-labelledby="inflation-rate-slider"
//             value={keyAssumptions?.inflationRate || 0}
//             onChange={(event, newValue) =>
//               handleChange("inflationRate", newValue as number)
//             }
//             valueLabelDisplay="auto"
//             step={0.1}
//             marks
//             min={0}
//             max={100}
//             sx={{ color: colors.greenAccent[500],width:'80%' }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Typography id="vacancy-period-slider" gutterBottom>
//             Vacancy Period: {keyAssumptions?.vacancyPeriod} weeks
//           </Typography>
//           <Slider
//             aria-labelledby="vacancy-period-slider"
//             value={keyAssumptions?.vacancyPeriod || 0}
//             onChange={(event, newValue) =>
//               handleChange("vacancyPeriod", newValue as number)
//             }
//             valueLabelDisplay="auto"
//             step={1}
//             marks
//             min={0}
//             max={52}
//             sx={{ color: colors.greenAccent[500],width:'80%' }}
//           />
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default KeyAssumptions;
import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { KeyAssumptionsData } from "../../../../types/CashFlow";
import { tokens } from "../../../../theme";
import Slider from "@mui/material/Slider";

interface KeyAssumptionsProps {
  keyAssumptions: KeyAssumptionsData | null;
  updateKeyAssumptions: (updates: Partial<KeyAssumptionsData>) => void;
}

const KeyAssumptions: React.FC<KeyAssumptionsProps> = ({
  keyAssumptions,
  updateKeyAssumptions,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [capitalGrowthRate, setCapitalGrowthRate] = useState<number>(0);
  const [rentalGrowthRate, setRentalGrowthRate] = useState<number>(0);
  const [inflationRate, setInflationRate] = useState<number>(0);
  const [vacancyPeriod, setVacancyPeriod] = useState<number>(0);

  useEffect(() => {
    if (keyAssumptions) {
      setCapitalGrowthRate(keyAssumptions.capitalGrowthRate);
      setRentalGrowthRate(keyAssumptions.rentalGrowthRate);
      setInflationRate(keyAssumptions.inflationRate);
      setVacancyPeriod(keyAssumptions.vacancyPeriod);
    }
  }, [keyAssumptions]);

  const handleSliderChange = (
    setValue: React.Dispatch<React.SetStateAction<number>>,
    field: keyof KeyAssumptionsData,
    value: number
  ) => {
    setValue(value);
  };

  const handleSliderChangeCommitted = (
    field: keyof KeyAssumptionsData,
    value: number
  ) => {
    updateKeyAssumptions({ [field]: value });
  };
  return (
    <Box mb={4} alignContent={"start"} flexDirection={"column"} gap={3} display={"flex"} justifyContent={"start"} >
      <Typography variant="h5" gutterBottom mb={0}>
        Key Assumptions
      </Typography>
      <Grid container alignItems="center" display={"flex"}  spacing={0}>
        <Grid item xs={12} gap={2} sm={6}>
          <Typography id="capital-growth-rate-slider" gutterBottom>
            Capital Growth Rate: {capitalGrowthRate}%
          </Typography>
          <Slider
            aria-labelledby="capital-growth-rate-slider"
            value={capitalGrowthRate}
            onChange={(event, newValue) =>
              handleSliderChange(setCapitalGrowthRate, "capitalGrowthRate", newValue as number)
            }
            onChangeCommitted={(event, newValue) =>
              handleSliderChangeCommitted("capitalGrowthRate", newValue as number)
            }
            valueLabelDisplay="auto"
            step={0.1}
            marks
            min={0}
            max={100}
            sx={{ color: colors.greenAccent[500], width: '80%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography id="rental-growth-rate-slider" gutterBottom>
            Rental Growth Rate: {rentalGrowthRate}%
          </Typography>
          <Slider
            aria-labelledby="rental-growth-rate-slider"
            value={rentalGrowthRate}
            onChange={(event, newValue) =>
              handleSliderChange(setRentalGrowthRate, "rentalGrowthRate", newValue as number)
            }
            onChangeCommitted={(event, newValue) =>
              handleSliderChangeCommitted("rentalGrowthRate", newValue as number)
            }
            valueLabelDisplay="auto"
            step={0.1}
            marks
            min={0}
            max={100}
            sx={{ color: colors.greenAccent[500], width: '80%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography id="inflation-rate-slider" gutterBottom>
            Inflation Rate: {inflationRate}%
          </Typography>
          <Slider
            aria-labelledby="inflation-rate-slider"
            value={inflationRate}
            onChange={(event, newValue) =>
              handleSliderChange(setInflationRate, "inflationRate", newValue as number)
            }
            onChangeCommitted={(event, newValue) =>
              handleSliderChangeCommitted("inflationRate", newValue as number)
            }
            valueLabelDisplay="auto"
            step={0.1}
            marks
            min={0}
            max={100}
            sx={{ color: colors.greenAccent[500], width: '80%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography id="vacancy-period-slider" gutterBottom>
            Vacancy Period: {vacancyPeriod} weeks
          </Typography>
          <Slider
            aria-labelledby="vacancy-period-slider"
            value={vacancyPeriod}
            onChange={(event, newValue) =>
              handleSliderChange(setVacancyPeriod, "vacancyPeriod", newValue as number)
            }
            onChangeCommitted={(event, newValue) =>
              handleSliderChangeCommitted("vacancyPeriod", newValue as number)
            }
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={52}
            sx={{ color: colors.greenAccent[500], width: '80%' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeyAssumptions;
