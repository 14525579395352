import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button, Card, CardContent, LinearProgress, Typography, useTheme, Tooltip } from '@mui/material';
import { TextField } from 'formik-material-ui';
import * as yup from 'yup';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { UserState } from '../../../types/User';
import { tokens } from '../../../theme';
import InfoIcon from '@mui/icons-material/Info';
const ExpensesSchema = yup.object({
  existingInvestmentLoanAmount: yup.number().required('Existing Investment Loan Amount is required'),
  personalLoansAmount: yup.number().required('Personal Loans Amount is required'),
  creditCardLimit: yup.number().required('Credit Card Limit is required'),
  livingExpenses: yup.number().required('Living Expenses is required'),
  leasePayments: yup.number().required('Lease Payments is required'),
  miscExpenses: yup.number().required('Misc Expenses is required'),
});

interface expenses {

  expenses: any;

}
interface ProfileData {
  expenses: expenses;
}
interface ExpensesProps {
  handleNext: (values: Partial<UserState>) => void;
  handleBack: () => void;
  profiledata: ProfileData;
  formData: UserState;
}

const Expensess: React.FC<ExpensesProps> = ({ handleNext, handleBack, formData, profiledata }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Formik
      initialValues={{
        existingInvestmentLoanAmount: profiledata?.expenses?.expenses[0]?.existing_investment_loan_amount ?? formData.expenses.existingInvestmentLoanAmount,
        personalLoansAmount: profiledata?.expenses?.expenses[0]?.personal_loans_amount ?? formData.expenses.personalLoansAmount,
        creditCardLimit: profiledata?.expenses?.expenses[0]?.credit_card_limit ?? formData.expenses.creditCardLimit,
        livingExpenses: profiledata?.expenses?.expenses[0]?.living_expenses ?? formData.expenses.livingExpenses,
        leasePayments: profiledata?.expenses?.expenses[0]?.lease_payments ?? formData.expenses.leasePayments,
        miscExpenses: profiledata?.expenses?.expenses[0]?.misc_expenses ?? Number(formData.expenses.miscExpenses),
      }}
      validationSchema={ExpensesSchema}
      onSubmit={(values, { setSubmitting }) => {

        handleNext({ expenses: values });
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, submitForm, isSubmitting }) => (
        <div className="flex flex-col justify-center items-center">

          <Typography
            variant="h3"
            gutterBottom
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.primary[100],
            }}
          >
            Expenses
          </Typography>

          <Form className="w-full">
            <Box my={3} display="flex" justifyContent="space-between">
              {/* <Button variant="contained" onClick={handleBack}
                sx={{
                  color: colors.grey[900],
                  backgroundColor: colors.blueAccent[100],
                  padding: '15px 18px 15px 15px',
                  height: '60px',
                  "&:hover": {
                    backgroundColor: colors.blueAccent[300], // Adjust hover color as needed
                  },
                }}>
                <ArrowBackOutlinedIcon sx={{ mr: 1 }} />
                Back
              </Button> */}
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                onClick={handleBack}
                sx={{
                  background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                  color: '#fff',
                  padding: '8px 16px',
                  textAlign: 'center',
                  fontSize: 14,
                  borderRadius: 5,
                  cursor: 'pointer',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    '& .overlay': {
                      width: '100%',
                    },
                  },
                }}
              >
                <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
                <Box
                  className="overlay"
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: 'white',
                    overflow: 'hidden',
                    width: 0,
                    height: '100%',
                    transition: 'all 0.6s ease-in-out 0s',
                  }}
                >
                  <Box
                    className="text"
                    sx={{
                      color: 'black',
                      fontSize: 16,
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
                  </Box>
                </Box>
              </Button>

              {/* <Button variant="contained" disabled={isSubmitting} onClick={submitForm}
                sx={{
                  color: colors.grey[900],
                  backgroundColor: colors.blueAccent[100],
                  padding: '15px 15px 15px 18px',
                  height: '40px',
                  "&:hover": {
                    backgroundColor: colors.blueAccent[300], // Adjust hover color as needed
                  },
                }}>
                Save
                <ArrowForwardIcon sx={{ ml: 1 }} />
              </Button> */}

              <Box sx={{ position: 'relative', width: 200 }} >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  sx={{
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    color: '#fff',
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                      '& .overlay': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  Save
                  <ArrowForwardIcon sx={{ ml: 1 }} />
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'white',
                      overflow: 'hidden',
                      width: 0,
                      height: '100%',
                      transition: 'all 0.6s ease-in-out 0s',
                    }}
                  >
                    <Box
                      className="text"
                      sx={{
                        color: 'black',
                        fontSize: 16,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Save
                      <ArrowForwardIcon sx={{ ml: 1 }} />
                    </Box>
                  </Box>
                </Button>
              </Box>
            </Box>

            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "30px !important",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                borderRadius: "10px"
                // backgroundColor: colors.blueAccent[900],
              }}
            >
              <CardContent sx={{ padding: "10px !important" }}>

                <Grid
                  container
                  spacing={4}
                  alignItems="end"
                >
                  <Grid item xs={12} sm={12} md={6} lg={4}>

                    <Field
                      component={TextField}
                      name="existingInvestmentLoanAmount"

                      label={
                        <Box display="flex" alignItems="center">
                          Existing Investment Loan Amount
                          <Tooltip title="This is the total amount of monthly loan currently outstanding on the investment property.">
                            <InfoIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      }
                      type="number"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Field
                      component={TextField}
                      name="personalLoansAmount"
                      label={
                        <Box display="flex" alignItems="center">
                          Personal Loans Amount
                          <Tooltip title="This is the total amount of monthly Personal loan.">
                            <InfoIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      }
                      // label="Personal Loans Amount"
                      type="number"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Field
                      component={TextField}
                      name="creditCardLimit"
                      label={
                        <Box display="flex" alignItems="center">
                          Credit Card Limit
                          <Tooltip title="This is the total amount of monthly Credit Card Limit.">
                            <InfoIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      }
                      // label="Credit Card Limit"
                      type="number"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Field
                      component={TextField}
                      name="livingExpenses"
                      label={
                        <Box display="flex" alignItems="center">
                          Living Expenses
                          <Tooltip title="This is the total amount of monthly Living Expenses.">
                            <InfoIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      }
                      // label="Living Expenses"
                      type="number"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Field
                      component={TextField}
                      name="leasePayments"
                      label={
                        <Box display="flex" alignItems="center">
                          Lease Payments
                          <Tooltip title="This is the total amount of monthly Lease Payments.">
                            <InfoIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      }
                      // label="Lease Payments"
                      type="number"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Field
                      component={TextField}
                      name="miscExpenses"
                      label={
                        <Box display="flex" alignItems="center">
                          Misc Expenses
                          <Tooltip title="This is the total amount of monthly Misc Expenses.">
                            <InfoIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      }
                      // label="Misc Expenses"
                      type="number"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {isSubmitting && <LinearProgress />}

              </CardContent>
            </Card>

          </Form>
        </div>
      )}
    </Formik>
  );
};

export default Expensess;