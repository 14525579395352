import React, { useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import { Button, useTheme } from "@mui/material";
import AuthenticatedAPI from "../../.././auth_helper";
import { tokens } from "../../../theme";

interface ColorSelectionProps {
  agentId: string;
  onColorUpdate: (color: string) => void;
}

const ColorSelection: React.FC<ColorSelectionProps> = ({
  agentId,
  onColorUpdate,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [color, setColor] = useState<string>("#ffffff");
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const handleChangeComplete = (color: ColorResult) => {
    setColor(color.hex);
  };

  const handleSave = async () => {
    try {
      await updateThemeColorInDatabase(agentId, color);
      onColorUpdate(color);
      setShowPicker(false);
    } catch (error) {
      console.error("Error saving theme color:", error);
    }
  };

  const updateThemeColorInDatabase = async (agentId: string, color: string) => {
    try {
      const response = await AuthenticatedAPI.request(
        "BuyersAgentsAPI",
        "put",
        `/agents/buyers-agents/${agentId}`,
        {
          body: { theme_color: color },
        }
      );
      if (!response || !response.data) {
        throw new Error("Failed to update theme color in database");
      }
      console.log("Theme color updated successfully");
    } catch (error) {
      console.error("Error updating theme color in database:", error);
      throw error;
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          borderRadius: "10px",
          fontSize: "16px",
          fontWeight: "600",
          textTransform: "capitalize",
          color: "#ffffff",
          padding: "10px 40px",
          // backgroundColor: theme.palette.mode === "light" ? "#1F2A40" : "#6c63ff",
        }}
        onClick={() => setShowPicker(!showPicker)}
      >
        {showPicker ? "Close Color Picker" : "Open Color Picker"}
      </Button>
      {showPicker && (
        <div>
          <ChromePicker
            color={color}
            onChangeComplete={handleChangeComplete}
            className="my-4"
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            sx={{
              borderRadius: "10px",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "capitalize",
              color: "#ffffff",
              padding: "10px 40px",
              // backgroundColor: theme.palette.mode === "light" ? "#1F2A40" : "#6c63ff",
            }}
          >
            Save Color
          </Button>
        </div>
      )}
    </div>
  );
};

export default ColorSelection;
