import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { tokens } from "./theme";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { colors } from "@mui/material";
import { Provider } from "react-redux";
import {store} from "./store";
Amplify.configure(config);
const theme = {
  name: "default",
  tokens: {
    colors: {
      background: {
        primary: {
          value: tokens("colors.blueGray.50", "colors.white", "colors.black"),
        },
      },
      colors: {
        background: {
          primary: {
            value: tokens("colors.blueGray.50", "colors.white", "colors.black"),
          },
        },
      },  
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <Provider store={store}>
    <AmplifyProvider theme={theme}>
    
      <App />
    </AmplifyProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
