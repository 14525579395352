import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { ColorModeContext, tokens } from "../../../theme";
import { Container, Box, Grid, Typography, Button, Card, Accordion, AccordionDetails, AccordionSummary, FormControl, Input, OutlinedInput, IconButton, useMediaQuery } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import ServicesSection from '../../components/HomePageComponents/ServicesSection';
import CTASection from "../../components/HomePageComponents/CtaSection/indes";
import FAQSection from "../../components/HomePageComponents/FaqSection";
import { InlineWidget } from 'react-calendly';

const HomePage = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const imageSrc = theme.palette.mode === 'light' ?  '/landinglogo1.svg' : '/logo-white-01.svg';
  const heroImg = theme.palette.mode === 'light' ? '/hero-light.svg' : '/hero-dark.svg';
  const ctaImg = theme.palette.mode === 'light' ? "/women_img-light.png" : "/women_img-dark.png";
  const personalizedInvestmentImg = theme.palette.mode === 'light' ? "/landing_img-light.png" : "/landing_img-dark.png";
  const heroBackgroundColor = theme.palette.mode === 'light' ? "#E9FFFC" : "#182036";
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [showCalendly, setShowCalendly] = useState(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    
    const handleRegisterInterest = () => {
      window.location.href = 'https://forms.gle/wEXXy1WZJXHqMDJJ6';
    };
    const handleGetStarted = () => {
      setShowCalendly(true);
      setTimeout(() => {
        const calendlyWidget = document.querySelector('.calendly-inline-widget');
        if (calendlyWidget) {
          calendlyWidget.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    };

    const handleRedirect =()=>{
      navigate('/dashboard')
    }
  return (
    <div style={{ backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "#141B2D", }}>

      <div className="landing_page">

        <div style={{ backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "#1F2A40", }}>
          <Container maxWidth="xl">
            {/* <nav className="bg-white dark:bg-white w-full z-20 top-0 start-0 border-b border-gray-100 dark:border-gray-100"> */}
            <nav
              style={{
                backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "#1F2A40",
              }}
            >
              <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
                <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse">
                  <img src={imageSrc} className="h-10" alt="Logo" />
                </a>
                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse ml-auto">
                <Box display="flex">
                  <IconButton onClick={colorMode.toggleColorMode}
                      sx={{
                        width: "48px",
                        height: "48px",
                        marginRight: "15px",
                        color: theme.palette.mode === 'light' ? '#5BC0BE !important' : '#5BC0BE !important',
                        backgroundColor: theme.palette.mode === 'light' ? 'rgb(91 192 190 / 25%) !important' : '#EFF9F8 !important',
                      }}
                    >
                      {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                      ) : (
                        <LightModeOutlinedIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Link to="/dashboard">
                    <Button variant="contained" color="secondary" startIcon={<LockIcon />}
                      sx={{
                        borderRadius: "10px",
                        fontSize: "14px",
                        textTransform: "capitalize",
                        color: "#ffffff",
                        padding: "12px 50px",
                      }}>Login</Button>
                    </Link>
                  <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                  </button>
                </div>
                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1 mx-20" id="navbar-sticky">
                  <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 bg-transparent">
                    <li>
                      {/* <a href="#" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-gray-900 md:dark:hover:bg-transparent dark:border-gray-700">Home</a> */}
                      <a href="#" style={{
                        display: "block",
                        color: theme.palette.mode === 'light' ? "#000000" : "#ffffff",
                        backgroundColor: "transparent",
                      }}>Home</a>
                    </li>
                    {/* <li>
                      <a href="#" style={{
                        display: "block",
                        color: theme.palette.mode === 'light' ? "#000000" : "#ffffff",
                        backgroundColor: "transparent",
                      }}>Marketplace</a>
                    </li> */}
                    <li>
                      <a href="#" style={{
                        display: "block",
                        color: theme.palette.mode === 'light' ? "#000000" : "#ffffff",
                        backgroundColor: "transparent",
                      }}>Investor.io</a>
                    </li>
                    <li>
                      <a href="#" style={{
                        display: "block",
                        color: theme.palette.mode === 'light' ? "#000000" : "#ffffff",
                        backgroundColor: "transparent",
                      }}>Our Story</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </Container>
        </div>

        <Box className="heroSection"
          sx={{
            backgroundColor: heroBackgroundColor,
          }}>

          <div className="heroimg" style={{ backgroundImage: `url(${heroImg})` }} ></div>
          <Container maxWidth="xl">
            <Grid container spacing={3} columns={12}>
              <Grid item md={12} textAlign={'center'}>
                <Typography variant="h1" gutterBottom sx={{ fontSize: "70px", fontWeight: "700", color: colors.grey[100] }} mb={2}>
                  Unlock Your Financial <br/>Investment Opportunity
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "20px", fontWeight: "400", color: colors.grey[100] }} mb={9}>
                  Build a portfolio of real estate assets geared for growth and personalized for your goals.
                </Typography>
                <Button variant="contained" color="secondary" onClick={()=>handleGetStarted()}
                  sx={{
                    borderRadius: "10px",
                    fontSize: "17px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    color: "#ffffff",
                    padding: "12px 50px",
                  }}>Get Started</Button>
              </Grid>
            </Grid>
          </Container>

        </Box>

        {showCalendly && (
          <Box className="calendlySection" py={12}>
            <Container maxWidth="xl">
              <Typography variant="h2" gutterBottom sx={{ fontSize: "36px", fontWeight: "700", color: colors.grey[100], textAlign: "center" }} mb={4}>
                Schedule Your Consultation
              </Typography>
              <InlineWidget 
                url="https://calendly.com/amol-investar/30min"
                styles={{
                  height: '700px',
                  color: colors.grey[100],
                }}
              />
            </Container>
          </Box>
        )}

<ServicesSection />

<CTASection/>

        <Box className="showcaseSection" pt={12}>       
          <Container maxWidth="xl">  
            <Grid container spacing={3} columns={12} alignItems={"center"}>
              <Grid item sm={12} md={6} pr={4} mb={12}>
                <Typography variant="h1" gutterBottom sx={{ fontSize: "42px", fontWeight: "700", color: colors.grey[100], letterSpacing: "-1px" }} mb={2}>
                  Latest Financial News and Trends
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "16px", fontWeight: "400", color: colors.grey[100] }} mb={4}>
                  Stay Updated with Real-Time Market Data long-term drivers of the Australian economy. In today's macroeconomic environment, we believe the Investror portfolio is as well-positioned as any in the world to deliver stable, market-beating returns.
                </Typography>
                <Button variant="contained" color="secondary"onClick={()=>handleRedirect()}

                  sx={{
                    borderRadius: "10px",
                    fontSize: "17px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    color: "#ffffff",
                    padding: "12px 50px",
                  }}>Know More</Button>
              </Grid>
              <Grid item sm={12} md={6} textAlign={"center"} mb={12}>
                <Box>
                  <img
                    className="h-auto max-w-full"
                    alt="profile-user"
                    src={`/landing_chart-01.svg`}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container> 
          <Container maxWidth="xl">  
            <Grid container spacing={3} columns={12} alignItems={"center"}>
              <Grid item sm={12} md={6} textAlign={"center"} mb={12}>
                <Box className="">
                  <img
                    className="h-auto max-w-full"
                    alt="profile-user"
                    src={`/landing_img-02.png`}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} pl={4} mb={12}>
                <Typography variant="h1" gutterBottom sx={{ fontSize: "42px", fontWeight: "700", color: colors.grey[100], letterSpacing: "-1px" }} mb={2}>
                  We Are Ready To Make Your <br/>Dreams Come True
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "16px", fontWeight: "400", color: colors.grey[100] }} mb={4}>
                  Our $7+ billion investment portfolio* aims to harness the most powerful long-term drivers of the Australian economy. In today's macroeconomic environment, we believe the Investor portfolio is as well-positioned as any in the world to deliver stable, market-beating returns. 
                </Typography>
                <Button variant="contained" color="secondary"onClick={()=>handleRedirect()}
                  sx={{
                    borderRadius: "10px",
                    fontSize: "17px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    color: "#ffffff",
                    padding: "12px 50px",
                  }}>Know More</Button>
              </Grid>
            </Grid>
          </Container> 

          <Box className="showcase_inner" pt={12} mb={12}
            sx={{
              backgroundColor: theme.palette.mode === 'light' ? "#e9fffc" : "#1F2A40",         
            }}
          >       
            <Container maxWidth="xl">  
              <Grid container spacing={3} columns={12} alignItems={"center"}>
                <Grid item sm={12} md={6} pr={4} mb={12}>
                  <Typography variant="h1" gutterBottom sx={{ fontSize: "42px", fontWeight: "700", color: colors.grey[100], letterSpacing: "-1px" }} mb={2}>
                    Personalized Investment <br/>Recommendations
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{ fontSize: "16px", fontWeight: "400", color: colors.grey[100] }} mb={4}>
                    Our $7+ billion investment portfolio* aims to harness the most powerful long-term drivers of the Australian. economy. In today's macroeconomic environment, we believe the Investro portfolio is as well-positioned as any in the world to deliver stable, market-beating returns.
                  </Typography>
                  {/* <Typography variant="h6" gutterBottom sx={{ fontSize: "16px", fontWeight: "700", color: colors.grey[100] }} mb={0}>
                    Daniel Craig
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{ fontSize: "16px", fontWeight: "400", color: colors.grey[700] }} mb={1}>
                    CEO and Co-Founder @ Investor
                  </Typography> */}
                </Grid>
                <Grid item sm={12} md={6} textAlign={"center"} mb={12}>
                  <Box>
                    <img
                      className="h-auto max-w-full"
                      alt="profile-user"
                      src={personalizedInvestmentImg}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container> 
          </Box>
     
          <Container maxWidth="xl">              
            <Grid container spacing={3} columns={12} alignItems={"center"}>
              <Grid item sm={12} md={6} textAlign={"center"} mb={12}>
                <Box>
                  <img
                    className="h-auto max-w-full"
                    alt="profile-user"
                    src={`/landing_chart-02.svg`}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} mb={12}>
                <Box pl={8}>
                  <Typography variant="h1" gutterBottom sx={{ fontSize: "42px", fontWeight: "700", color: colors.grey[100], letterSpacing: "-1px" }} mb={2}>
                    Automated Portfolio <br/>Analysis
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{ fontSize: "16px", fontWeight: "400", color: colors.grey[100] }} mb={4}>
                  Experience comprehensive, hands-free evaluation of your investment holdings with our Automated Portfolio Analysis. This intelligent system continuously monitors your assets, providing real-time insights into performance, diversification, and risk exposure. Receive regular updates and actionable recommendations to optimize your portfolio without the need for constant manual oversight.                  </Typography>                
                  <Button variant="contained" color="secondary" onClick={()=>handleRedirect()}
                    sx={{
                      borderRadius: "10px",
                      fontSize: "17px",
                      fontWeight: "700",
                      textTransform: "capitalize",
                      color: "#ffffff",
                      padding: "12px 50px",
                    }}>Know More</Button>
                  </Box>
              </Grid>
            </Grid>
          </Container> 
        </Box>


        <FAQSection expanded={expanded} handleChange={handleChange} />

        <Box className="footerSection" pt={12}
          sx={{
            backgroundColor: theme.palette.mode === 'light' ? "#5BC0BE" : "#1F2A40",
          }}
        >       
          <Container maxWidth="xl">  
            <Grid container spacing={3} columns={12}>
              <Grid item sm={12} md={4} lg={4} mb={8}>
                <Box className="lg:pr-20 lg:max-w-sm">
                  <a href="#">
                    <img src="/logo-light.svg" alt="Logo" />
                  </a>                
                  <Typography variant="h6" gutterBottom sx={{ fontSize: "16px", fontWeight: "400", color: "#EAECF0" }} mt={4} mb={0}>
                  Your gateway to intelligent real estate investing. Our comprehensive platform leverages cutting-edge technology to analyze market trends, optimize portfolios, and uncover high-potential opportunities. Experience a new era of smart, data-driven real estate investment that adapts to your goals and maximizes your returns in an ever-evolving market landscape.                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={12} md={5} lg={5} mb={0}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={12} md={5} mb={8}>
                    <Typography variant="h5" gutterBottom sx={{ fontSize: "13px", fontWeight: "700", color: "#FFFFFF", textTransform: "uppercase" }} mb={3} >
                      Company
                    </Typography>            
                    <ul style={{
                      color: "#EAECF0",
                    }}>
                         <li 
                          style={{ cursor: 'pointer' }}onClick={handleRegisterInterest}>Register your interest</li>
                      <li>About</li>
                      <li>Features</li>
                      <li>Works</li>
                      <li>Career</li>
                    </ul>
                  </Grid>
                  <Grid item sm={12} md={7} mb={8}>
                    <Typography variant="h5" gutterBottom sx={{ fontSize: "13px", fontWeight: "700", color: "#FFFFFF", textTransform: "uppercase" }} mb={3}>
                      Help
                    </Typography>            
                    <ul style={{
                      color: "#EAECF0",
                    }}>
                      <li>Customer Support</li>
                      <li>FAQs</li>
                      <li>Terms & Conditions</li>
                      <li>Privacy Policy</li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item sm={12} md={3} mb={8}>
                <Typography variant="h5" gutterBottom sx={{ fontSize: "13px", fontWeight: "700", color: "#FFFFFF", textTransform: "uppercase" }} mb={3}>
                Socials
                </Typography> 
                <div className="flex space-x-4">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                <FacebookIcon fontSize="large" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600">
                <TwitterIcon fontSize="large" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-pink-500 hover:text-pink-700">
                <InstagramIcon fontSize="large" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-900">
                <LinkedInIcon fontSize="large" />
            </a>
        </div>

                {/* <form noValidate autoComplete="off">
                  <FormControl sx={{ width: "100%" }}>
                    <OutlinedInput placeholder="Enter your email address" 
                      sx={{
                        borderRadius: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "rgb(255 255 255 / 15%)",
                        color: theme.palette.mode === 'light' ? "#000000" : "#cccccc",
                        boxShadow: "none",
                        marginBottom: "12px",
                      }}/>
                    <Button variant="contained"
                      sx={{
                        borderRadius: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        backgroundColor: "rgb(255 255 255 / 15%)",
                        color: "#ffffff",
                        padding: "12px 50px",
                        boxShadow: "none",
                      }}>Subscribe Now</Button>
                  </FormControl>
                </form> */}
              </Grid>
            </Grid>
            
            <Grid container spacing={0} columns={12} alignItems={"center"}>
              <Grid item sm={12}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "14px", fontWeight: "400", color: "#ffffff", borderTop: "1px solid rgb(191 230 230 / 50%)", textAlign: "center", }} py={4}>
                  © Copyright 2024, All Rights Reserved by Investor.IO
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>

      </div>


      {/* <div className="bg-gray-100 min-h-screen flex items-center justify-center pt-20 mt-20"> */}
        {/* <div className=" flex fixed  top-[400px] justify-center items-center w-full max-w-lg ">
          <div className="absolute  top-0 -left-4 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
          <div className="absolute  top-0 right-8 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
          <div className="absolute  -bottom-8 left-20 w-72 h-72 bg-orange-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
        </div> */}
        {/* <div className="flex z-50 flex-col py-3 gap-10">
          <div className="flex  justify-between items-center px-16">
            <img src="/performance.svg" alt="Logo" className="w-full h-full" />
            <div className=" flex flex-col m-8 relative space-y-4  ">
              <div className="bg-white shadow-md opacity-60 p-10 rounded-lg flex items-start justify-between space-x-8 transform transition-transform duration-1000 hover:scale-105">
                <div className="flex-1 flex gap-5 flex-col text-left">
                  <div>
                    <h3 className="text-2xl text-gray-800">
                      Understand your current property investment position
                    </h3>
                  </div>
                  <span className="text-md text-gray-800">
                    We review your existing property portfolio performance and
                    money management to provide clarity around your current
                    position
                  </span>
                  <button className="border-2 border-gray-700 w-[150px] hover:bg-[#543da2] text-black hover:text-white font-bold py-4 px-7 rounded-3xl transition-colors duration-300">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex  justify-between items-center px-16">
            <img src="/fineance.svg" alt="Logo" className="w-full h-full" />
            <div className=" flex flex-col m-8 relative space-y-4  ">
            <div className="bg-white shadow-md opacity-60 p-10 rounded-lg flex items-start justify-between space-x-8 transform transition-transform duration-1000 hover:scale-105">
                <div className="flex-1 flex gap-5 flex-col text-left">
                  <div>
                    <h3 className="text-2xl text-gray-800">
                      Understand your current property investment position
                    </h3>
                  </div>
                  <span className="text-md text-gray-800">
                    We review your existing property portfolio performance and
                    money management to provide clarity around your current
                    position
                  </span>
                  <Link to ="https://buy.stripe.com/bIY8xf7vGcLj2cM144">
                  <button className="border-2 border-gray-700 w-[150px] hover:bg-[#543da2] text-black hover:text-white font-bold py-4 px-7 rounded-3xl transition-colors duration-300">
                    Subscribe
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex  justify-between items-center px-16">
            <img
              src="/undraw_business_analytics_re_tfh3.svg"
              alt="Logo"
              className="w-full h-full"
            />
            <div>
              <div className=" flex flex-col m-8 relative space-y-4  ">
                <div className="bg-[#fffcfc] shadow-md  p-10 rounded-lg flex items-start justify-between space-x-8">
                  <div className="flex-1 ">
                    <div className=" h-4 w-40 bg-gray-300 rounded transform transition-transform duration-1000 hover:scale-105"></div>
                  </div>
                  <div>
                    <div className="h-4 w-24 rounded-lg bg-orange-300 transform transition-transform duration-1000 hover:scale-105"></div>
                  </div>
                </div>
              </div>
              <div className=" flex flex-col m-8 relative space-y-4  ">
              <div className="bg-white shadow-md opacity-60 p-10 rounded-lg flex items-start justify-between space-x-8 transform transition-transform duration-1000 hover:scale-105">
                <div className="flex-1 flex gap-5 flex-col text-left">
                  <div>
                    <h3 className="text-2xl text-gray-800">
                      Understand your current property investment position
                    </h3>
                  </div>
                  <span className="text-md text-gray-800">
                    We review your existing property portfolio performance and
                    money management to provide clarity around your current
                    position
                  </span>
                  <button className="border-2 border-gray-700 w-[150px] hover:bg-[#543da2] text-black hover:text-white font-bold py-4 px-7 rounded-3xl transition-colors duration-300">
                    Get Started
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="flex text-center justify-center items-center ">
            <div className="bg-white shadow-md gap-6 flex w-[350px] flex-col opacity-60 p-10 rounded-lg  items-center justify-center space-x-8 transform transition-transform duration-1000 hover:scale-105">
              <h3 className="text-2xl ml-5 text-gray-800"> Sign In</h3>
              <Link to="/dashboard">
              <button className="border-2 border-[#293C45] w-[150px] hover:bg-[#293C45] hover:border-gray-100 text-black hover:text-white font-bold py-4 px-7 rounded-3xl">
                Sign In
              </button>
              </Link> 
            </div>
          </div>

          <div className="bottom-img-style "></div>
        </div>
      </div> */}


      {/* <div className="bg-gray-100 min-h-screen flex items-center justify-center pt-20 mt-20"> */}
        {/* <div className=" flex fixed  top-[400px] justify-center items-center w-full max-w-lg ">
          <div className="absolute  top-0 -left-4 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
          <div className="absolute  top-0 right-8 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
          <div className="absolute  -bottom-8 left-20 w-72 h-72 bg-orange-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
        </div> */}
        {/* <div className="flex z-50 flex-col py-3 gap-10">
          <div className="flex  justify-between items-center px-16">
            <img src="/performance.svg" alt="Logo" className="w-full h-full" />
            <div className=" flex flex-col m-8 relative space-y-4  ">
              <div className="bg-white shadow-md opacity-60 p-10 rounded-lg flex items-start justify-between space-x-8 transform transition-transform duration-1000 hover:scale-105">
                <div className="flex-1 flex gap-5 flex-col text-left">
                  <div>
                    <h3 className="text-2xl text-gray-800">
                      Understand your current property investment position
                    </h3>
                  </div>
                  <span className="text-md text-gray-800">
                    We review your existing property portfolio performance and
                    money management to provide clarity around your current
                    position
                  </span>
                  <button className="border-2 border-gray-700 w-[150px] hover:bg-[#543da2] text-black hover:text-white font-bold py-4 px-7 rounded-3xl transition-colors duration-300">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex  justify-between items-center px-16">
            <img src="/fineance.svg" alt="Logo" className="w-full h-full" />
            <div className=" flex flex-col m-8 relative space-y-4  ">
            <div className="bg-white shadow-md opacity-60 p-10 rounded-lg flex items-start justify-between space-x-8 transform transition-transform duration-1000 hover:scale-105">
                <div className="flex-1 flex gap-5 flex-col text-left">
                  <div>
                    <h3 className="text-2xl text-gray-800">
                      Understand your current property investment position
                    </h3>
                  </div>
                  <span className="text-md text-gray-800">
                    We review your existing property portfolio performance and
                    money management to provide clarity around your current
                    position
                  </span>
                  <Link to ="https://buy.stripe.com/bIY8xf7vGcLj2cM144">
                  <button className="border-2 border-gray-700 w-[150px] hover:bg-[#543da2] text-black hover:text-white font-bold py-4 px-7 rounded-3xl transition-colors duration-300">
                    Subscribe
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex  justify-between items-center px-16">
            <img
              src="/undraw_business_analytics_re_tfh3.svg"
              alt="Logo"
              className="w-full h-full"
            />
            <div>
              <div className=" flex flex-col m-8 relative space-y-4  ">
                <div className="bg-[#fffcfc] shadow-md  p-10 rounded-lg flex items-start justify-between space-x-8">
                  <div className="flex-1 ">
                    <div className=" h-4 w-40 bg-gray-300 rounded transform transition-transform duration-1000 hover:scale-105"></div>
                  </div>
                  <div>
                    <div className="h-4 w-24 rounded-lg bg-orange-300 transform transition-transform duration-1000 hover:scale-105"></div>
                  </div>
                </div>
              </div>
              <div className=" flex flex-col m-8 relative space-y-4  ">
              <div className="bg-white shadow-md opacity-60 p-10 rounded-lg flex items-start justify-between space-x-8 transform transition-transform duration-1000 hover:scale-105">
                <div className="flex-1 flex gap-5 flex-col text-left">
                  <div>
                    <h3 className="text-2xl text-gray-800">
                      Understand your current property investment position
                    </h3>
                  </div>
                  <span className="text-md text-gray-800">
                    We review your existing property portfolio performance and
                    money management to provide clarity around your current
                    position
                  </span>
                  <button className="border-2 border-gray-700 w-[150px] hover:bg-[#543da2] text-black hover:text-white font-bold py-4 px-7 rounded-3xl transition-colors duration-300">
                    Get Started
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="flex text-center justify-center items-center ">
            <div className="bg-white shadow-md gap-6 flex w-[350px] flex-col opacity-60 p-10 rounded-lg  items-center justify-center space-x-8 transform transition-transform duration-1000 hover:scale-105">
              <h3 className="text-2xl ml-5 text-gray-800"> Sign In</h3>
              <Link to="/dashboard">
              <button className="border-2 border-[#293C45] w-[150px] hover:bg-[#293C45] hover:border-gray-100 text-black hover:text-white font-bold py-4 px-7 rounded-3xl">
                Sign In
              </button>
              </Link> 
            </div>
          </div>

          <div className="bottom-img-style "></div>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default HomePage;
