import React from "react";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../../../theme";

const CTASection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const ctaImg = theme.palette.mode === 'light' ? "/women_img-light.png" : "/women_img-dark.png";

  return (
    <Box className="ctaSection" py={isMobile ? 4 : 8}>    
      <Box className="cta_inner"
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? "#5BC0BE" : "#1F2A40",  
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >         
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={5} sx={{ 
            display: { xs: 'none', md: 'block' },
            position: 'relative',
            height: { md: '400px' }
          }}>
            <img 
              src={ctaImg} 
              alt="CTA" 
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          </Grid>
          <Grid item xs={12} md={7} p={isMobile ? 3 : { xs: 4, md: 6 }}>
            <Box px={isMobile ? 0 : { xs: 2, md: 6 }}>    
              <Typography variant="h1" gutterBottom sx={{ 
                fontSize: isMobile ? "32px" : isTablet ? "42px" : "55px", 
                fontWeight: "700", 
                color: "#ffffff",
                lineHeight: 1.2,
              }} mb={2}>
                Save Time & Stress By Letting Us Do All The Hard Work
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ 
                fontSize: isMobile ? "18px" : isTablet ? "24px" : "30px", 
                fontWeight: "400", 
                color: "#ffffff",
              }} mb={0}>
                Insight, Making Investment Easy.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CTASection;