// DisclaimerPage.tsx

import React from 'react';

const DisclaimerPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 flex justify-center items-center">
      <div className="bg-white max-w-4xl w-full p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Disclaimer</h1>

        <div className="text-gray-700">
          <p className="mb-4">
            The material and content provided on this website is for informational purposes only
            and general in nature and must not be used or relied upon as a substitute for obtaining
            independent business, legal, investment, or financial advice from appropriately qualified
            professional persons based on the viewers’ and listeners’ particular circumstances.
          </p>
          <p className="mb-4">
            All ideas, information, and examples shared by the presenters relate to past performance
            and future projections which are not a reliable indicator of future performance.
          </p>
          <p className="mb-4">
            The authors, presenters, contributors, administrators, and their related parties cannot
            in any way whatsoever be held liable in any claim for damages as a result of viewing,
            listening, reading, or acting on any of the information contained herein; and are not
            responsible for any errors or omissions, or for the results obtained from using this
            information.
          </p>
          <p className="mb-4">
            This website, its owners, and its content providers do not make any representations or
            warranties of any kind, express or implied, about the completeness, accuracy,
            reliability, suitability, or availability with respect to the website or the information,
            products, services, or related graphics contained on the website for any purpose. Any
            reliance you place on such information is therefore strictly at your own risk. Users are
            advised to independently verify any information before relying on it.
          </p>
          <p className="mb-4">
            Property market information is based on data and sources believed to be reliable but
            may not reflect the current state of the market. Users should perform their own due
            diligence before making any property-related decisions.
          </p>
          <p className="mb-4">
            Investing in financial products carries a risk of loss. Past performance is not indicative
            of future results. Investment values can fluctuate, and investors may not get back the
            amount originally invested. Users should carefully consider their investment objectives,
            experience, and risk appetite before making investment decisions.
          </p>
          <p className="mb-4">
            Interest rates and financial product information are subject to change without notice
            and may not apply in all circumstances. Users should verify current rates and terms
            directly with the financial institutions offering the products. We do not endorse or
            recommend any specific financial products or institutions.
          </p>
          <p className="mb-4">
            This website may include links to other websites and/or resources provided by third
            parties. These links are provided for your convenience only. We have no control over the
            content of those sites or resources and accept no responsibility for them or for any loss
            or damage that may arise from your use of them. The inclusion of any links does not
            necessarily imply a recommendation or endorse the views expressed within them.
          </p>
          <p className="mb-4">
            Every effort has been made to ensure the accuracy of the information contained on this
            website. However, as the market is continually evolving, no guarantees are given that
            the information contained within this website is currently correct. Investar shall bear no
            liability to any person or entity with respect to any inaccuracy, misleading information,
            loss or damage caused directly or indirectly by the information contained on the site.
          </p>
          <p className="mb-4">
            By using this website, you agree to the terms and conditions of Investar. Unauthorised
            reproduction or sharing of copyrighted materials from this website will result in access
            revocation, and we reserve the right to enforce our legal rights to address such
            breaches. If you do not agree with any part of this disclaimer, refrain from using this
            website. We reserve the right to modify this disclaimer at any time without notice, and
            your continued use of the website implies acceptance of these changes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerPage;
