import React, { useState } from "react";
import { Storage } from "aws-amplify";
import { Button, CircularProgress, useTheme } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AuthenticatedAPI from "../../.././auth_helper";
import { tokens } from "../../../theme";

interface LogoUploadProps {
  agentId: string;
  onLogoUpdate: (logoUrl: string) => void;
}

const LogoUpload: React.FC<LogoUploadProps> = ({ agentId, onLogoUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) return;
    setUploading(true);
    try {
      const result = await Storage.put(`logos/${agentId}`, file, {
        contentType: file.type,
      });
      // Generate the public URL without query parameters
      const publicLogoUrl = `https://investar-buyers-agent195537-dev.s3.ap-southeast-2.amazonaws.com/public/logos/${result.key.split('/').pop()}`;
      await updateLogoInDatabase(agentId, publicLogoUrl);
      onLogoUpdate(publicLogoUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setUploading(false);
  };

  const updateLogoInDatabase = async (agentId: string, logoUrl: string) => {
    try {
      const response = await AuthenticatedAPI.request(
        "BuyersAgentsAPI",
        "put",
        `/agents/buyers-agents/${agentId}`,
        {
          body: { logo_url: logoUrl },
        }
      );
      if (!response || !response.data) {
        throw new Error("Failed to update logo URL in database");
      }
      console.log("Logo URL updated successfully");
    } catch (error) {
      console.error("Error updating logo URL in database:", error);
      throw error;
    }
  };

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="logo-upload"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="logo-upload">
        <Button
          variant="contained"
          component="span"
          color="secondary"
          startIcon={<CloudUploadIcon />}
          sx={{
            borderRadius: "10px",
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "capitalize",
            color: "#ffffff",
            padding: "10px 40px",
            // backgroundColor: theme.palette.mode === "light" ? "#1F2A40" : "#6c63ff",
          }}
        >
          Choose Logo
        </Button>
      </label>
      {file && (
        <Button
          onClick={handleUpload}
          disabled={uploading}
          variant="contained"
          color="secondary"
          style={{ marginLeft: "10px" }}
        >
          {uploading ? <CircularProgress size={24} /> : "Upload"}
        </Button>
      )}
    </div>
  );
};

export default LogoUpload;
