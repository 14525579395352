import React, { useEffect, useRef, useState } from "react";

import { Bar, Pie } from "react-chartjs-2";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import ScoreIcon from '@mui/icons-material/Score';
import BusinessIcon from '@mui/icons-material/Business';
import clsx from 'clsx';
import './graph/index.css'
import InfoIcon from '@mui/icons-material/Info';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Legend, ArcElement, } from "chart.js";
import {
  Typography, Grid, Card,

  Button,
  useTheme,
  Box,
  Tooltip,
  Stack,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  CircularProgress,
  IconButton,

} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePersonalInfo,
  updateBankingInfo,
} from "../../../features/userSlice";
import { Auth } from "aws-amplify";
import { RootState, AppDispatch } from "../../../store";
import { tokens } from "../../../theme";
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import AuthenticatedAPI from "../../../auth_helper";
import { BankingInfo } from "../../../types/User";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PropertyGrowth from "./graph/Propertygrowth";
import Propertyyields from "./graph/Propertyyields";
import BorrowableEquity from "./graph/BorrowableEquity";
import LoanToValueRatioChart from "./graph/LoanToValueRatioChart";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { apiKey, StreetViewImage, useStreetViewImage } from "../../../hooks/properties/properties";
import PortfolioWealth from "./graph/PortfolioWealth";
import { Pdf } from "./pdf";
import { formatCurrency } from "../../../hooks/buyerAgent";
import EmailCompositionModal from "../../components/EmailCompositionModal";

// import Investar from "./investar";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  // Tooltip,
  Legend,
  ArcElement
);

Amplify.configure(awsconfig);

interface Bankinginfo {
  bankingInfo: BankingInfo;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface UserStrategy {
  structure?: { investmentType: any };
  ownership: any;
  funding?: {
    lvr: any;
    canBuyAdditionalIp: any;
  };
  recommendations?: {
    growthYoy: any;
    purchasePriceBracket: any[];
    rentalYield: any[];
  };

  [key: string]: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface Property {
  propertyId: number;
  address: string;
  capRate: number;
  classification: string;
  dscr: number;
  ltv: number;
  roi: number;
}
export interface ProfileData {
  financialGoal?: any[];

  familyInfo?: { dependentKids: any[]; earningMembers: any[] };
  employmentInfo?: any[];
  currentPortfolio?: { investmentProperties: any[] };
  investmentStrategy?: any;
  [key: string]: any;
}

const DashboardContent = ({ user }: any, { bankingInfo }: Bankinginfo) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userProfile = useSelector(
    (state: RootState) => state.user.personalInfo
  );

  const userBankingInfo = useSelector<RootState, BankingInfo>(
    (state) => state.user.bankingInfo
  );
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const [userFirstName, setUserFirstName] = useState("");
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState("recommendation");
  const [activeSubIndex, setActiveSubIndex] = React.useState("growth");
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [investarScore, setInvestarScore] = React.useState(0);
  const [propertyImages, setPropertyImages] = useState<string[]>([]);
  const [addresses, setAddresses] = useState<string[]>([])
  // console.log('address :>> ', address);
  const [portfolioData, setPortfolioData] = useState<Property[]>([]);
  const [userStrategy, setUserStrategy] = React.useState<UserStrategy | null>(
    null
  );
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  // const [currentInvestor, setCurrentInvestor] = useState<Buyer | null>(null);
  const [buttonLabel, setButtonLabel] = useState("DOWNLOAD REPORTS");
  const [percentage, setPercentage] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const imageUrl = useStreetViewImage(address)
  // console.log('userBankingInfo', userBankingInfo)
  const auth = useSelector((state: RootState) => state.user.personalInfo);
  const meterImg = theme.palette.mode === 'dark' && investarScore <= 25 ? '/guage_d-01.png' :
    theme.palette.mode === 'dark' && investarScore >= 25 && investarScore <= 50 ? '/guage_d-02.png' :
      theme.palette.mode === 'dark' && investarScore >= 50 && investarScore <= 75 ? '/guage_d-03.png' :
        theme.palette.mode === 'dark' && investarScore >= 75 && investarScore <= 100 ? '/guage_d-04.png' :
          theme.palette.mode === 'light' && investarScore <= 25 ? '/guage_w-01.png' :
            theme.palette.mode === 'light' && investarScore >= 25 && investarScore <= 50 ? '/guage_w-02.png' :
              theme.palette.mode === 'light' && investarScore >= 50 && investarScore <= 75 ? '/guage_w-03.png' :
                theme.palette.mode === 'light' && investarScore >= 75 && investarScore <= 100 ? '/guage_w-04.png' : '';

  const handlePipelineTitle = (value: any) => {
    const defaultValue =
      value == "structure"
        ? "owner_occupied"
        : value == "ownership"
          ? "trust"
          : value == "funding"
            ? "lender"
            : "growth";
    // console.log('value',value)
    setActiveIndex(value);
    handleSubTitle(defaultValue);
  };
  const handleSubTitle = (value: any) => {
    // console.log(value,'-----')
    setActiveSubIndex(value);
  };

  const location = useLocation()

  useEffect(() => {
    if (userProfile.id && location.pathname === '/login') {
      navigate('/dashboard')
    }
  }, [location.pathname, userProfile.id])
  const getPortFolioAnalysis = async () => {
    const data1 = await Auth?.currentAuthenticatedUser().then(async (res) => {
      const response = await AuthenticatedAPI.request(
        "InvestmentAPI",
        "get",
        `/api/portfolio-analysis/${res.username}`
      );
      setPortfolioData(response.data)

    })
  }

  useEffect(() => {
    getPortFolioAnalysis();
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      const user = await Auth?.currentAuthenticatedUser();
      // console.log('user1111111', user)
      setUserFirstName(user.attributes.given_name);
    };
    getUserInfo();
  }, [userFirstName]);

  const openTransactionsPopup = (url: any) => {
    const consentUrl =
      userBankingInfo.consentUrl?.toString() || url?.toString();
    // Open the URL in a popup window
    if (consentUrl) {
      // window.open(transactionsUrl, '_blank', 'width=600,height=400,resizable=yes');
      window.location.href = consentUrl;
    } else {
      console.error("No consent URL found"); // Handle error case if needed
    }
  };

  // pagination
  const handleClickPrev = () => {
    setCurrentPage((currentPage) => Math.max(0, currentPage - 1));
  };
  const handleClickNext = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const getUserStrategy = async () => {
    const userDatastart = await Auth?.currentAuthenticatedUser().then(
      async (res) => {
        const response = await AuthenticatedAPI.request(
          "InvestmentAPI",
          "get",
          `/api/strategy/${res.username}`
        );
        setUserStrategy(response?.data[0]?.strategy);
        if (!response) {
          throw new Error("Failed to fetch user profile");
        }
      }
    );
  };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userAttributes = await Auth.userAttributes(user);
        const id =
          userAttributes.find((attr) => attr.Name === "sub")?.Value ?? "";
        const email =
          userAttributes.find((attr) => attr.Name === "email")?.Value ?? "";
        const phone_number =
          userAttributes.find((attr) => attr.Name === "phone_number")?.Value ??
          "";
        const company_name =
          userAttributes.find((attr) => attr.Name === "company_name")?.Value ??
          "";
        const first_name =
          userAttributes.find((attr) => attr.Name === "given_name")?.Value ??
          "";
        const last_name =
          userAttributes.find((attr) => attr.Name === "family_name")?.Value ??
          "";

        dispatch(
          updatePersonalInfo({
            id,
            email,
            phone_number,

            first_name,
            last_name,
          })
        );
        fetchUserBankDetails(id);
        setUserFirstName(first_name);
        getUserProfile();
        getUserStrategy();
      } catch (error) {
      }
    };

    fetchUserData();
  }, [dispatch]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 15,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 15,
      backgroundColor: theme.palette.mode === "light" ? "#4cceac" : "#4cceac",
    },
  }));

  const addAccount = async (id: any) => {
    setAddBtnLoading(true);
    try {
      const response = await AuthenticatedAPI.request(
        "FinanceAPI",
        "post",
        `/banking/add-account/${id}`,
        {}
      );

      openTransactionsPopup(response.redirectUrl);
      setAddBtnLoading(false);
      // console.log('User profile saved successfully', response);
    } catch (error) {
      setAddBtnLoading(false);
      console.error("Error saving user profile:", error);
      // Handle error state
    }
  };

  const fetchUserBankDetails = async (id: any) => {
    try {
      const response = await AuthenticatedAPI.request(
        "FinanceAPI",
        "post",
        `/banking/${id}`,
        {
          // body: {
          //   userId: userProfile.personalInfo.id,
          //   profile: userProfile,
          // },
        }
      );
      // console.log('response', response)
      // if (!response.ok) {
      //   throw new Error('Failed to save user profile');
      // }

      // Handle successful submissions

      dispatch(
        updateBankingInfo({
          consent: response.consent[0],
          accounts: response.accounts.data,
          consentUrl: response.consentUrl,
        })
      );
    } catch (error) {
      console.log("Error saving user profile:", error);
      // Handle error state
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getUserProfile = async () => {
    try {
      const userData = await Auth?.currentAuthenticatedUser().then(
        async (res) => {
          const response = await AuthenticatedAPI.request(
            "InvestmentAPI",
            "get",
            `/api/profile/${res.username}`
          );

          if (!response) {
            setLoading(false);
            throw new Error("Failed to fetch user profile");
          }
          if (response) {
            setProfileData(response.data);
            setInvestarScore(response.data.investarScore);
            setLoading(false);
          }
          return response;
        }
      );
    } catch (error) {
      setLoading(false);
      console.log("Error fetching user profile:", error);
    }
  };

  const multiformNavigateHandler = (step: Number) => {
    navigate(`/goal-setting?step=${step}`);
  };

  const propertydetailsNavigateHandler = () => {
    navigate("/property-details");
  };

  const PortfolioAnalysis = () => {
    navigate("/portfolio");
  };

  // function calculateCompletionPercentage(profileData: any) {
  //   // Check if each required field has values with length greater than 0
  //   const hasCurrentPortfolio =
  //     profileData?.currentPortfolio?.investmentProperties?.length > 0;
  //   const hasEmploymentInfo =
  //     profileData?.employmentInfo?.employmentInfo?.length > 0;
  //   const hasExpenses = profileData?.expenses?.expenses?.length > 0;
  //   const hasFamilyInfo =
  //     profileData?.familyInfo?.dependentKids?.length > 0 &&
  //     profileData.familyInfo?.earningMembers?.length > 0;
  //   const hasFinancialGoal =
  //     profileData?.financialGoal?.financialGoal?.length > 0;

  //   // Array of all checks
  //   const checks = [
  //     hasCurrentPortfolio,
  //     hasEmploymentInfo,
  //     hasExpenses,
  //     hasFamilyInfo,
  //     hasFinancialGoal,
  //   ];  
  //   console.log(checks,"checks")
  //   // Count the number of true values
  //   const completedSections = checks.filter(Boolean).length;
  //   console.log(completedSections,"sections")
  //   // Calculate percentage (each section contributes 20%)
  //   const percentage = (completedSections / checks.length) * 100;
  //   console.log(percentage,"percentages")
  //   setPercentage(percentage);
  //   return percentage;
  // }
  function calculateCompletionPercentage(profileData: any) {
    const hasCurrentPortfolio =
      profileData?.currentPortfolio?.investmentProperties?.length > 0;
    const hasEmploymentInfo =
      profileData?.employmentInfo?.employmentInfo?.length > 0;
    const hasExpenses = profileData?.expenses?.expenses?.length > 0;
    const hasFamilyInfo =
      profileData?.familyInfo?.dependentKids?.length > 0 &&
      profileData.familyInfo?.earningMembers?.length > 0;
    const hasFinancialGoal =
      profileData?.financialGoal?.financialGoal?.length > 0;

    const checks = [
      hasCurrentPortfolio,
      hasEmploymentInfo,
      hasExpenses,
      hasFamilyInfo,
      hasFinancialGoal,
    ];
    const falseCount = checks.filter(value => !value).length;
    const percentage = (checks.length - falseCount) / checks.length * 100;

    setPercentage(percentage);
    return percentage;
  }

  useEffect(() => {
    calculateCompletionPercentage(profileData);
  }, [profileData]);

  //Property Growth
  const investmentProperties =
    profileData?.currentPortfolio?.investmentProperties || [];

  const data = investmentProperties.map(
    (property: any) =>
      property.currentestimatedvalue - property.initialpurchaseprice || 0
  );
  const PropertyNames = investmentProperties.map(
    (property: any) =>
      property.address || ""
  );
  const propertyName = PropertyNames.map(name => name.split(' ').slice(0, 2).join(' '));
  const totalLoan = investmentProperties.map(
    (loan: any) => loan.currentloanamount || 0
  );

  const netRentalYields = investmentProperties.map(
    (property: any) => (property.weeklyrent * 52) / property.initialpurchaseprice * 100
  );

  // Borrowable Equity
  const borrowableEquities = investmentProperties.map((property: any) => {
    const currentEstimatedValue =
      parseFloat(property.currentestimatedvalue) || 0;
    const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
    return currentEstimatedValue * 0.8 - currentLoanAmount;
  });
  const totalValue = investmentProperties?.reduce((accumulator, x) => {

    return accumulator + parseFloat(x.currentestimatedvalue);
  }, 0) || 0;

  const toatlEquity = borrowableEquities.reduce(
    (total, value) => total + value,
    0
  );
  const totalYield = investmentProperties?.reduce((accumulator, x) => {

    return accumulator + parseInt(x.netrentalyield)
  }, 0) || 0;
  const averageYield = profileData?.currentPortfolio ? totalYield / investmentProperties.length : 0;
  const toatlLoanAmount = totalLoan.reduce(
    (total, value) => total + Number(value),
    0
  );

  const propertyHandler = async (id: number) => {
    try {
      const userData = await Auth?.currentAuthenticatedUser().then(
        async (res) => {
          const response = await AuthenticatedAPI.request(
            "InvestmentAPI",
            "get",
            `/api/property-details/${id}`
          );

          if (!response) {
            throw new Error("Failed to fetch user profile");
          }
        }
      );
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const getPropertyDetailHandler = (id: string) => {
    navigate(`/property-details?id=${id}`);
  };
  const total = toatlEquity + toatlLoanAmount;
  const Equitypercente = ((toatlEquity / total) * 100).toFixed(2);
  const Debtpercente = ((toatlLoanAmount / total) * 100).toFixed(2);



  useEffect(() => {
    if (profileData?.currentPortfolio?.investmentProperties) {
      const newAddresses = profileData.currentPortfolio.investmentProperties.map(val => val.address);
      setAddresses(newAddresses);

    }
  }, [profileData]);
  const img = StreetViewImage(addresses)
  useEffect(() => {
    if (img) {
      setPropertyImages(img)
    }
  }, [propertyImages, img])
  // useEffect(() => {
  //   profileData?.currentPortfolio?.investmentProperties?.map((val) => {
  //     return(

  //     setAddress(val.address)
  //     )
  //   })
  //   if(imageUrl){

  //   setPropertyImages(prevValue =>{ if (!prevValue.includes(imageUrl)) {
  //     return [...prevValue, imageUrl];
  //   }
  //   return prevValue;})
  //   }
  // }, [address,imageUrl])
  // const propertyimages = StreetViewImage({ addresses });
  // console.log('propertyimages :>> ', propertyimages);
  //   useEffect(() => {
  //     addresses.forEach(address => {
  //       const imageUrl = StreetViewImage(address);
  // console.log('imageUrl :>> ', imageUrl);
  //       // if (imageUrl) {
  //       //   setPropertyImages(prevValue => {
  //       //     if (!prevValue.includes(imageUrl)) {
  //       //       return [...prevValue, imageUrl];
  //       //     }
  //       //     return prevValue;
  //       //   });
  //       // }
  //     });
  //   }, [addresses]);

  useEffect(() => { }, [
    toatlEquity,
    Equitypercente,
    Debtpercente,
    toatlLoanAmount,
  ]);

  const handleUpdateStratgy = async () => {
    const userDatastart = await Auth?.currentAuthenticatedUser().then(
      async (res) => {
        const response = await AuthenticatedAPI.request(
          "InvestmentAPI",
          "put",
          `/api/strategy/update/${res.username}`
        );
        if (!response) {
          throw new Error("Failed to fetch user profile");
        }
      }
    );
  };
  React.useEffect(() => {
    if (userStrategy && !userStrategy) {
      handleUpdateStratgy();
    }
  }, [userStrategy]);




  //pdf code 




  const pdfRef = useRef<HTMLDivElement>(null);

  const generatePDF = async () => {
    setButtonLabel("Preparing PDF...");

    const element = pdfRef.current;
    if (!element) return;

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.scrollHeight,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
    heightLeft -= pdfHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save("dashboard-content.pdf");

    setButtonLabel("DOWNLOAD REPORTS");
  };
  // const generatePDF = async () => {
  //   setButtonLabel("Preparing PDF...");

  //   // Select the specific div you want to capture
  //   const divToCapture = document.getElementById('captureForScreenShot'); // Use your div's id or class

  //   if (!divToCapture) return;

  //   // Capture the specific div using html2canvas
  //   const canvas = await html2canvas(divToCapture, {
  //     scale: 2,
  //     useCORS: true,
  //     scrollX: -window.scrollX,
  //     scrollY: -window.scrollY,
  //   });

  //   const imgData = canvas.toDataURL("image/png");
  //   const pdf = new jsPDF("p", "mm", "a4");

  //   const imgProps = pdf.getImageProperties(imgData);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();
  //   const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //   let heightLeft = imgHeight;
  //   let position = 0;

  //   pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
  //   heightLeft -= pdfHeight;

  //   while (heightLeft > 0) {
  //     position = heightLeft - imgHeight;
  //     pdf.addPage();
  //     pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
  //     heightLeft -= pdfHeight;
  //   }

  //   pdf.save("specific-div-content.pdf");

  //   setButtonLabel("DOWNLOAD REPORTS");
  // };

  const handleDownloadPdf = async () => {
    try {
      setButtonLabel("Preparing PDF...")
      const response: any = await Pdf(profileData, auth, portfolioData, userBankingInfo)
      setButtonLabel("DOWNLOAD REPORTS")
    } catch (error: any) {
      console.log(error.message)
    }
  }
  return (
    <>

      <div>
        <Grid container spacing={3} columns={12} ref={pdfRef} className="p-6">
          <Grid item md={12} lg={8}>
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                fontSize: "30px",
                fontWeight: "400",
                color: colors.grey[100],
              }}
            >
              Welcome back,{" "}
              <span style={{ fontWeight: "900" }}>
                {userFirstName && userFirstName}
              </span>{" "}
              👋
            </Typography>
          </Grid>
          <Grid item md={12} lg={4}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Button
                variant="contained"
                color="secondary"

                onClick={handleDownloadPdf}
                sx={{
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  color: "#ffffff",
                  padding: "10px 28px",
                }}
              >
                <DownloadIcon />
                {buttonLabel}
              </Button>
            </Box>
          </Grid>

          <Grid item md={12}>
            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                backgroundColor: colors.primary[400],
              }}
            >
              <Typography
                variant="h4"
                align="left"
                pb={2}
                zIndex={1}
                gutterBottom
                sx={{
                  fontWeight: "700",
                }}
              >
                Profile Completion
              </Typography>
              <Grid container spacing={2} columns={12} className="mb-7">
                <Grid item xs={12} md={8} lg={5}>
                  <Stack
                    spacing={2}
                    sx={{ flexGrow: 1 }}
                    className="custom_progressBar"
                  >
                    <label
                      style={{
                        color:
                          theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
                      }}
                    >
                      {percentage}% Complete
                    </label>
                    <BorderLinearProgress
                      variant="determinate"
                      value={percentage}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <ul className="profileBox_list">
                <li>
                  <Box
                    className="profile_box"
                    onClick={() => multiformNavigateHandler(0)}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        width: "38px",
                        height: "38px",
                        borderRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      <img
                        alt="profile-user"
                        width="21px"
                        height="21px"
                        src={`/target.svg`}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{
                          color: colors.grey[100],
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Goals
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box
                    className="profile_box"
                    onClick={() => multiformNavigateHandler(1)}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        width: "38px",
                        height: "38px",
                        borderRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      <img
                        alt="profile-user"
                        width="21px"
                        height="21px"
                        src={`/usersGroupRounded.svg`}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{
                          color: colors.grey[100],
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Family
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box
                    className="profile_box"
                    onClick={() => multiformNavigateHandler(2)}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        width: "38px",
                        height: "38px",
                        borderRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      <img
                        alt="profile-user"
                        width="21px"
                        height="21px"
                        src={`/schoolCaseRound.svg`}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{
                          color: colors.grey[100],
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Income
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box
                    className="profile_box"
                    onClick={() => multiformNavigateHandler(3)}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        width: "38px",
                        height: "38px",
                        borderRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      <img
                        alt="profile-user"
                        width="21px"
                        height="21px"
                        src={`/pieChart.svg`}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{
                          color: colors.grey[100],
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Portfolio
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box
                    className="profile_box"
                    onClick={() => multiformNavigateHandler(4)}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        width: "38px",
                        height: "38px",
                        borderRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      <img
                        alt="profile-user"
                        width="21px"
                        height="21px"
                        src={`/moneyManagement.svg`}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{
                          color: colors.grey[100],
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Expenses
                      </Typography>
                    </Box>
                  </Box>
                </li>
                {/* <li>
                  <Box className="profile_box">
                    <Box
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        width: "38px",
                        height: "38px",
                        borderRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      <img
                        alt="profile-user"
                        width="21px"
                        height="21px"
                        src={`/mortgageStrategy.svg`}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{
                          color: colors.grey[100],
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Mortgage Strategy
                      </Typography>
                    </Box>
                  </Box>
                </li>
                <li>
                  <Box className="profile_box">
                    <Box
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        width: "38px",
                        height: "38px",
                        borderRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      <img
                        alt="profile-user"
                        width="21px"
                        height="21px"
                        src={`/map.svg`}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{
                          color: colors.grey[100],
                          fontSize: "14px",
                          lineHeight: 1,
                        }}
                      >
                        Property Plan
                      </Typography>
                    </Box>
                  </Box>
                </li> */}
              </ul>
              <Grid item md={12}>
                <Card
                  sx={{
                    padding: "20px !important",
                    marginBottom: "0px !important",
                    borderRadius: "15px !important",
                    boxShadow: "none !important",
                    backgroundColor: colors.primary[400],
                  }}
                >
                  <Typography
                    variant="h4"
                    align="left"
                    pb={2}
                    zIndex={1}
                    gutterBottom
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    Portfolio Summary
                  </Typography>
                  <ul className="profileBox_summarylist">
                    <li>
                      <Box className="profile_box">
                        <Box sx={{
                          flex: "100% !important",
                          maxWidth: "100% !important",
                          display: "flex", // To enable flexbox
                          flexDirection: "column", // Aligns items vertically
                          alignItems: "center", // Centers items horizontally
                          justifyContent: "center", // Centers items vertically
                        }}>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography color='#6870fa' textAlign='center'><ScoreIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                            <Typography fontWeight="700" textAlign='center' paddingTop='10px'>{profileData?.investarScore ?? 0}</Typography>
                            <Typography fontWeight="500" textAlign='center' paddingTop='10px'>Investar Score</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                    <li>
                      <Box className="profile_box">
                        <Box sx={{
                          flex: "100% !important",
                          maxWidth: "100% !important",
                          display: "flex", // To enable flexbox
                          flexDirection: "column", // Aligns items vertically
                          alignItems: "center", // Centers items horizontally
                          justifyContent: "center",
                        }}>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography color='#4cceac' textAlign='center' ><HomeIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                            <Typography fontWeight="900" textAlign='center' paddingTop='10px'>{portfolioData?.length}</Typography>
                            <Typography fontWeight="500" paddingTop='10px'>Properties Owned</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                    <li>
                      <Box className="profile_box">
                        <Box sx={{
                          flex: "100% !important",
                          maxWidth: "100% !important",
                          display: "flex", // To enable flexbox
                          flexDirection: "column", // Aligns items vertically
                          alignItems: "center", // Centers items horizontally
                          justifyContent: "center",
                        }}>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography color='#6870fa' textAlign='center'><AttachMoneyIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                            <Typography fontWeight="700" textAlign='center' paddingTop='10px'>${formatCurrency(totalValue)}</Typography>
                            <Typography fontWeight="500" textAlign='center' paddingTop='10px'>Total Value</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                    <li>
                      <Box className="profile_box">
                        <Box sx={{
                          flex: "100% !important",
                          maxWidth: "100% !important",
                          display: "flex", // To enable flexbox
                          flexDirection: "column", // Aligns items vertically
                          alignItems: "center", // Centers items horizontally
                          justifyContent: "center",
                        }}>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography color='#141414' textAlign='center'><PercentIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                            <Typography fontWeight="700" textAlign='center' paddingTop='10px'>{averageYield.toFixed(0)}</Typography>
                            <Typography fontWeight="500" textAlign='center' paddingTop='10px'> Average Yield</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                    <li>
                      <Box
                        className={`${toatlEquity >= 70000 ? "gradient-border-ba-dash" : "profile_box"}`}
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography textAlign='center' color='#2e7d32'><BusinessIcon sx={{ height: '30px !important', width: '40px !important' }} /></Typography>
                            <Typography className="pt-5" fontWeight="600">
                              Borrowable Equity: ${toatlEquity.toFixed(0)}
                              {toatlEquity >= 70000 && (
                                <>
                                  <Tooltip title={`Your Portfolio Borrowable Equity is ${toatlEquity.toFixed(0)}`}>
                                    <InfoIcon sx={{ ml: 1 }} />
                                  </Tooltip>
                                </>
                              )}
                              {/* <Tooltip title="Send email to investor">
                                <IconButton
                                  onClick={() => handleOpenEmailModal(buyerAgentData)}
                                  sx={{ ml: 1 }}
                                >
                                  <EmailIcon />
                                </IconButton>
                              </Tooltip> */}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  </ul>
                </Card>
              </Grid>
            </Card>
          </Grid>


          <Grid container item md={12} spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} xl={3}>
                  <Card
                    sx={{
                      padding: "20px 20px 20px !important",
                      marginBottom: "0px !important",
                      borderRadius: "15px !important",
                      boxShadow: "none !important",
                      height: "auto",
                      backgroundColor: colors.primary[400],
                    }}
                  >
                    <Typography
                      variant="h4"
                      align="left"
                      pb={2}
                      zIndex={1}
                      gutterBottom
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Portfolio Growth
                    </Typography>

                    <PropertyGrowth data={data} propertyname={propertyName} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <Card
                    sx={{
                      padding: "20px 20px 20px !important",
                      marginBottom: "0px !important",
                      borderRadius: "15px !important",
                      boxShadow: "none !important",
                      height: "auto",
                      backgroundColor: colors.primary[400],
                    }}
                  >
                    <Typography
                      variant="h4"
                      align="left"
                      pb={2}
                      zIndex={1}
                      gutterBottom
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Portfolio Yields
                    </Typography>

                    <Propertyyields netRentalYields={netRentalYields} propertyname={propertyName} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <Card
                    sx={{
                      padding: "20px 20px 20px !important",
                      marginBottom: "0px !important",
                      borderRadius: "15px !important",
                      boxShadow: "none !important",
                      height: "auto",
                      backgroundColor: colors.primary[400],
                    }}
                  >
                    <Typography
                      variant="h4"
                      align="left"
                      pb={2}
                      zIndex={1}
                      gutterBottom
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Portfolio Borrowable Equity
                    </Typography>

                    <BorrowableEquity borrowableEquities={borrowableEquities} propertyname={propertyName} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <Card
                    sx={{
                      padding: "20px 20px 20px !important",
                      marginBottom: "0px !important",
                      borderRadius: "15px !important",
                      boxShadow: "none !important",
                      height: "385px",
                      backgroundColor: colors.primary[400],
                    }}
                  >
                    <Typography
                      variant="h4"
                      align="left"
                      pb={2}
                      zIndex={1}
                      gutterBottom
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Portfolio Debt to Equity Ratio
                    </Typography>

                    <LoanToValueRatioChart
                      equity={Equitypercente}
                      loanamount={Debtpercente}
                      totalLoan={toatlLoanAmount}
                      totalEquity={toatlEquity}
                    />
                  </Card>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          {/* <button onClick={() => propertyHandler(24)}>Testttt</button> */}
          <Grid item xs={12} md={5} lg={3}>
            <Card
              className="investmentScore_box"
              sx={{
                padding: "20px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                height: "500px",
                backgroundColor: colors.primary[400],
              }}
            >
              <Box sx={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <Typography
                  variant="h4"
                  align="left"
                  zIndex={1}
                  gutterBottom
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  Investar Score
                </Typography>

              </Box>












              {/* <Typography>
                <Investar/>
              </Typography> */}
              <Box className="investmentScore_imgBox">
                <img
                  className="w-full h-full p-2"
                  alt="profile-user"
                  src={meterImg}
                />
                <span>{profileData?.investarScore}</span>
              </Box>
              <div className="legend-item" style={{ justifyContent: "center" }}>
                <span style={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#FFFFFF" : "black",
                }}></span>
                <span className="legend-label"><span className="legend-score" style={{ fontSize: "13px" }}>Including these but not limited to mentioned below
                </span></span>
              </div>
              <div className="legend">
                <div>


                  <div className="legend-item">
                    <span className="legend-color" style={{
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#FFFFFF" : "black",
                    }}></span>
                    <span className="legend-label"> <span className="legend-score">Income Details </span></span>
                  </div>
                  <div className="legend-item">
                    <span className="legend-color" style={{
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#FFFFFF" : "black",
                    }}></span>
                    <span className="legend-label"><span className="legend-score">Expenses Details</span></span>
                  </div>

                </div>
                <div>
                  <div className="legend-item">
                    <span className="legend-color" style={{
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#FFFFFF" : "black",
                    }}></span>
                    <span className="legend-label"><span className="legend-score">Portfolio Details
                    </span></span>
                  </div>
                  <div className="legend-item">
                    <span className="legend-color" style={{
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#FFFFFF" : "black",
                    }}></span>
                    <span className="legend-label"><span className="legend-score">Property Details
                    </span></span>
                  </div>
                </div>

                {/* <div>
                  <div className="legend-item">
                    <span className="legend-color" style={{
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#FFFFFF" : "black",
                    }}></span>
                    <span className="legend-label">Investment <span className="legend-score">{userStrategy?.structure?.investmentType}</span></span>
                  </div>
                  <div className="legend-item">
                    <span className="legend-color" style={{
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#FFFFFF" : "black",
                    }}></span>
                    <span className="legend-label">Growth YOY <span className="legend-score">{userStrategy?.recommendations?.growthYoy}</span></span>
                  </div>
                </div> */}
              </div>

            </Card>

          </Grid>
          <Grid item xs={12} md={7} lg={3}>
            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                height: "500px",
                backgroundColor: colors.primary[400],
              }}
            >
              <Typography
                variant="h4"
                align="left"
                pb={2}
                zIndex={1}
                gutterBottom
                sx={{
                  fontWeight: "700",
                }}
              >
                Portfolio Wealth
              </Typography>

              <PortfolioWealth
                investmentProperties={investmentProperties}
                propertyname={propertyName}
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={7} lg={6}>
            <Card
              sx={{
                padding: "20px 20px 0px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                backgroundColor: colors.primary[400],
                height: "500px",
              }}
            >
              <Grid container spacing={3} columns={12} alignItems="center">
                <Grid item md={12} lg={7} mb={3}>
                  <Typography
                    variant="h4"
                    align="left"
                    zIndex={1}
                    gutterBottom
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    Portfolio Analysis
                  </Typography>
                </Grid>
                <Grid item md={12} lg={5} mb={3} className="xl:text-right">
                  <Link
                    sx={{
                      color: colors.greenAccent[500],
                      textDecoration: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={PortfolioAnalysis}
                  >
                    View All
                  </Link>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                columns={12}
                sx={{
                  position: "relative",
                  height: "400px",
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: `${loading && "center"}`,
                  alignItems: `${loading && "center"}`,
                }}
              >
                {loading ? (
                  <Typography variant="h5">Loading...</Typography>
                ) : profileData?.currentPortfolio?.investmentProperties &&
                  profileData?.currentPortfolio?.investmentProperties?.length >
                  0 ? (
                  profileData.currentPortfolio.investmentProperties.map(
                    (property, index) => {
                      const propertyData = portfolioData.find(
                        (p) => p.propertyId === property.id
                      );


                      return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={property.id}>
                          <Card
                            className="propertyBox"
                            onClick={() =>
                              getPropertyDetailHandler(property.id)
                            }
                            sx={{
                              padding: "10px !important",
                              marginBottom: "0px !important",
                              borderRadius: "15px !important",
                              boxShadow: "none !important",
                              backgroundColor:
                                "rgb(91 192 190 / 10%) !important",
                            }}
                          >
                            <Box className="property_img">
                              <img
                                className="w-full"
                                alt="profile-user"
                                // src={`/property-01.png`}
                                src={propertyImages[index]}
                              />
                            </Box>
                            <Box className="property_content">
                              <Typography
                                variant="h5"
                                align="left"
                                mb={1}
                                zIndex={1}
                                gutterBottom
                                sx={{
                                  fontWeight: "700",
                                }}
                              >
                                {property.address}
                              </Typography>
                              <Typography
                                variant="body1"
                                className="gradient-text mr-1 "
                                align="left"
                                mb={2}
                                zIndex={1}
                                gutterBottom
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "600",

                                }}
                              >
                                Estimated value: {property.currentestimatedvalue}
                              </Typography>
                              <Button
                                variant="contained"
                                color={
                                  propertyData &&
                                    propertyData.classification ===
                                    "Performing Well"
                                    ? "success"
                                    : propertyData &&
                                      propertyData.classification === "Average"
                                      ? "warning"
                                      : propertyData &&
                                        propertyData.classification === "Risky"
                                        ? "error"
                                        : "info"
                                }
                                endIcon={<ErrorOutlineIcon />}
                                sx={{
                                  borderRadius: "50px",
                                  fontSize: "12px",
                                  textTransform: "capitalize",
                                  color: "#ffffff",
                                  cursor: "none",
                                }}
                              >
                                {propertyData && propertyData.classification}
                              </Button>
                            </Box>
                          </Card>
                        </Grid>
                      );
                    }
                  )
                ) : (
                  <Typography variant="h5">No data found</Typography>
                )}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              className="investmentStrategy_box"
              sx={{
                padding: "50px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                backgroundColor: colors.primary[400],
              }}
            >
              <Box className="investmentStrategy_boxInner" id="captureForScreenShot">
                <Box className="firstStep_line">
                  <img
                    className="w-full"
                    alt="profile-user"
                    src={`/first_step_d.png`}
                  />
                </Box>
                <ul className="list_one">
                  <li>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        borderRadius: "50px",
                        fontSize: "26px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        color: "#ffffff",
                        padding: "6px 30px",
                      }}
                    >
                      Investar Strategy
                    </Button>
                  </li>
                </ul>

                <ul className="list_two">
                  <li>
                    <Button
                      variant="contained"
                      className={`btn_styleThree ${activeIndex === "structure" ? "active" : ""
                        }`}
                      onClick={() => handlePipelineTitle("structure")}
                      sx={{
                        color:
                          theme.palette.mode === "dark"
                            ? "#5bc0be !important"
                            : "#5BC0BE !important",
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "#345060 !important"
                            : "#EFF9F8 !important",
                      }}
                    >
                      <img
                        className="w-full mr-3"
                        alt="profile-user"
                        src={`/icon_structure-01.svg`}
                      />
                      Structure
                    </Button>
                  </li>
                  <li>
                    <Button
                      variant="contained"
                      className={`btn_styleThree ${activeIndex === "ownership" ? "active" : ""
                        }`}
                      onClick={() => handlePipelineTitle("ownership")}
                      sx={{
                        color:
                          theme.palette.mode === "dark"
                            ? "#5bc0be !important"
                            : "#5BC0BE !important",
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "#345060 !important"
                            : "#EFF9F8 !important",
                      }}
                    >
                      <img
                        className="w-full mr-3"
                        alt="profile-user"
                        src={`/icon_ownership-01.svg`}
                      />
                      Ownership
                    </Button>
                  </li>
                  <li>
                    <Button
                      variant="contained"
                      className={`btn_styleThree ${activeIndex === "funding" ? "active" : ""
                        }`}
                      onClick={() => handlePipelineTitle("funding")}
                      sx={{
                        color:
                          theme.palette.mode === "dark"
                            ? "#5bc0be !important"
                            : "#5BC0BE !important",
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "#345060 !important"
                            : "#EFF9F8 !important",
                      }}
                    >
                      <img
                        className="w-full mr-3"
                        alt="profile-user"
                        src={`/icon_funding-01.svg`}
                      />
                      Funding
                    </Button>
                  </li>
                  <li>
                    <Button
                      variant="contained"
                      className={`btn_styleThree  ${activeIndex === "recommendation" ? "active" : ""
                        }`}
                      onClick={() => handlePipelineTitle("recommendation")}
                      sx={{
                        color:
                          theme.palette.mode === "dark"
                            ? "#5bc0be !important"
                            : "#5BC0BE !important",
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "#345060 !important"
                            : "#EFF9F8 !important",
                      }}
                    >
                      <img
                        className="w-full mr-3"
                        alt="profile-user"
                        src={`/icon_recommendation-02.svg`}
                      />
                      Recommendation
                    </Button>
                  </li>
                </ul>

                <div
                  className={`firstStep_firstStep_box ${activeIndex === "structure" &&
                    userStrategy?.structure?.investmentType === "owner_occupied"
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="firstStep_firstStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/firstStep_firstStep_e.png`}
                    />
                  </Box>
                  <ul className="twoColumn_list">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("owner_occupied")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Owner Occupied
                      </Button>
                    </li>
                    <li className="text-right">
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("investment")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Investment
                      </Button>
                    </li>
                  </ul>
                </div>

                <div
                  className={`firstStep_secondStep_box ${activeIndex === "structure" &&
                    userStrategy?.structure?.investmentType !== "InterestOnly"
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="firstStep_secondStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/firstStep_secondStep_e.png`}
                    />
                  </Box>
                  <ul className="twoColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("owner_occupied")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Owner Occupied
                      </Button>
                    </li>
                    <li className="text-right">
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("investment")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Investment
                      </Button>
                    </li>
                  </ul>
                  <ul className="twoColumn_list listTwo">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        // onClick={()=>handleSubTitle('principal')}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Principal + Interest
                      </Button>
                    </li>
                    <li className="text-right">
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        // onClick={()=>handleSubTitle('intrest')}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Interest Only
                      </Button>
                    </li>
                  </ul>
                </div>

                {/* NEWLY Added */}
                <div
                  className={`firstStep_secondStep_secondStep_box ${userStrategy?.structure?.investmentType ===
                    "InterestOnly" && activeIndex === "structure"
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="firstStep_secondStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/firstStep_threeStep_e.png`}
                    />
                  </Box>
                  <ul className="twoColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("owner_occupied")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Owner Occupied
                      </Button>
                    </li>
                    <li className="text-right">
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("investment")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Investment
                      </Button>
                    </li>
                  </ul>
                  <ul className="twoColumn_list listTwo">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        // onClick={()=>handleSubTitle('principal')}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Principal + Interest
                      </Button>
                    </li>
                    <li className="text-right">
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        //  onClick={()=>handleSubTitle('intrest')}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Interest Only
                      </Button>
                    </li>
                  </ul>
                </div>

                <div
                  className={`secondStep_firstStep_box  ${activeIndex === "ownership" &&
                    userStrategy?.ownership === "trust"
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="secondStep_firstStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/secondStep_firstStep_e.png`}
                    />
                  </Box>
                  <ul className="twoColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        //  onClick={()=>handleSubTitle('trust')}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Trust
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        // onClick={()=>handleSubTitle('personal')}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Personal
                      </Button>
                    </li>
                  </ul>
                </div>

                {/* NEWLY Added */}
                <div
                  className={`secondStep_secondStep_box ${activeIndex === "ownership" &&
                    userStrategy?.ownership !== "trust"
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="secondStep_secondStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/secondStep_secondStep_e.png`}
                    />
                  </Box>
                  <ul className="twoColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        // onClick={()=>handleSubTitle('trust')}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Trust
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        //  onClick={()=>handleSubTitle('personal')}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Personal
                      </Button>
                    </li>
                  </ul>
                </div>

                <div
                  className={`threeStep_firstStep_box  ${activeIndex === "funding" && activeSubIndex == "lender"
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="threeStep_firstStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/threeStep_firstStep_e.png`}
                    />
                  </Box>
                  <ul className="threeColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("lender")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Lender Selection
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("loantovalue")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Loan to value ratio
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("equity")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Ability to buy additional investment via equity
                      </Button>
                    </li>
                  </ul>
                  <div
                    className={`${activeSubIndex === "lender" ? "" : "hidden"}`}
                  >
                    <Box
                      className="tab_styleOne "
                      sx={{
                        borderColor:
                          theme.palette.mode === "dark"
                            ? "#4FA2A5 !important"
                            : "#ACE7E7 !important",
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "rgb(79 162 165 / 60%) !important"
                            : "#ACE7E7 !important",
                      }}
                    >
                      <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs"
                      >
                        <Tab
                          icon={
                            <img
                              src={`${process.env.PUBLIC_URL}/icon_serviceAbility-01.svg`}
                              alt=""
                            />
                          }
                          iconPosition="start"
                          label="Serviceability"
                          {...a11yProps(0)}
                          onClick={() => setActiveTab(0)}
                          sx={{
                            fontSize: "16px !important",
                            fontWeight: "600 !important",
                            justifyContent: "start !important",
                            borderRadius: "15px 0px 0px 15px !important",
                            textTransform: "capitalize !important",
                            color:
                              theme.palette.mode === "dark"
                                ? "#ffffff !important"
                                : "#101828 !important",
                            backgroundColor:
                              theme.palette.mode === "dark" && activeTab === 0
                                ? "#1F2A40 !important"
                                : theme.palette.mode === "light" &&
                                  activeTab === 0
                                  ? "#ffffff !important"
                                  : "",
                          }}
                        />
                        <Tab
                          icon={
                            <img
                              src={`${process.env.PUBLIC_URL}/icon_competitiveRate-01.svg`}
                              alt=""
                            />
                          }
                          iconPosition="start"
                          label="Competitive Rate"
                          {...a11yProps(1)}
                          onClick={() => setActiveTab(1)}
                          sx={{
                            fontSize: "16px !important",
                            fontWeight: "600 !important",
                            justifyContent: "start !important",
                            borderRadius: "15px 0px 0px 15px !important",
                            textTransform: "capitalize !important",
                            color:
                              theme.palette.mode === "dark"
                                ? "#ffffff !important"
                                : "#101828 !important",
                            backgroundColor:
                              theme.palette.mode === "dark" && activeTab === 1
                                ? "#1F2A40 !important"
                                : theme.palette.mode === "light" &&
                                  activeTab === 1
                                  ? "#ffffff !important"
                                  : "",
                          }}
                        />
                        <Tab
                          icon={
                            <img
                              src={`${process.env.PUBLIC_URL}/icon_flexibleLenderPolicies-01.svg`}
                              alt=""
                            />
                          }
                          iconPosition="start"
                          label="Flexible Lender Policies"
                          {...a11yProps(2)}
                          onClick={() => setActiveTab(2)}
                          sx={{
                            fontSize: "16px !important",
                            fontWeight: "600 !important",
                            justifyContent: "start !important",
                            borderRadius: "15px 0px 0px 15px !important",
                            textTransform: "capitalize !important",
                            color:
                              theme.palette.mode === "dark"
                                ? "#ffffff !important"
                                : "#101828 !important",
                            backgroundColor:
                              theme.palette.mode === "dark" && activeTab === 2
                                ? "#1F2A40 !important"
                                : theme.palette.mode === "light" &&
                                  activeTab === 2
                                  ? "#ffffff !important"
                                  : "",
                          }}
                        />
                      </Tabs>
                      <TabPanel value={value} index={0}>
                        <Box
                          sx={{
                            fontSize: "12px !important",
                            fontWeight: "400 !important",
                            padding: "20px",
                            borderRadius: "0px 15px 15px 15px !important",
                            textTransform: "capitalize !important",
                            color:
                              theme.palette.mode === "dark"
                                ? "#dddddd !important"
                                : "#101828 !important",
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "#1F2A40 !important"
                                : "#ffffff !important",
                          }}
                        >
                          {[
                            "Crucial For Long-Term Investment Success",
                            "Ensures Financial Stability And Peace Of Mind",
                            "Maintains Good Standing With Lenders",
                            "Opens Doors For Future Investment Opportunities",
                            "Protects Existing Assets",
                            "Sets Up Sustainable Growth",
                            "Reduces Risk Of Financial Stress",
                            "Key Factor In Building Long-Term Wealth Through Property Investment",
                          ].map((text, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "6px",
                                  height: "6px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    theme.palette.mode === "dark"
                                      ? "#5bc0be"
                                      : "#5BC0BE",
                                  marginRight: "10px",
                                }}
                              />
                              <Typography variant="body2">{text}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Box
                          sx={{
                            fontSize: "12px !important",
                            fontWeight: "400 !important",
                            padding: "20px",
                            borderRadius: "15px 15px 15px 15px !important",
                            textTransform: "capitalize !important",
                            color:
                              theme.palette.mode === "dark"
                                ? "#dddddd !important"
                                : "#101828 !important",
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "#1F2A40 !important"
                                : "#ffffff !important",
                          }}
                        >
                          {[
                            "Pivotal In Maximizing Investment Returns And Profitability",
                            "Small Differences Can Have Substantial Impact Over Loan Life",
                            "Lowers Financing Costs",
                            "Improves Cash Flow And Net Returns",
                            "Allows For Reinvestment Of Savings",
                            "Provides Buffer Against Market Fluctuations",
                            "Can Make Significant Difference In Investment Quality",
                            "May Help Reach Financial Goals Sooner",
                          ].map((text, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "6px",
                                  height: "6px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    theme.palette.mode === "dark"
                                      ? "#5bc0be"
                                      : "#5BC0BE",
                                  marginRight: "10px",
                                }}
                              />
                              <Typography variant="body2">{text}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <Box
                          sx={{
                            fontSize: "12px !important",
                            fontWeight: "400 !important",
                            padding: "20px",
                            borderRadius: "15px 15px 15px 15px !important",
                            textTransform: "capitalize !important",
                            color:
                              theme.palette.mode === "dark"
                                ? "#dddddd !important"
                                : "#101828 !important",
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "#1F2A40 !important"
                                : "#ffffff !important",
                          }}
                        >
                          {[
                            "Supports Unique Investment Strategies",
                            "Accommodates Various Property Types And Income Structures",
                            "Vital For Seizing Time-Sensitive Opportunities",
                            "Helps Manage Portfolio Through Market Fluctuations",
                            "May Offer Beneficial Features (e.g. Interest-Only Periods)",
                            "Adapts To Changing Needs As Portfolio Grows",
                            "Can Open Up Otherwise Inaccessible Opportunities",
                            "Often More Understanding Of Real Estate Investing Complexities",
                          ].map((text, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "6px",
                                  height: "6px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    theme.palette.mode === "dark"
                                      ? "#5bc0be"
                                      : "#5BC0BE",
                                  marginRight: "10px",
                                }}
                              />
                              <Typography variant="body2">{text}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </TabPanel>
                    </Box>
                  </div>
                </div>

                <div
                  className={`threeStep_secondStep_box ${activeSubIndex === "loantovalue" ? "" : "hidden"
                    }`}
                >
                  <Box className="threeStep_secondStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/threeStep_secondStep_firstStep_e.png`}
                    />
                  </Box>
                  <ul className="threeColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("lender")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Lender Selection
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("loantovalue")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Loan to value ratio
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("equity")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Ability to buy additional investment via equity
                      </Button>
                    </li>
                  </ul>
                  <ul className="listTwo">
                    <li>
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          lineHeight: "1",
                          padding: "10px",
                          minWidth: "68px",
                          borderRadius: "8px",
                          border: "3px solid #ACE7E7",
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#56b2b2 !important"
                              : "#ACE7E7 !important",
                          color:
                            theme.palette.mode === "dark"
                              ? "#ffffff !important"
                              : "#ffffff !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgb(79 162 165 / 60%) !important"
                              : "#5BC0BE !important",
                          boxShadow: "none !important",
                        }}
                      >
                        {userStrategy?.funding?.lvr}%
                      </Button>
                    </li>
                  </ul>
                </div>

                <div
                  className={`threeStep_threeStep_box ${activeSubIndex === "equity" &&
                    userStrategy?.funding?.canBuyAdditionalIp == true
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="threeStep_threeStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/threeStep_threeStep_e.png`}
                    />
                  </Box>
                  <ul className="threeColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("lender")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Lender Selection
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("loantovalue")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Loan to value ratio
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("equity")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Ability to buy additional investment via equity
                      </Button>
                    </li>
                  </ul>
                  <ul className="threeColumn_list listTwo">
                    <li>
                      <Button
                        variant="contained"
                        // onClick={()=>handleSubTitle('equity')}
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          lineHeight: "1",
                          padding: "10px",
                          minWidth: "68px",
                          borderRadius: "8px",
                          border: "3px solid #ACE7E7",
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#56b2b2 !important"
                              : "#ACE7E7 !important",
                          color:
                            theme.palette.mode === "dark"
                              ? "#ffffff !important"
                              : "#ffffff !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgb(79 162 165 / 60%) !important"
                              : "#5BC0BE !important",
                          boxShadow: "none !important",
                        }}
                      >
                        Yes
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        // onClick={()=>handleSubTitle('no')}
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          lineHeight: "1",
                          padding: "10px",
                          minWidth: "68px",
                          borderRadius: "8px",
                          border: "3px solid #ACE7E7",
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "transparent !important"
                              : "transparent !important",
                          color:
                            theme.palette.mode === "dark"
                              ? "#5BC0BE !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgb(91 192 190 / 9%) !important"
                              : "rgb(91 192 190 / 9%) !important",
                          boxShadow: "none !important",
                        }}
                      >
                        No
                      </Button>
                    </li>
                  </ul>
                </div>

                <div
                  className={`threeStep_fourStep_box ${activeSubIndex === "equity" &&
                    userStrategy?.funding?.canBuyAdditionalIp == false
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="threeStep_fourStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/threeStep_fourStep_e.png`}
                    />
                  </Box>
                  <ul className="threeColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("lender")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Lender Selection
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("loantovalue")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Loan to value ratio
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("equity")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Ability to buy additional investment via equity
                      </Button>
                    </li>
                  </ul>
                  <ul className="threeColumn_list listTwo">
                    <li>
                      <Button
                        variant="contained"
                        // onClick={()=>handleSubTitle('equity')}
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          lineHeight: "1",
                          padding: "10px",
                          minWidth: "68px",
                          borderRadius: "8px",
                          border: "3px solid #ACE7E7",
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "transparent !important"
                              : "transparent !important",
                          color:
                            theme.palette.mode === "dark"
                              ? "#5BC0BE !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgb(91 192 190 / 9%) !important"
                              : "rgb(91 192 190 / 9%) !important",
                          boxShadow: "none !important",
                        }}
                      >
                        Yes
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        // onClick={()=>handleSubTitle('no')}
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          lineHeight: "1",
                          padding: "10px",
                          minWidth: "68px",
                          borderRadius: "8px",
                          border: "3px solid #ACE7E7",
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#56b2b2 !important"
                              : "#ACE7E7 !important",
                          color:
                            theme.palette.mode === "dark"
                              ? "#ffffff !important"
                              : "#ffffff !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgb(79 162 165 / 60%) !important"
                              : "#5BC0BE !important",
                          boxShadow: "none !important",
                        }}
                      >
                        No
                      </Button>
                    </li>
                  </ul>
                </div>

                <div
                  className={`fourStep_firstStep_box ${activeIndex === "recommendation" &&
                    activeSubIndex == "growth"
                    ? ""
                    : "hidden"
                    }`}
                >
                  <Box className="fourStep_firstStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/fourStep_firstStep_e.png`}
                    />
                  </Box>
                  <ul className="threeColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("growth")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Growth YoY
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("purchase")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Purchase Price
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("rental")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Rental Yield
                      </Button>
                    </li>
                  </ul>
                  <ul className="listTwo">
                    <li>
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          lineHeight: "1.5",
                          textAlign: "center",
                          padding: "10px 15px",
                          minWidth: "68px",
                          borderRadius: "8px",
                          border: "3px solid #ACE7E7",
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#56b2b2 !important"
                              : "#ACE7E7 !important",
                          color:
                            theme.palette.mode === "dark"
                              ? "#ffffff !important"
                              : "#ffffff !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgb(79 162 165 / 60%) !important"
                              : "#5BC0BE !important",
                          boxShadow: "none !important",
                        }}
                      >
                        {userStrategy?.recommendations?.growthYoy || 7}%{" "}
                      </Button>
                    </li>
                  </ul>
                </div>

                <div
                  className={`fourStep_secondStep_box  ${activeSubIndex === "purchase" ? "" : "hidden"
                    }`}
                >
                  <Box className="fourStep_secondStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/fourStep_secondStep_e.png`}
                    />
                  </Box>
                  <ul className="threeColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("growth")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Growth YoY
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("purchase")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Purchase Price
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("rental")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Rental Yield
                      </Button>
                    </li>
                  </ul>
                  <ul className="listTwo">
                    <li>
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          lineHeight: "1.5",
                          textAlign: "center",
                          padding: "10px 15px",
                          minWidth: "68px",
                          borderRadius: "8px",
                          border: "3px solid #ACE7E7",
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#56b2b2 !important"
                              : "#ACE7E7 !important",
                          color:
                            theme.palette.mode === "dark"
                              ? "#ffffff !important"
                              : "#ffffff !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgb(79 162 165 / 60%) !important"
                              : "#5BC0BE !important",
                          boxShadow: "none !important",
                        }}
                      >
                        400 - 600K
                      </Button>
                    </li>
                  </ul>
                </div>

                <div
                  className={`fourStep_thirdStep_box  ${activeSubIndex === "rental" ? "" : "hidden"
                    }`}
                >
                  <Box className="fourStep_thirdStep_line">
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/fourStep_thirdStep_e.png`}
                    />
                  </Box>
                  <ul className="threeColumn_list listOne">
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("growth")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Growth YoY
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree"
                        onClick={() => handleSubTitle("purchase")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Purchase Price
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        className="btn_styleThree active"
                        onClick={() => handleSubTitle("rental")}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "#5bc0be !important"
                              : "#5BC0BE !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#345060 !important"
                              : "#EFF9F8 !important",
                        }}
                      >
                        Rental Yield
                      </Button>
                    </li>
                  </ul>
                  <ul className="listTwo">
                    <li>
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          lineHeight: "1.5",
                          textAlign: "center",
                          padding: "10px 15px",
                          minWidth: "68px",
                          borderRadius: "8px",
                          border: "3px solid #ACE7E7",
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#56b2b2 !important"
                              : "#ACE7E7 !important",
                          color:
                            theme.palette.mode === "dark"
                              ? "#ffffff !important"
                              : "#ffffff !important",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "rgb(79 162 165 / 60%) !important"
                              : "#5BC0BE !important",
                          boxShadow: "none !important",
                        }}
                      >
                        Min - 5.5
                      </Button>
                    </li>
                  </ul>
                </div>

                {/* <ul className="list_three hidden">
                  <li></li>
                  <li>
                    <Button variant="contained" className="btn_styleThree active">
                      Growth YoY
                    </Button>
                  </li>
                  <li>
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/investmentStrategy_rightCurve.svg`}
                    />
                    <Button variant="contained" className="btn_styleThree">
                      Purchase Price
                    </Button>
                  </li>
                  <li>
                    <img
                      className="w-full"
                      alt="profile-user"
                      src={`/investmentStrategy_straightLine.svg`}
                    />
                    <Button variant="contained" className="btn_styleThree">
                      Rental Yield
                    </Button>
                  </li>
                </ul>

                <Box className="finish_line hidden">
                  <img
                    alt="profile-user"
                    src={`/finish_img.png`}
                  />
                </Box> */}
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} className="screentoCapture">
            <Card
              sx={{
                padding: "0px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                backgroundColor: colors.primary[400],
              }}
            >
              <Grid
                container
                spacing={0}
                columns={12}
                alignItems={"center"}
                p={2.5}
              >
                <Grid item md={12} lg={6}>
                  <Typography
                    variant="h4"
                    align="left"
                    zIndex={1}
                    gutterBottom
                    sx={{
                      margin: "0px",
                      fontWeight: "600",
                    }}
                  >
                    Finance Details
                  </Typography>
                </Grid>
                <Grid item md={12} lg={6} textAlign={"right"}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={addBtnLoading}
                    onClick={() => addAccount(userProfile.id)}
                    sx={{
                      borderRadius: "10px",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      color: "#ffffff",
                      padding: "10px 28px",
                    }}
                  >
                    Add Account
                  </Button>
                </Grid>
              </Grid>
              <TableContainer className="table_styleOne">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "rgb(255 255 255 / 60%) !important"
                              : "rgb(16 24 40 / 60%) !important",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "rgb(255 255 255 / 60%) !important"
                              : "rgb(16 24 40 / 60%) !important",
                        }}
                      >
                        Account Number
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "rgb(255 255 255 / 60%) !important"
                              : "rgb(16 24 40 / 60%) !important",
                        }}
                      >
                        Bank Name
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "rgb(255 255 255 / 60%) !important"
                              : "rgb(16 24 40 / 60%) !important",
                        }}
                      >
                        Account type
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "rgb(255 255 255 / 60%) !important"
                              : "rgb(16 24 40 / 60%) !important",
                        }}
                      >
                        Current Interest Rate
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? "rgb(255 255 255 / 60%) !important"
                              : "rgb(16 24 40 / 60%) !important",
                        }}
                        align="center"
                      >
                        Account balance
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {userBankingInfo?.consent?.status === "active" ? (
                      userBankingInfo.accounts.length > 0 ? (
                        userBankingInfo.accounts.slice(startIndex, endIndex).map((transaction, i) => (
                          <TableRow key={i}>
                            <TableCell>{transaction.accountHolder}</TableCell>
                            <TableCell>{transaction.accountNo}</TableCell>
                            <TableCell style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                              {transaction.bank_name ? transaction.bank_name : '-'}
                            </TableCell>
                            <TableCell>{transaction?.class?.type}</TableCell>
                            <TableCell>
                              {transaction.interestRateStatus === 'poor' ? <img
                                alt="profile-user"
                                width="100%"
                                height="100%"
                                src={`/down_red-icon.png`}
                                style={{
                                  width: "auto",
                                  height: "15px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  marginRight: "5px",
                                }}
                              /> : transaction.interestRateStatus === 'good' ? <img
                                alt="profile-user"
                                width="100%"
                                height="100%"
                                src={`/up_green-icon.png`}
                                style={{
                                  width: "auto",
                                  height: "15px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  marginRight: "5px",
                                }}
                              /> : <img
                                alt="profile-user"
                                width="100%"
                                height="100%"
                                src={`/up_down-icon.png`}
                                style={{
                                  width: "auto",
                                  height: "15px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  marginRight: "5px",
                                }}
                              />}
                              {transaction.meta && Array.isArray(transaction.meta.lendingRates) && transaction.meta.lendingRates.length > 0
                                ? transaction.meta.lendingRates[0].rate * 100
                                : '0'} %
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                sx={{
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "capitalize",
                                  //  color: "#5BC0BE",
                                  color: transaction.balanceStatus === 'poor' ? '#FF5733' : transaction.balanceStatus === 'good' ? '#4CAF50' : "#5BC0BE",
                                  backgroundColor: "rgb(91 192 190 / 10%)",
                                  padding: "5px 20px",
                                  boxShadow: "none !important",
                                }}
                              >

                                ${transaction.balance}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Box
                              display="flex"
                              justifyContent={"center"}
                              textAlign={"center"}
                              p="20px"
                            >
                              No data found
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    ) : userBankingInfo.consentUrl ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box
                            display="flex"
                            justifyContent={"center"}
                            alignItems={"center"}
                            p="20px"
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => openTransactionsPopup("")}
                              sx={{
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                                color: "#ffffff",
                                padding: "10px 28px",
                              }}
                            >
                              View Finance
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box
                            display="flex"
                            justifyContent={"center"}
                            textAlign={"center"}
                            p="20px"
                          >
                            <CircularProgress color="secondary" />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="pagination">
                <Button
                  variant="contained"
                  className="btn-nav"
                  onClick={handleClickPrev}
                  disabled={currentPage === 0}
                  sx={{
                    borderRadius: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    padding: "8px 16px 8px 8px",
                    backgroundColor: "transparent !important",
                    boxShadow: "none !important",
                    color:
                      theme.palette.mode === "dark"
                        ? "rgb(255 255 255 / 50%) !important"
                        : "rgb(16 24 40 / 50%) !important",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="left-icon"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                  <span>Previous</span>
                </Button>
                <div className="page-numbers mt-2">
                  <button> Page {currentPage + 1}</button>
                </div>
                <Button
                  variant="contained"
                  className="btn-nav"
                  onClick={handleClickNext}
                  disabled={endIndex >= userBankingInfo.accounts.length}
                  sx={{
                    borderRadius: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    padding: "8px 8px 8px 16px",
                    backgroundColor: "transparent !important",
                    boxShadow: "none !important",
                    color:
                      theme.palette.mode === "dark"
                        ? "rgb(255 255 255 / 50%) !important"
                        : "rgb(16 24 40 / 50%) !important",
                  }}
                >
                  <span>Next</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="right-icon"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div >


    </>
  );
};

export default withAuthenticator(DashboardContent, {
  hideSignUp: true,
  formFields: {
    setupTOTP: {
      QR: {
        totpIssuer: "investar.io",
      },
    },
  },
});
