import React from "react";
import { Container, Box, Grid, Typography, Card, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { tokens } from "../../../../theme";

const ServicesSection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const services = [
    {
      icon: "/icon_01.png",
      title: "Investment portfolio analysis",
      description: "Gain a comprehensive overview of your current investments, their allocations, and performance metrics. Our in-depth analysis provides valuable insights into your portfolio's composition and historical performance, enabling you to better understand your investment strategy and make more informed decisions about your financial future."
    },
    {
      icon: "/icon_02.png",
      title: "Help plan your next investment move using our recommendation engine",
      description: "Receive data-driven suggestions for potential investments tailored to your financial goals and risk tolerance."
    },
    {
      icon: "/icon_03.png",
      title: "Help you assess the health of your investment portfolio with so that you can make informed changes",
      description: "Get detailed insights into your portfolio's strengths, weaknesses, and areas for potential improvement or rebalancing."
    },
    {
        icon: "/icon_04.png",
        title: "We tell you ,..how you are doing as an investor based our industry experience",
        description: "Compare your investment performance against industry benchmarks and receive personalized feedback on your strategy."
      },
      {
        icon: "/icon_05.png",
        title: "We will give you tools and insights to asses your investment finances",
        description: "Access advanced financial modeling tools and in-depth market analysis to evaluate your investment decisions."
      },
      {
        icon: "/icon_06.png",
        title: "Analytics and projections",
        description: "Utilize powerful predictive models to forecast potential returns and risks across various investment scenarios. Our advanced analytics tool helps you visualize possible outcomes, allowing you to make strategic decisions with greater confidence in your long-term financial planning."
      },
    // Add the other services here...
  ];

  return (
    <Box className="servicesSection"
      py={isMobile ? 6 : 12}
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "#141B2D",         
      }}
    >       
      <Container maxWidth="xl">  
        <Grid container spacing={3} columns={12}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h1" gutterBottom sx={{ 
              fontSize: isMobile ? "28px" : isTablet ? "36px" : "42px", 
              fontWeight: "700", 
              color: colors.grey[100], 
              letterSpacing: "-1px" 
            }} mb={2}>
              We are a platform built for investors by investors
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ 
              fontSize: isMobile ? "14px" : "16px", 
              fontWeight: "400", 
              color: colors.grey[100] 
            }} mb={isMobile ? 4 : 9}>
              Diverse investment opportunities from real estate industry's advisors and experts
            </Typography>

            <Grid container spacing={isMobile ? 2 : 3}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    className="service_box"
                    sx={{
                      padding: isMobile ? "30px 20px" : "45px 30px",
                      height: "100%",
                      borderRadius: "20px",
                      textAlign: "left",
                      backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "#1F2A40",
                      boxShadow: "0px 4px 34px 0px rgb(0 0 0 / 09%)",
                    }}
                  >
                    <img
                      alt={`service icon ${index + 1}`}
                      src={service.icon}
                      style={{ marginBottom: '1rem' }}
                    />
                    <Typography variant="h4" gutterBottom sx={{ 
                      fontSize: isMobile ? "20px" : isTablet ? "22px" : "26px", 
                      fontWeight: "600", 
                      color: colors.grey[100] 
                    }} mt={2} mb={1}>
                      {service.title}
                    </Typography>
                    <Typography variant="h6" gutterBottom sx={{ 
                      fontSize: isMobile ? "14px" : "16px", 
                      fontWeight: "400", 
                      color: colors.grey[100] 
                    }} mb={0}>
                      {service.description}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container> 
    </Box>
  );
};

export default ServicesSection;