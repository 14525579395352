import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import AuthenticatedAPI from '../../../auth_helper';
import { toast } from 'react-toastify';

// Helper function to convert slug to title
const convertSlugToTitle = (slug: string): string => {
  return slug
    .split('-')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Validation schema using yup
const validationSchema = yup.object({
  companyName: yup.string().required('Company name is required'),
  contactName: yup.string().required('Contact name is required'),
  contactEmail: yup
    .string()
    .email('Invalid email format')
    .required('Contact email is required'),
  contactPhone: yup.string().required('Contact phone is required'),
  businessAddress: yup.string().required('Business address is required'),
  workedSinceYear: yup
    .number()
    .required('Year is required')
    .min(1900, 'Invalid year')
    .max(new Date().getFullYear(), 'Invalid year'),
  workedSinceMonth: yup.string().required('Month is required'),
});

const FormComponent: React.FC = () => {
  const { type, id } = useParams<{ type: string; id?: string }>();
  const [title, setTitle] = useState('');
  const [advisorData, setAdvisorsData] = useState<any>(null);

  useEffect(() => {
    if (type) {
      setTitle(convertSlugToTitle(type));
    }
  }, [type]);

  const fetchAdvisorData = async () => {
    try {
      if (id) {
        const response = await AuthenticatedAPI.request('AdvisorsAPI', 'get', `/advisors/details/${id}`);
        setAdvisorsData(response[0]);
        formik.setValues({
          userId: response.userId || '',
          needsAdvisor: response[0].needs_advisor || false,
          noNeedAdvisor: response[0].no_need_advisor || false,
          companyName: response[0].company_name || '',
          contactName: response[0].contact_name || '',
          contactEmail: response[0].contact_email || '',
          contactPhone:  response[0].contact_phone || '',
          businessAddress: response[0].business_address || '',
          workedSinceYear: response[0].worked_since_year || new Date().getFullYear(),
          workedSinceMonth: response[0].worked_since_month || 'January',
          hasSeekedAdvice: response[0].has_seeked_advice || false,
        });
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchAdvisorData();
  }, []);

  const formik = useFormik({
    initialValues: {
      userId: '', // Replace with actual user ID
      needsAdvisor: false,
      noNeedAdvisor: false,
      companyName: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      businessAddress:'',
      workedSinceYear: new Date().getFullYear(),
      workedSinceMonth: 'January',
      hasSeekedAdvice: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username; // Assuming `id` is the correct property for user ID

        // Include userId in the values
        const submissionValues = {
          ...values,
          userId,
          type: title,
        };

        const response = await AuthenticatedAPI.request('AdvisorsAPI', id ? 'put' : 'post', `/advisors${id ? `/${id}` : ''}`, {
          body: submissionValues,
        });

        toast.success(response.msg)

        if (!response) {
          throw new Error('Failed to submit form');
        }
      } catch (error:any) {
        console.error('Error submitting form:', error);
        if (error.response) {
          toast.error(error.response.data.error || 'Already exists !!!!');
        }
      }
    },
  });

  return (
    <Container maxWidth="md" className='mt-4'>

<Box mb={4} display="flex" justifyContent="start" textAlign="start">
        
<Grid item xs={2}>
            <Button variant="contained" color="primary" onClick={() => window.history.back()}>
              Back
            </Button>
          </Grid>
      </Box>
      <Box mb={4} display="flex" justifyContent="center" textAlign="center">
        
        <Typography variant="h4">{title || advisorData?.advisor_type}</Typography>
      </Box>

      <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company name"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                helperText={formik.touched.companyName && formik.errors.companyName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Contact name"
                name="contactName"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contactName && Boolean(formik.errors.contactName)}
                helperText={formik.touched.contactName && formik.errors.contactName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Contact email"
                name="contactEmail"
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
                helperText={formik.touched.contactEmail && formik.errors.contactEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Contact phone"
                name="contactPhone"
                value={formik.values.contactPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contactPhone && Boolean(formik.errors.contactPhone)}
                helperText={formik.touched.contactPhone && formik.errors.contactPhone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Business address"
                name="businessAddress"
                value={formik.values.businessAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.businessAddress && Boolean(formik.errors.businessAddress)}
                // helperText={formik.touched.businessAddress && formik.errors.businessAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Worked with since (Year)"
                name="workedSinceYear"
                type="number"
                value={formik.values.workedSinceYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.workedSinceYear && Boolean(formik.errors.workedSinceYear)}
                helperText={formik.touched.workedSinceYear && formik.errors.workedSinceYear}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Month</InputLabel>
                <Select
                  name="workedSinceMonth"
                  value={formik.values.workedSinceMonth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.workedSinceMonth && Boolean(formik.errors.workedSinceMonth)}
                >
                  {[
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                  ].map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.workedSinceMonth && formik.errors.workedSinceMonth && (
                  <Typography variant="body2" color="error">
                    {formik.errors.workedSinceMonth}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="hasSeekedAdvice"
                    checked={formik.values.hasSeekedAdvice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Have you seeked advice regarding property investing?"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="needsAdvisor"
                    checked={formik.values.needsAdvisor}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label={`Don't have a ${title} advisor? Click here and we will find a recommendation for you`}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="noNeedAdvisor"
                    checked={formik.values.noNeedAdvisor}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Don't want to speak to a financial advisor? Click here"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default FormComponent;
