import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Button,
  LinearProgress,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  useTheme,
} from "@mui/material";
import { TextField } from "formik-material-ui";
import PeopleIcon from "@mui/icons-material/People";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import * as yup from "yup";
import { UserState } from "../../../types/User";
import { tokens } from "../../../theme";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch } from "react-redux";
import { updateFamilyInfo, updatePersonalInfo } from "../../../features/userSlice";
import { Auth } from "aws-amplify";
import { RemoveFamilyMember } from "../../../hooks/userprofile/user";
const FamilyMembersSchema = yup.object({
  earningMembers: yup.array().of(
    yup.object({
      name: yup.string().required("Name is required"),
      // relation: yup.string().required("Relationship is required"),
    })
  ),
  dependentKids: yup.array().of(
    yup.object({
      name: yup.string().required("Name is required"),
      age: yup
        .number()
        .required("Age is required")
        .positive("Age must be positive")
        .integer(),
    })
  ),
});

interface Familymembers {
  earningMembers: any;
  dependentKids: any;
  familyInfo: any;

}
interface ProfileData {
  familyInfo: Familymembers;
}
interface FamilyMembersProps {
  handleNext: (values: Partial<UserState>) => void;
  handleBack: () => void;
  profiledata: ProfileData
  formData: UserState;
  submit: () => void;
}

const FamilyMembers: React.FC<FamilyMembersProps> = ({
  handleNext,
  handleBack,
  profiledata,
  submit,
  formData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch()
  const [userdata, setUserData] = React.useState({})

  const user = async () => {
    const userData = await Auth?.currentAuthenticatedUser()
    // Assuming storage is your storage object (e.g., localStorage, sessionStorage)


    // Step 1: Extract the user data string
    const keyPrefix = "CognitoIdentityServiceProvider.5t6cadapsb5aef6u7acf91gu3g";
    const userId = userData.username;
    const userDataKey = `${keyPrefix}.${userId}.userData`;
    const userDataString = userData.storage.getItem(userDataKey);

    if (userDataString) {
      // Step 2: Parse the JSON string
      const userData = JSON.parse(userDataString);

      // Step 3: Access the desired attributes
      const sub = userData.UserAttributes.find((attr: { Name: string; }) => attr.Name === "sub")?.Value;
      const email = userData.UserAttributes.find((attr: { Name: string; }) => attr.Name === "email")?.Value;
      const name = userData.UserAttributes.find((attr: { Name: string; }) => attr.Name === "name")?.Value;
      const family_name = userData.UserAttributes.find((attr: { Name: string; }) => attr.Name === "family_name")?.Value;
      const values = {

        id: sub,
        firstName: name,
        lastName: family_name,
        email: email,
        phoneNumber: "987987897897"
      }
      setUserData(values)
      // console.log("User ID (sub):", sub);
      // console.log("Email:", email);
    } else {
    }


  }
  React.useEffect(() => {
    user()
  }, [])
  return (
    <Formik
      initialValues={{
        // profiledata.financialGoal?.financialGoal[0]?.passive_income ?? formData.financialGoal.passiveIncome,
        // earningMembers: (profiledata.familyInfo && profiledata.familyInfo.earningMembers && profiledata.familyInfo.earningMembers.length > 0) ? profiledata.familyInfo.earningMembers : formData.familyInfo.earningMembers,
        // dependentKids: (profiledata.familyInfo && profiledata.familyInfo.dependentKids && profiledata.familyInfo.dependentKids.length > 0) ? profiledata.familyInfo.dependentKids : formData.familyInfo.dependentKids,

        earningMembers: (profiledata?.familyInfo && profiledata?.familyInfo?.earningMembers && profiledata?.familyInfo.earningMembers.length > 0) ? profiledata?.familyInfo.earningMembers : [{ name: "" }],
        dependentKids: (profiledata?.familyInfo && profiledata?.familyInfo?.dependentKids && profiledata?.familyInfo?.dependentKids.length > 0) ? profiledata?.familyInfo.dependentKids : [{ name: "", age: "" }],
        // earningMembers: (profiledata.familyInfo?.earningMembers.length > 0 && profiledata.familyInfo?.earningMembers) ?? formData.familyInfo.earningMembers,
        // dependentKids: (profiledata.familyInfo?.dependentKids.length > 0 && profiledata.familyInfo?.dependentKids) ?? formData.familyInfo.dependentKids,
      }}
      // validationSchema={FamilyMembersSchema}
      onSubmit={(values, { setSubmitting }) => {


        // dispatch(updateFamilyInfo(values || {}));
        // dispatch(submit())
        // dispatch(updatePersonalInfo(userdata || {}));
        handleNext({ familyInfo: values });
        setSubmitting(false);
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <div className="flex flex-col items-center justify-center">
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.primary[100],
            }}
          >
            Family Members List
          </Typography>
          <Form className="w-full">

            <Box my={3} display="flex" justifyContent="space-between">
              {/* <Button variant="contained" onClick={handleBack}
                sx={{
                  color: colors.grey[900],
                  backgroundColor: colors.blueAccent[100],
                  padding: '15px 18px 15px 15px',
                  height: '40px',
                  "&:hover": {
                    backgroundColor: colors.blueAccent[300], // Adjust hover color as needed
                  },
                }}>
                <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
              </Button> */}

              <Box sx={{ position: 'relative', width: 200 }} onClick={handleBack}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    color: '#fff',
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                      '& .overlay': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'white',
                      overflow: 'hidden',
                      width: 0,
                      height: '100%',
                      transition: 'all 0.6s ease-in-out 0s',
                    }}
                  >
                    <Box
                      className="text"
                      sx={{
                        color: 'black',
                        fontSize: 16,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
                    </Box>
                  </Box>
                </Button>
              </Box>


              {/* <Button
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
                sx={{
                  color: colors.grey[900],
                  backgroundColor: colors.blueAccent[100],
                  padding: '15px 15px 15px 18px',
                  height: '40px',
                  "&:hover": {
                    backgroundColor: colors.blueAccent[300], // Adjust hover color as needed
                  },
                }}
              >
                Save & Continue.. <ArrowForwardIcon sx={{ ml: 1, }} />
              </Button> */}


              <Box sx={{ position: 'relative', width: 200 }} >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  sx={{
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    color: '#fff',
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                      '& .overlay': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  Save & Continue<ArrowForwardIcon sx={{ ml: 1, }} />
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'white',
                      overflow: 'hidden',
                      width: 0,
                      height: '100%',
                      transition: 'all 0.6s ease-in-out 0s',
                    }}
                  >
                    <Box
                      className="text"
                      sx={{
                        color: 'black',
                        fontSize: 16,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Save & Continue<ArrowForwardIcon sx={{ ml: 1, }} />
                    </Box>
                  </Box>
                </Button>
              </Box>



            </Box>

            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "30px !important",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                // backgroundColor: colors.blueAccent[900],
                borderRadius: "10px",
              }}
            >

              <CardContent sx={{ padding: "10px !important" }}>

                <FieldArray name="earningMembers">
                  {({ push, remove }) => (
                    <React.Fragment>
                      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                        <PeopleIcon
                          color="primary"
                          sx={{ color: colors.primary[100], mr: 1 }}
                        />{" "}
                        Earning Members
                      </Typography>
                      <Grid
                        container
                        columns={12}
                        alignItems="end"
                      >
                        <Grid item xs={12} sm={12} md={9} lg={10} >
                          {values.earningMembers && values.earningMembers.map((_: any, index: number) => (
                            <Grid
                              container
                              spacing={3}
                              key={index}
                              mb={3}
                            >
                              <Grid item xs={9} sm={10} md={10} lg={10}>
                                <Field
                                  component={TextField}
                                  name={`earningMembers[${index}].name`}
                                  label="Name"
                                  fullWidth
                                  sx={{
                                    '& .MuiInputBase-input-focused': {
                                      color: colors.grey[100],
                                      borderColor: colors.grey[100],
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: colors.grey[100],
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: colors.grey[100], // Adjust as needed
                                    },
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={3} >
                                <Field
                                  component={TextField}
                                  name={`earningMembers[${index}].relation`}
                                  label="Relation"
                                  fullWidth
                                  sx={{
                                    '& .MuiInputBase-input-focused': {
                                      color: colors.grey[100],
                                      borderColor: colors.grey[100],
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: colors.grey[100],
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: colors.grey[100], // Adjust as needed
                                    },
                                  }}
                                />
                                
                              </Grid> */}
                              <Grid item xs={3} sm={2} md={2} lg={2} textAlign={'right'}>
                                <Button type="button" onClick={() => {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                  profiledata?.familyInfo.earningMembers.length > 0
                                    ? (RemoveFamilyMember(_.id), remove(index))
                                    : remove(index);
                                }} sx={{
                                  backgroundColor: colors.redAccent[500],
                                  color: 'white',
                                  padding: '16px 15px 15px 15px',
                                  height: '33px',
                                  width: "36px",
                                  minWidth: "auto",
                                  // "&:hover": {
                                  //   backgroundColor: colors.redAccent[600], // Adjust hover color as needed
                                  // },
                                }}>
                                  <DeleteOutlineOutlinedIcon sx={{ color: '#ffffff' }} />

                                </Button>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={2} mb={3} textAlign={'right'}>
                          <Button
                            type="button"
                            onClick={() => push({ name: "" })}
                            sx={{
                              // color: colors.grey[100],
                              color: "#50cd89",
                              // backgroundColor: colors.blueAccent[500],
                              backgroundColor: " #e8fff3",
                              padding: '16px 15px 15px 15px',
                              height: '40px',

                              // border: `1px solid ${colors.blueAccent[500]}`,
                              border: "1px solid #50cd89",
                              // "&:hover": {

                              //   color: colors.blueAccent[100],
                              //   border: `1px solid ${colors.blueAccent[100]}`,
                              // },
                            }}
                          >
                            <span className="hidden lg:block mx-1">Add Member</span>{" "}
                            <AddOutlinedIcon
                              sx={{ ml: 0, }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </FieldArray>
                <FieldArray name="dependentKids">
                  {({ push, remove }) => (
                    <React.Fragment>
                      <Typography variant="h6" gutterBottom my={2}>
                        <ChildCareIcon
                          color="primary"
                          sx={{ color: colors.primary[100], mr: 1 }}
                        />{" "}
                        Dependent Kids
                      </Typography>
                      <Grid
                        container
                        columns={12}
                        alignItems="end"
                      >
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                          {values.dependentKids && values.dependentKids.map((_: any, index: any) => (
                            <Grid
                              container
                              spacing={2}
                              key={index}
                              mb={3}
                            >
                              <Grid item xs={6} sm={7} md={7} lg={8}>
                                <Field
                                  component={TextField}
                                  name={`dependentKids[${index}].name`}
                                  label="Name"
                                  fullWidth
                                  sx={{
                                    '& .MuiInputBase-input-focused': {
                                      color: colors.grey[100],
                                      borderColor: colors.grey[100],
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: colors.grey[100],
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: colors.grey[100], // Adjust as needed
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={2}>
                                <Field
                                  component={TextField}
                                  name={`dependentKids[${index}].age`}
                                  label="Age"
                                  fullWidth
                                  sx={{
                                    '& .MuiInputBase-input-focused': {
                                      color: colors.grey[100],
                                      borderColor: colors.grey[100],
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: colors.grey[100],
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: colors.grey[100], // Adjust as needed
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3} sm={2} md={2} lg={2} textAlign={'right'}>
                                <Button type="button"
                                  // disabled={profiledata?.familyInfo && profiledata?.familyInfo.dependentKids.length > 0 ? true : false} 
                                  onClick={() => {
                                    if (profiledata?.familyInfo.earningMembers.length > 0) {
                                      RemoveFamilyMember(_.id);
                                      remove(index);
                                    } else {
                                      remove(index);
                                    }
                                  }}
                                  sx={{
                                    backgroundColor: colors.redAccent[500],
                                    color: 'white',
                                    padding: '16px 15px 15px 15px',
                                    height: '33px',
                                    width: "36px",
                                    minWidth: "auto",
                                    "&:hover": {
                                      backgroundColor: colors.redAccent[600], // Adjust hover color as needed
                                    },
                                  }}>
                                  <DeleteOutlineOutlinedIcon sx={{ color: '#ffffff' }} />

                                </Button>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} mb={3} textAlign={'right'}>
                          <Button
                            type="button"
                            onClick={() => push({ name: "", age: "" })}
                            sx={{
                              // color: colors.grey[100],
                              color: "#50cd89",
                              // backgroundColor: colors.blueAccent[500],
                              backgroundColor: " #e8fff3",
                              padding: '16px 15px 15px 15px',
                              height: '40px',

                              // border: `1px solid ${colors.blueAccent[500]}`,
                              border: "1px solid #50cd89",
                              // "&:hover": {

                              //   color: colors.blueAccent[100],
                              //   border: `1px solid ${colors.blueAccent[100]}`,
                              // },
                            }}
                          >
                            <span className="hidden lg:block mx-1">Add Kid</span> {" "}
                            <AddOutlinedIcon
                              sx={{ ml: 0, }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </FieldArray>
                {isSubmitting && <LinearProgress />}

              </CardContent>
            </Card>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default FamilyMembers;
