import { Auth } from "aws-amplify";
import AuthenticatedAPI from "../../auth_helper";



const fetchPortfolio = async (invId:any) => {

    try {

        const user = await Auth.currentAuthenticatedUser();
        const userAttributes = await Auth.userAttributes(user);
        const id = userAttributes.find((attr) => attr.Name === "sub")?.Value ?? "";
        const response = await AuthenticatedAPI.request(
          'BuyersAgentsAPI',
          'get',
          `/agents/buyers-agents/${id}/portfolio-analysis/${invId}`
        );

        if (!response) {
          throw new Error('Failed to delete property');
        }
        return response.data
 
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } 
  }

// const fetchAdvisors = async (id: any) => {

//     try {
//         const response = await AuthenticatedAPI.request(
//             'BuyersAgentsAPI',
//             'get',
//             `/agents/buyers-agents/${id}`
//         );

//         if (!response) {
//             throw new Error('Failed to create buyer-agent');
//         }

//         return response;
//     } catch (error) {
//         console.error('Error creating buyer-agent:', error);
//         return null; // Or some default/fallback value
//     }
// };

const fetchAdvisors = async (id: any, investor_name?: string) => {
  try {
      const requestBody = investor_name ? { investor_name } : {};

      const response = await AuthenticatedAPI.request(
          'BuyersAgentsAPI',
          'get',
          `/agents/buyers-agents/${id}?name=${investor_name?investor_name:''}`,
         
      );

      if (!response) {
          throw new Error('Failed to fetch buyer-agent information');
      }
      console.log(response,investor_name)

      return response;
  } catch (error) {
      console.error('Error fetching buyer-agent information:', error);
      return null; // Or some default/fallback value
  }
};



  export function formatCurrency(value:any)  {  if (value >= 1e9) { // 1 billion
    return (value / 1e9).toFixed(1) + ' B'; // Format in billions
} else if (value >= 1e6) { // 1 million
    return (value / 1e6).toFixed(1) + ' M'; // Format in millions
} else if (value >= 1e3) { // 1 thousand
    return (value / 1e3).toFixed(1) + ' k'; // Format in thousands
} else {
    return value.toString(); // Return the value as is
}
}
const fetchStrategy = async (invId:any) => {

    try {

        const user = await Auth.currentAuthenticatedUser();
        const userAttributes = await Auth.userAttributes(user);
        const id = userAttributes.find((attr) => attr.Name === "sub")?.Value ?? "";
        const response = await AuthenticatedAPI.request(
          'BuyersAgentsAPI',
          'get',
          `/agents/buyers-agents/${id}/strategies/${invId}`
        );

        if (!response) {
          throw new Error('Failed to delete property');
        }
        return response.data
 
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }


  function convertToSubcurrency(amount: number, factor = 100) {
    return Math.round(amount * factor);
  }
  
  export default convertToSubcurrency;




export { fetchAdvisors ,fetchPortfolio,fetchStrategy,convertToSubcurrency}