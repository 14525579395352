import React, { useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext, tokens } from "../../../theme";
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  InputAdornment,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchPageProps {
  handleSearch: (searchTerm: any) => void;
  agents: [] | any; // Define the prop type
  agentsDetail: [] | any;
}
interface Agent {
  investor_name: string;
  // Add other properties of the agent object here
}

const SearchPage: React.FC<SearchPageProps> = ({
  handleSearch,
  agents,
  agentsDetail
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const heroImg =
    theme.palette.mode === "light" ? "/hero-light.svg" : "/hero-dark.svg";
  const heroBackgroundColor =
    theme.palette.mode === "light" ? "#E9FFFC" : "#182036";

  const handleInputChange = (event: any) => {
    console.log(event.target);
    // handleSearch(event.target.value);
  };

  const handleSearchClick = () => {
    console.log("Search term:", searchTerm);
    handleSearch(searchTerm); // Use the handleSearch prop
  };
  const handleOptionChange = (event: any, value: Agent | null) => {
    // setSelectedAgent(value);
    // console.log(value);
    handleSearch(value);
    // Call your handle click function here with the full selected object
  };

  agentsDetail?.sort((a: any, b: any) => {
    const aTotalEquity = Number(a.totalequity) || 0; // Use 0 if NaN
    const bTotalEquity = Number(b.totalequity) || 0; // Use 0 if NaN
    console.log(bTotalEquity - aTotalEquity); // Sort in ascending order
  });
  console.log(
    agentsDetail?.sort((a: any, b: any) => a.totalequity < b.totalequity)
  );
  function sortNumber(a: any, b: any) {
    return a - b;
  }
  return (
    <>
      <Box
        className="search_agentSection pt-20 pb-60"
        sx={{
          backgroundColor: heroBackgroundColor,
        }}
      >
        <div
          className="heroimg"
          style={{ backgroundImage: `url(${heroImg})` }}
        ></div>
        <Container maxWidth="xl" className="relative">
          <Grid container columns={12} justifyContent={"center"}>
            <Grid item md={8} lg={6} xl={6} textAlign={"center"}>
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: "36px",
                  fontWeight: "700",
                  color: colors.grey[100],
                }}
                mb={2}
              >
                Search For An Investor
              </Typography>
              <Box className="custom_searchOne mb-10">
                <Autocomplete
                  id="free-solo-demo"
                  options={agents || []} // Pass the full array of objects
                  getOptionLabel={(option: Agent) => option?.investor_name} // Display the label
                  onChange={handleOptionChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search by Investor Name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              {/* <Box
                className="flex relative"
                alignItems="center"
                justifyContent="center"
                mt={2}
              >
                <TextField
                  label="Enter Agent Name"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleInputChange}
                  sx={{ marginRight: 2, width: "300px" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
              </Box> */}
            </Grid>
            <Grid item md={12} lg={12}>
              <Card
                sx={{
                  padding: "20px !important",
                  margin: "0px auto !important",
                  borderRadius: "15px !important",
                  boxShadow: "0px 12px 12px -6px rgb(0 0 0 / 20%) !important",
                  backgroundColor: theme.palette.mode === 'dark' ? '#6c63ff' : '#ffffff',
                  maxWidth: "800px",
                }}
              >
                <Typography
                  variant="h4"
                  align="left"
                  zIndex={1}
                  gutterBottom
                  sx={{
                    padding: "5px 5px 16px",
                    margin: "0px",
                    fontWeight: "600",
                  }}
                >
                  Investors
                </Typography>

                <TableContainer className="table_styleTwo">
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#ffffff", // Replace with actual color
                            fontWeight: "bold",
                            backgroundColor: theme.palette.mode === 'dark' ? '#0c0c28' : "#005957", // Light background for contrast
                            borderBottom: "0px solid #ccc", // Stronger bottom border
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#ffffff", // Replace with actual color
                            fontWeight: "bold",
                            backgroundColor: theme.palette.mode === 'dark' ? '#0c0c28' : "#005957", // Light background for contrast
                            borderBottom: "0px solid #ccc", // Stronger bottom border
                          }}
                          align="right"
                        >
                          Equity
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {agentsDetail
                        ?.sort((a: any, b: any) => {
                          const aTotalEquity = Number(a.totalequity) || 0; // Use 0 if NaN
                          const bTotalEquity = Number(b.totalequity) || 0; // Use 0 if NaN
                          return bTotalEquity - aTotalEquity; // Sort in descending order
                        })
                        .map((property: any) => {
                          return (
                            <TableRow
                              key={property.investor_name}
                              sx={{
                                "&:hover": { backgroundColor: "#f0f0f0" },
                                cursor: "pointer" // Add cursor pointer
                              }}
                              onClick={() => handleOptionChange('', property)} // Handle click event

                            >
                              <TableCell sx={{ padding: "16px" }}>
                                {property.investor_name}
                              </TableCell>
                              <TableCell align="right" sx={{ padding: "16px" }}>
                                {parseFloat(property.totalequity).toFixed(0)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>

            <Grid item md={12} lg={12} textAlign={"right"}>
              {/* <Button
                variant="contained"
                color="secondary"
                disabled={false} // Make sure it's always enabled
                sx={{
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  color: "#ffffff",
                  padding: "10px 28px",
                }}
              >
                Add Account
              </Button> */}

              {/* <div className="pagination">
                <Button
                  variant="contained"
                  className="btn-nav"
                  sx={{
                    borderRadius: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    padding: "8px 16px 8px 8px",
                    backgroundColor: "transparent !important",
                    boxShadow: "none !important",
                    color: "yourColorHere", // Replace with actual color
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="left-icon"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                  <span>Previous</span>
                </Button>
                <div className="page-numbers">
                </div>
                <Button
                  variant="contained"
                  className="btn-nav"
                  sx={{
                    borderRadius: "0px",
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    padding: "8px 8px 8px 16px",
                    backgroundColor: "transparent !important",
                    boxShadow: "none !important",
                    color: "yourColorHere", // Replace with actual color
                  }}
                >
                  <span>Next</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="right-icon"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </Button>
              </div> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SearchPage;