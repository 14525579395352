import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, validateYupSchema } from "formik";
import {
  Button,
  LinearProgress,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  useTheme,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  Select

} from "@mui/material";
import { TextField } from "formik-material-ui";
import Autocomplete from '@mui/material/Autocomplete';
import HomeIcon from "@mui/icons-material/Home";
import * as yup from "yup";
import { UserState, CurrentPortFolio, CurrentPortfolioInfo } from "../../../types/User";
import { tokens } from "../../../theme";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import { useFetchProperties, Result, useFetchpropertyavm, deleteProperty, useFetchpropertDetailed, AutoUpdateFetchpropertyavm } from "../../../hooks/properties/properties";

const InvestmentPropertiesSchema = yup.object({
  investmentProperties: yup.array().of(
    yup.object({
      id: yup.string().nullable(),
      address: yup.string().required("Address is required"),

      currentestimatedvalue: yup.number().required("Current estimated value is required"),
      initialpurchaseprice: yup.number().required("Initial purchase price is required"),
      netrentalyield: yup.number().required("Net rental yield is required"),
      weeklyrent: yup.number().required("weekly rent is required"),
      currentloanAmount: yup.number().required("Current loan amount is required"),
      yearofpurchase: yup.number().required("Current loan amount is required"),
    })
  ),
});

interface ProfileData {
  currentPortfolio: CurrentPortfolioInfo;
}
interface InvestmentPropertiesProps {
  handleNext: (values: Partial<UserState>) => void;
  handleBack: () => void;
  profiledata: ProfileData;
  formData: UserState;
}
interface Property {
  address: string;
}
const CurrentPortfolio: React.FC<InvestmentPropertiesProps> = ({
  handleNext,
  handleBack,

  profiledata,
  formData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(currentYear - 1999), (val, index) => 2000 + index);
  const [addres, setAddress] = useState('');
  const [propertyID, setPropertyID] = useState('');
  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);

  const [propertyIDS, setPropertyIDS] = useState<string[]>([]);
  const [currentEstimatedValue, setCurrentEstimatedValue] = useState<string[]>([]);
  const [propertyDetailed, setPropertyDetails] = useState<any[]>([]);

  const [dbCurrentEstimatedValue, setDBCurrentValue] = useState<string[]>([]);

  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);

  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: boolean }>({});
  const { propertyData, propertyLoading, error } = useFetchProperties(addres);
  const { propertyavmdata, propertyavmloading, propertyavmerror } = useFetchpropertyavm(propertyID);

  const { propertyDetaileddata, propertyDetailedloading, propertyDetailederror } = useFetchpropertDetailed(propertyID);

  const [isAutocompleteActive, setIsAutocompleteActive] = useState<number | null>(null);

  const toggleAutocomplete = (index: number) => {
    // 

    if (isAutocompleteActive !== index && selectedAddresses) {
      setIsAutocompleteActive((prev) => (prev === index ? null : index));
    }
  };
  const HandleInput = (value: string) => {

    setAddress(value);
  };
console.log('propertyIDS :>> ', propertyIDS);
  const handleSelect = (event: any, value: Result | null, index: number) => {

    if (value) {
      const newSelectedAddress = `${value.match.address.streetAddress} ${value.match.address.locality} ${value.match.address.state} ${value.match.address.postcode}`;

      setSelectedAddresses(prevAddresses => {
        const newAddresses = [...prevAddresses];
        if (index !== null && newAddresses[index] !== undefined) {
          newAddresses[index] = newSelectedAddress; // Update the address at the specified index
        } else {
          newAddresses.push(newSelectedAddress); // Add the new address
        }
        return newAddresses;
      });

      setSelectedAddress(newSelectedAddress);

      setPropertyID(value.match.id);


      setPropertyIDS(prevID => {
        const newPropertyIDS = [...prevID];
        if (index !== null && newPropertyIDS[index] !== undefined) {
          newPropertyIDS[index] = value.match.id; // Update the address at the specified index
        } else {
          newPropertyIDS.push(value.match.id); // Add the new address
        }
        return newPropertyIDS;
      });

      setSelectedOptions(prevOptions => ({
        ...prevOptions,
        [index]: true
      }));
    }
  };

  const addEstimatedValue = async (currentValues: string) => {
    setCurrentEstimatedValue(prevValues => {

      // Check if isAutocompleteActive is not null
      if (isAutocompleteActive !== null) {
        // Check if the value at isAutocompleteActive index exists
        if (prevValues[isAutocompleteActive] !== undefined) {
          // Replace the value at the specified index
          const newValues = [...prevValues];
          newValues[isAutocompleteActive] = currentValues;
          return newValues;
        }
      }

      // Check if the value already exists in the state
      if (!prevValues.includes(currentValues)) {
        return [...prevValues, currentValues];
      }
      return prevValues;
    });
  };


  const addPropertyDetailedValue = (currentValue: any) => {
    setPropertyDetails((prevValues) => {
      if (isAutocompleteActive !== null && prevValues[isAutocompleteActive] !== undefined) {
        const newValues = [...prevValues];
        newValues[isAutocompleteActive] = currentValue;
        return newValues;
      }
      if (!prevValues.some((detail) => detail && detail.id === currentValue.id)) {
        return [...prevValues, currentValue];
      }
      return prevValues;
    });
  };


  const propertyDeleteHandler = async (id: string) => {
    await deleteProperty(id)

  }
console.log('propertyavmdata :>> ', propertyavmdata);
  useEffect(() => {
    if (propertyavmdata && propertyavmdata) {
      addEstimatedValue(propertyavmdata?.valuation?.value)
      setAddress('')
    }
    if (propertyDetaileddata && propertyDetaileddata) {

      addPropertyDetailedValue(propertyDetaileddata?.property)
    }
  }, [propertyavmdata, propertyID, propertyDetaileddata, profiledata])

  // Fetch initial data on component mount
  useEffect(() => {
    if (profiledata?.currentPortfolio?.investmentProperties?.length > 0) {
      profiledata.currentPortfolio.investmentProperties.forEach((property) => {
        if (property) {


          setSelectedAddresses(prevValues => {
            // Check if the value already exists in the state
            if (!prevValues.includes(property.address)) {
              return [...prevValues, property.address];
            }
            return prevValues;
          })
          setCurrentEstimatedValue(prevValues => {
            // Check if the value already exists in the state
            if (!prevValues.includes(property.currentestimatedvalue)) {
              return [...prevValues, property.currentestimatedvalue];
            }
            return prevValues;
          })
          setPropertyIDS(prevID => {
            // Check if the value already exists in the state
            if (!prevID.includes(property.propertyid)) {
              return [...prevID, property.propertyid];
            }
            return prevID;
          });
          setPropertyDetails((prevValues) => {
            if (!prevValues.some((detail) => detail && detail.id === property.propertyid)) {
              return [...prevValues, property.propertydetails];
            }
            return prevValues;
          });
        }

      });
    }
  }, [profiledata?.currentPortfolio?.investmentProperties]);
  const handleValueChange = (index: number, event: { target: { value: string; }; }) => {
    const newValues = [...currentEstimatedValue];
    newValues[index] = event.target.value;
    setCurrentEstimatedValue(newValues);
  };

  // useEffect(() => {
  //   const updateEstimatedValuesForAllProperties = async () => {
  //     for (let i = 0; i < propertyIDS.length; i++) {
  //       const id = propertyIDS[i];
  
  //       // Set the current propertyID for fetching data
  //       setPropertyID(id);
  
  //       // Assuming you have a way to fetch propertyavmdata synchronously or wait for it
       
  //     const data = await AutoUpdateFetchpropertyavm(id)
  //     
  //       if (data && data.valuation?.value) {
  //         const currentValue = profiledata?.currentPortfolio?.investmentProperties[i]?.currentestimatedvalue;
  
  //         // Compare and update if needed
          

  //         if (currentValue !== data.valuation?.value) {
  //         

  //           setCurrentEstimatedValue(prevValues => {
  //             const newValues = [...prevValues];
  //             newValues[i] = propertyavmdata.valuation.value;
  //             return newValues;
  //           });
  //           const updatedValues = profiledata?.currentPortfolio?.investmentProperties.map((property, index) => {
              
    
  //             return {
  //               ...property,
  //               currentestimatedvalue: currentEstimatedValue[index] || property.currentestimatedvalue,
              
  //             };
  //           });
    
  //           handleNext({ currentPortfolio: { investmentProperties: updatedValues } });
  //         }
  //       }
  //     }
  //   };
  
  //   if (propertyIDS.length > 0) {
  //     updateEstimatedValuesForAllProperties();
  //   }
  // }, [propertyIDS, profiledata]);
  
  return (
    <Formik


      initialValues={{
        investmentProperties: (profiledata?.currentPortfolio?.investmentProperties?.length > 0)
          ? profiledata.currentPortfolio.investmentProperties.map(property => ({
            id: property.id || null,
            address: property.address || "",
            yearofpurchase: property.yearofpurchase || 0,
            currentestimatedvalue: property.currentestimatedvalue || "",
            initialpurchaseprice: property.initialpurchaseprice || 0,
            netrentalyield: property.netrentalyield || 0,
            weeklyrent: property.weeklyrent || 0,
            currentloanamount: property.currentloanamount || 0
          }))
          : [{
            id: null,
            address: "",
            yearofpurchase: 0,
            currentestimatedvalue: "",
            initialpurchaseprice: 0,
            netrentalyield: 0,
            weeklyrent: 0,
            currentloanamount: 0
          }]
      }}
      // validationSchema={InvestmentPropertiesSchema}
      onSubmit={(values, { setSubmitting }) => {
        const updatedValues = values.investmentProperties.map((property, index) => {
          const rentalYield = (property.weeklyrent * 52) / property.initialpurchaseprice * 100;

          return {
            ...property,
            address: selectedAddresses[index] || property.address, // Update address with selected address or keep the existing one
            currentestimatedvalue: currentEstimatedValue[index] || property.currentestimatedvalue,
            netrentalyield: rentalYield,
            propertydetails: propertyDetailed[index],
            propertyid: propertyIDS[index]
          };
        });

        handleNext({ currentPortfolio: { investmentProperties: updatedValues } });
        setSubmitting(false);
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <div className="flex flex-col items-center justify-center">
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.primary[100],
            }}
          >
            Investment Properties
          </Typography>

          <Form className="w-full">
            <Box my={3} display="flex" justifyContent="space-between">

              <Box sx={{ position: 'relative', width: 200 }} onClick={handleBack}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    color: '#fff',
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                      '& .overlay': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'white',
                      overflow: 'hidden',
                      width: 0,
                      height: '100%',
                      transition: 'all 0.6s ease-in-out 0s',
                    }}
                  >
                    <Box
                      className="text"
                      sx={{
                        color: 'black',
                        fontSize: 16,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
                    </Box>
                  </Box>
                </Button>
              </Box>

              <Box sx={{ position: 'relative', width: 200 }} >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  sx={{
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    color: '#fff',
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                      '& .overlay': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  Save & Continue<ArrowForwardIcon sx={{ ml: 1, }} />
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'white',
                      overflow: 'hidden',
                      width: 0,
                      height: '100%',
                      transition: 'all 0.6s ease-in-out 0s',
                    }}
                  >
                    <Box
                      className="text"
                      sx={{
                        color: 'black',
                        fontSize: 16,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Save & Continue<ArrowForwardIcon sx={{ ml: 1, }} />
                    </Box>
                  </Box>
                </Button>
              </Box>
            </Box>

            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "30px !important",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                borderRadius: "10px"
                // backgroundColor: colors.blueAccent[900],
              }}
            >
              <CardContent sx={{ padding: "10px !important" }}>
                <FieldArray name="investmentProperties">
                  {({ push, remove }) => (
                    <React.Fragment>
                      <Typography variant="h6" gutterBottom sx={{ mb: 2, fontSize: "21px", fontWeight: "600", color: `${colors.grey[100]} !important`, }}>
                        <HomeIcon
                          color="primary"
                          sx={{ color: colors.primary[100], mr: 1, }}
                        />
                        Properties
                      </Typography>

                      <Grid
                        container
                        columns={12}
                        alignItems="end"
                      >
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                          {values.investmentProperties.map((_: any, index: number) => {
                            return (
                              <Grid
                                container
                                spacing={4}
                                alignItems="end"
                                mb={3}
                                key={index}
                              >
                                <Grid item xs={9} sm={9} md={10} lg={10}>
                                  <Grid
                                    container
                                    spacing={4}
                                    alignItems="end"
                                    key={index}
                                  >
                                    <Grid item xs={6} sm={6} key={index} onClick={() => toggleAutocomplete(index)}>
                                      {isAutocompleteActive === index ? (
                                        <Autocomplete
                                          options={propertyData?.results || []}
                                          getOptionLabel={(option) =>
                                            option && `${option.match.address.streetAddress} ${option.match.address.locality} ${option.match.address.state} ${option.match.address.postcode}`
                                          }
                                          onInputChange={(e: any) => HandleInput(e.target.value)}
                                          onChange={(event, value) => handleSelect(event, value, index)}
                                          loading={propertyLoading}
                                          renderInput={(params) => (
                                            <Field
                                              component={TextField}
                                              name={`investmentProperties[${index}].address`}
                                              // value={selectedAddresses[index] && selectedAddresses[index]}
                                              label="Property address"
                                              // onChange={(e:any)=>HandleInput(e.current.value)}
                                              {...params}
                                              InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                  <>
                                                    {selectedOptions[index] && <InputAdornment position="end"><CheckIcon /></InputAdornment>}
                                                    {params.InputProps.endAdornment}
                                                  </>
                                                )
                                              }}
                                              sx={{

                                                "& .MuiInputBase-input": {
                                                  padding: '0px !important',
                                                },
                                                "& .MuiInputBase-input-focused": {
                                                  color: 'grey',
                                                  borderColor: 'grey',
                                                },
                                                "& .MuiInputLabel-root.Mui-focused": {
                                                  color: 'grey',
                                                },
                                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                  borderColor: 'grey',
                                                },
                                              }}
                                            />
                                          )}
                                        />

                                      ) : (
                                        <Field
                                          component={TextField}
                                          name={`investmentProperties[${index}].address`}
                                          value={selectedAddresses[index]}
                                          label="Property address"
                                          style={{ padding: "0px" }}
                                          fullWidth
                                          sx={{

                                            "& .MuiInputBase-input-focused": {
                                              color: 'grey',
                                              borderColor: 'grey',
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                              color: 'grey',
                                            },
                                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                              borderColor: 'grey',
                                            },
                                          }}
                                        />
                                      )}
                                    </Grid>



                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                      <Field
                                        component={TextField}
                                        name={`investmentProperties[${index}].currentestimatedvalue`}

                                        value={currentEstimatedValue[index]}

                                        onChange={(event: { target: { value: string; }; }) => handleValueChange(index, event)}
                                        // value={profiledata?.currentPortfolio?.investmentProperties.length > 0 ? dbCurrentEstimatedValue[index] : currentEstimatedValue[index]}
                                        label="Current Estimated Value"
                                        loading={propertyavmloading}
                                        style={{ padding: "0px" }}
                                        InputLabelProps={{
                                          shrink: Boolean(currentEstimatedValue[index] || ''),
                                        }}
                                        // disabled={true}
                                        fullWidth
                                        InputProps={{

                                          endAdornment: (
                                            <>
                                              {selectedOptions[index] && currentEstimatedValue[index] && <InputAdornment position="end"><CheckIcon /></InputAdornment>}
                                              {/* {params.InputProps.endAdornment} */}
                                            </>
                                          )
                                        }}

                                        sx={{
                                          "& .MuiInputBase-input-focused": {
                                            color: colors.grey[100],
                                            borderColor: colors.grey[100],
                                          },
                                          "& .MuiInputLabel-root.Mui-focused": {
                                            color: colors.grey[100],
                                          },
                                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: colors.grey[100],
                                          },
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                      <Field
                                        component={TextField}
                                        name={`investmentProperties[${index}].initialpurchaseprice`}

                                        label="Initial Purchase Price"

                                        fullWidth
                                        sx={{
                                          "& .MuiInputBase-input-focused": {
                                            color: colors.grey[100],
                                            borderColor: colors.grey[100],
                                          },
                                          "& .MuiInputLabel-root.Mui-focused": {
                                            color: colors.grey[100],
                                          },
                                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: colors.grey[100],
                                          },
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                      <Field
                                        component={TextField}
                                        name={`investmentProperties[${index}].weeklyrent`}
                                        label="Weekly Rent"
                                        fullWidth
                                        sx={{
                                          "& .MuiInputBase-input-focused": {
                                            color: colors.grey[100],
                                            borderColor: colors.grey[100],
                                          },
                                          "& .MuiInputLabel-root.Mui-focused": {
                                            color: colors.grey[100],
                                          },
                                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: colors.grey[100],
                                          },
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                      <Field
                                        component={TextField}
                                        name={`investmentProperties[${index}].currentloanamount`}
                                        label="Current Loan Amount"
                                        fullWidth
                                        sx={{
                                          "& .MuiInputBase-input-focused": {
                                            color: colors.grey[100],
                                            borderColor: colors.grey[100],
                                          },
                                          "& .MuiInputLabel-root.Mui-focused": {
                                            color: colors.grey[100],
                                          },
                                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: colors.grey[100],
                                          },
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Year Of Purchase</InputLabel>
                                        <Field
                                          as={Select}
                                          labelId={`year-of-purchase-label-${index}`}
                                          id={`year-${index}`}
                                          name={`investmentProperties[${index}].yearofpurchase`}
                                          label="Year Of Purchase"
                                          sx={{
                                            "& .MuiInputBase-input-focused": {
                                              color: colors.grey[100],
                                              borderColor: colors.grey[100],
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                              color: colors.grey[100],
                                            },
                                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                              borderColor: colors.grey[100],
                                            },
                                            minWidth: "100px",
                                          }}
                                        >
                                          {years && years.map((year) => (
                                            <MenuItem key={year} value={year} className="w-full">
                                              {year}
                                            </MenuItem>
                                          ))}
                                        </Field>
                                      </FormControl>
                                      {/* <Field
                                        className="w-full"
                                        component={Select}
                                        id="year"
                                        name={`investmentProperties[${index}].yearofpurchase`}
                                        label="Year Of Purchase"
                                        fullWidth
                                        sx={{
                                          "& .MuiInputBase-input-focused": {
                                            color: colors.grey[100],
                                            borderColor: colors.grey[100],
                                          },
                                          "& .MuiInputLabel-root.Mui-focused": {
                                            color: colors.grey[100],
                                          },
                                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: colors.grey[100],
                                          },
                                          minWidth: "100px",

                                        }}
                                      >
                                        {years &&years.map((year) => (
                                          <MenuItem key={year} value={year} className="w-full">
                                            {year}
                                          </MenuItem>
                                        ))}
                                      </Field> */}
                                    </Grid>

                                  </Grid>
                                </Grid>
                                <Grid item xs={3} sm={3} md={2} lg={2} textAlign={'right'}>
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      profiledata?.currentPortfolio?.investmentProperties.length > 0
                                        ? (propertyDeleteHandler(_.id), remove(index))
                                        : remove(index);
                                    }}
                                    // disabled={profiledata?.currentPortfolio && profiledata?.currentPortfolio?.investmentProperties.length > 0 ? true : false}
                                    sx={{
                                      backgroundColor: colors.redAccent[500],
                                      color: 'white',
                                      padding: '16px 15px 15px 15px',
                                      height: '40px',
                                      "&:hover": {
                                        backgroundColor: colors.redAccent[600], // Adjust hover color as needed
                                      },
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon sx={{ color: '#ffffff' }} />
                                    {/* <span className="hidden xl:block mx-1">Remove</span> */}
                                  </Button>
                                </Grid>
                              </Grid>
                            )
                          })}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} mb={3} textAlign={'right'}>
                          <Button
                            type="button"
                            onClick={() => {

                              push({
                                address: "",
                                currentEstimatedValue: 0,
                                initialpurchaseprice: 0,
                                netRentalYield: 0,
                                weeklyrent: 0,
                                yearofpurchase: 0,
                                currentLoanAmount: 0,
                              });
                            }}

                            sx={{
                              // color: colors.grey[100],
                              color: "#50cd89",
                              backgroundColor: " #e8fff3",
                              padding: '16px 15px 15px 15px',
                              height: '40px',
                              border: "1px solid #50cd89",
                              // border: `1px solid ${colors.blueAccent[500]}`,

                              // "&:hover": {

                              //   color: colors.blueAccent[100],
                              //   border: `1px solid ${colors.blueAccent[100]}`,
                              // },
                            }}
                          >
                            <span className="hidden lg:block mx-1">Add Property</span>
                            <AddOutlinedIcon sx={{ ml: 0 }} />
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </FieldArray>
                {isSubmitting && <LinearProgress />}

              </CardContent>
            </Card>
          </Form>
        </div>
      )
      }
    </Formik >
  );
};

export default CurrentPortfolio;


