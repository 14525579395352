import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ['Savings', 'Expenses', 'Lifestyle', 'Portfolio'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5],
      backgroundColor: [
        'rgba(255, 206, 86, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderColor: [
        'rgba(255, 206, 86, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


const BucketsChart = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const, // Update the position type to one of the allowed values
      },
      title: {
        display: true,
        text: 'Buckets',
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default BucketsChart;
