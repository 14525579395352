import React, { useEffect, useCallback, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../store";
import {
  removeProperty,
  updateProperty,
} from "../../../features/investedProperties";
import { InvestedProperty } from "../../../types/BuySell";
import { tokens } from "../../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Result,
  useFetchpropertDetailed,
  useFetchProperties,
  useFetchpropertyavm,
} from "../../../hooks/properties/properties";
import Autocomplete from "@mui/material/Autocomplete";
import { Field, Formik, useFormikContext } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import {
  CurrentPortFolio,
  CurrentPortfolioInfo,
  Property,
} from "../../../types/User";
import * as yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
import { calculateBorrowableEquity } from "../../../utils/finance_formulas";
interface PropertyColumnProps {
  index: number;
  properties: CurrentPortFolio[];
}

const InvestmentPropertiesSchema = yup.object({
  investmentProperties: yup.array().of(
    yup.object({
      // purchaseYear: yup.number().required("Purchase year is required").min(1900, "Invalid year").max(new Date().getFullYear(), "Invalid year"),
      deposit: yup
        .number()
        .required("Deposit is required")
        .min(0, "Invalid deposit amount"),
    })
  ),
});

const InvestedPropertyColumn: React.FC<PropertyColumnProps> = ({
  index,
  properties,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch: AppDispatch = useDispatch();
  const propertyyData = useSelector(
    (state: RootState) => state.investedProperties.properties[index]
  );
  const [addres, setAddress] = useState("");
  const { propertyData, propertyLoading, error } = useFetchProperties(addres);
  const [propertyID, setPropertyID] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);
  const [propertyIDS, setPropertyIDS] = useState<string[]>([]);
  const [currentEstimatedValue, setCurrentEstimatedValue] = useState<string[]>(
    []
  );
  const [isAutocompleteActive, setIsAutocompleteActive] = useState<
    number | null
  >(null);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: number]: boolean;
  }>({});
  const [propertyDetailed, setPropertyDetails] = useState<any[]>([]);
  const { propertyavmdata, propertyavmloading, propertyavmerror } =
    useFetchpropertyavm(propertyID);
  const {
    propertyDetaileddata,
    propertyDetailedloading,
    propertyDetailederror,
  } = useFetchpropertDetailed(propertyID);
  const [investmentProperties, setInvestmentProperties] = useState<
    CurrentPortFolio[]
  >([]);
  const toggleAutocomplete = (index: number) => {
    if (isAutocompleteActive !== index && selectedAddresses) {
      setIsAutocompleteActive((prev) => (prev === index ? null : index));
    }
  };

  const HandleInput = (value: string) => {
    setAddress(value);
  };

  const handleSelect = (event: any, value: Result | null, index: number) => {
    if (value) {
      const newSelectedAddress = `${value.match.address.streetAddress} ${value.match.address.locality} ${value.match.address.state} ${value.match.address.postcode}`;

      setSelectedAddresses((prevAddresses) => {
        const newAddresses = [...prevAddresses];
        if (index !== null && newAddresses[index] !== undefined) {
          newAddresses[index] = newSelectedAddress;
        } else {
          newAddresses.push(newSelectedAddress);
        }
        return newAddresses;
      });

      setSelectedAddress(newSelectedAddress);
      setPropertyID(value.match.id);

      setPropertyIDS((prevID) => {
        const newPropertyIDS = [...prevID];
        if (index !== null && newPropertyIDS[index] !== undefined) {
          newPropertyIDS[index] = value.match.id;
        } else {
          newPropertyIDS.push(value.match.id);
        }
        return newPropertyIDS;
      });

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [index]: true,
      }));
    }
  };

  const addEstimatedValue = async (currentValues: string) => {
    setCurrentEstimatedValue((prevValues) => {
      if (isAutocompleteActive !== null) {
        if (prevValues[isAutocompleteActive] !== undefined) {
          const newValues = [...prevValues];
          newValues[isAutocompleteActive] = currentValues;
          return newValues;
        }
      }
      if (!prevValues.includes(currentValues)) {
        return [...prevValues, currentValues];
      }
      return prevValues;
    });
  };

  const addPropertyDetailedValue = (currentValue: any) => {
    setPropertyDetails((prevValues) => {
      if (
        isAutocompleteActive !== null &&
        prevValues[isAutocompleteActive] !== undefined
      ) {
        const newValues = [...prevValues];
        newValues[isAutocompleteActive] = currentValue;
        return newValues;
      }
      if (
        !prevValues.some((detail) => detail && detail.id === currentValue.id)
      ) {
        return [...prevValues, currentValue];
      }
      return prevValues;
    });
  };

  const handleChange = (
    event:
      | React.ChangeEvent<{ value: number }>
      | React.ChangeEvent<HTMLInputElement>,
    field: keyof InvestedProperty
  ) => {
    const value =
      event.target instanceof HTMLInputElement && event.target.type === "number"
        ? Number(event.target.value)
        : event.target.value;

    dispatch(
      updateProperty({ index, data: { ...propertyyData, [field]: value } })
    );
  };

  const calculateLeverageRatio = useCallback(() => {
    if (propertyyData.value && propertyyData.deposit) {
      const leverageRatio =
        ((parseFloat(propertyyData.value) * 1.06 - propertyyData.deposit) /
          parseFloat(propertyyData.value)) *
        100;

      return leverageRatio.toFixed(2);
    }
    return "";
  }, [propertyyData.value, propertyyData.deposit]);

  const borrowableEquities = calculateBorrowableEquity(properties);

  useEffect(() => {
    const leverageRatio = parseFloat(calculateLeverageRatio());
    if (!isNaN(leverageRatio)) {
      dispatch(updateProperty({ index, data: { leverageRatio } }));
    }
  }, [
    propertyyData.value,
    propertyyData.deposit,
    dispatch,
    index,
    calculateLeverageRatio,
  ]);
  const currentYear = new Date().getFullYear();
  const years = Array?.from(new Array(41), (val, index) => currentYear + index);

  const handleRemove = () => {
    dispatch(removeProperty(propertyyData.id));
  };

  const propertyName = properties.map((address) =>
    address?.address?.split(" ").slice(0, 4).join(" ")
  );

  return (
    <Formik
      initialValues={{
        address: propertyyData.address || "",
        purchaseYear: propertyyData.purchaseYear || 0,
        deposit: propertyyData.deposit || 0,
      }}
      validationSchema={InvestmentPropertiesSchema}
      onSubmit={(values, { setSubmitting }) => {}}
    >
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <Box
          padding={2}
          boxShadow={3}
          gap={2}
          borderRadius={2}
          sx={{ backgroundColor: colors.primary[400] }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "end",
              marginBottom: "0px",
            }}
          >
            <IconButton size="large" onClick={handleRemove}>
              <DeleteIcon sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Box display="flex" flexDirection="column" gap={2}>

          {propertyName[index] && (
            <Typography
              variant="h6"
              gutterBottom
              mb={0}
              sx={{ height: "56px", padding: "5px 10px", lineHeight: "1.5", overflowY: "auto" }}
            >
              {propertyName[index]}
            </Typography>
            )}

            {!propertyName[index] && (
              <TextField
                label="Property Name"
                variant="outlined"
                type="text"
                value={propertyyData.address || ""}
                // value={propertyyData.value || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, "address")
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                placeholder="Enter property name"
                sx={{
                  "& .MuiInputBase-input-focused": {
                    color: colors.grey[100],
                    borderColor: colors.grey[100],
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: colors.grey[100],
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                }}
              />
            )}
            <TextField
              label="Initial Property Value"
              variant="outlined"
              type="number"
              value={propertyyData.value || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "value")
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              placeholder="Enter property value"
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: colors.grey[100], // Adjust as needed
                  },
              }}
            />
            {propertyName[index] ? (
              <TextField
                // select
                label="Property Purchase Year"
                variant="outlined"
                value={propertyyData.purchaseYear || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, "purchaseYear")
                }
                placeholder="Select purchase year"
                sx={{
                  "& .MuiInputBase-input-focused": {
                    color: colors.grey[100],
                    borderColor: colors.grey[100],
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: colors.grey[100],
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                }}
              />
            ) : (
              <Field name={`purchaseYear`}>
                {({ field }: any) => (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Purchase Year</InputLabel>
                    <Select
                      {...field}
                      label="Purchase Year"
                      onChange={(e: any) => handleChange(e, "purchaseYear")}
                      value={propertyyData.purchaseYear || ""}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            )}
            <TextField
              label="Deposit"
              variant="outlined"
              name={"deposit"}
              // required={true}
              type="number"
              value={propertyyData.deposit || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "deposit")
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              placeholder="Enter deposit amount"
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: colors.grey[100], // Adjust as needed
                  },
              }}
            />
            {/* {depositError && !propertyyData.deposit && <span style={{color:'red'}}>{depositError}</span>} */}
            <TextField
              label="Borrowable Equity"
              variant="outlined"
              type="number"
              value={Math.round(borrowableEquities[index]) || "0"}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: colors.grey[100], // Adjust as needed
                  },
              }}
            />
            <TextField
              label="Equity Accessed"
              variant="outlined"
              type="number"
              value={propertyyData.equityAccessed || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "equityAccessed")
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              placeholder="Enter equity accessed"
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: colors.grey[100], // Adjust as needed
                  },
              }}
            />
            <TextField
              label={
                <Box display="flex" alignItems="center">
                  Leverage Ratio When Purchased
                  <Tooltip title="Leverage Ratio includes stamp duty and other expenses.">
                    <InfoIcon sx={{ ml: 1 }} />
                  </Tooltip>
                </Box>
              }
              // label="Leverage Ratio When Purchased"
              variant="outlined"
              InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={calculateLeverageRatio()}
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: colors.grey[100], // Adjust as needed
                  },
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Formik>
  );
};

export default InvestedPropertyColumn;
