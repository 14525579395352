import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const data = {
  labels: ['Debt', 'Equity'],
  datasets: [
    {
      data: [60, 40], // Replace these with your actual data
      backgroundColor: ['#FFCD56', '#36A2EB'],
      hoverBackgroundColor: ['#FFCD56', '#36A2EB'],
    },
  ],
};


const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom' as const, // Change the position to one of the allowed values
    },
    title: {
      display: true,
      text: 'Loan to Value Ratio',
    },
  },
};

const LoanToValueRatioChart = () => {
  return <Doughnut data={data} options={options} />;
};

export default LoanToValueRatioChart;
