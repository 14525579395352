import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CashFlowProjectionData,
  PropertyDetailsData,
  KeyAssumptionsData,
  AnnualHoldingCostsData,
  AcquisitionCostsData,
  FormattedData,
  CashFlowData,
  GrowthData,
} from '../types/CashFlow';
import { AustralianStatesAndTerritories } from '../types/finance';

interface CashFlowAnalysisState {
  propertyDetails: PropertyDetailsData | null;
  keyAssumptions: KeyAssumptionsData | null;
  cashFlowProjection: CashFlowProjectionData | null;
  areaChartFlows: FormattedData[];
  CashflowChart: CashFlowData[];
  GrowthChart: GrowthData[] 
}

const initialState: CashFlowAnalysisState = {
  propertyDetails: {
    state: AustralianStatesAndTerritories.QLD,
    propertyValue: 0,
    stampDuty: 0,
    loanAmount: 0,
    lvr: 0,
    interestRate: 6.9,
    loanTerm: 30,
    repaymentType: 'Principal & Interest',
    interestOnly: 0,
    deposit: 63600,
    legals: 0,
    insurance: 0,
    buildInspection: 550,
    pestInspection: 0,
    otherCosts: 0,
    estimatedWeeklyRent: 500,
    grossYield: 0,
    annualHoldingCosts: {
      propertyManagementFee: 10,
      lettingFee: 1.1,
      maintenanceCostPA: 1000,
      councilRates: 2000,
      waterRates: 800,
      insurance: 1400,
    },
    acquisitionCosts: {
      deposit: 63600,
      buildingAndPestInspection: 550,
      buyersAgentFee: 15000,
      stampDuty: 16975,
      conveyancingFees: 1500,
      depreciationSchedule: 550,
      registrationFees: 0,
    },
  },
  keyAssumptions: {
    capitalGrowthRate: 5,
    rentalGrowthRate: 3,
    inflationRate: 3,
    vacancyPeriod: 2,
  },
  cashFlowProjection: null,
  areaChartFlows: [],
  CashflowChart: [],
  GrowthChart: [],
};

const cashFlowAnalysisSlice = createSlice({
  name: 'cashFlowAnalysis',
  initialState,
  reducers: {
    updatePropertyDetails: (state, action: PayloadAction<Partial<PropertyDetailsData>>) => {
      if (state.propertyDetails) {
        
        state.propertyDetails = { ...state.propertyDetails, ...action.payload };
      }
    },
    updateKeyAssumptions: (state, action: PayloadAction<Partial<KeyAssumptionsData>>) => {
      if (state.keyAssumptions) {
        state.keyAssumptions = { ...state.keyAssumptions, ...action.payload };
      }
    },
    updateAnnualHoldingCosts: (state, action: PayloadAction<Partial<AnnualHoldingCostsData>>) => {
      if (state.propertyDetails?.annualHoldingCosts) {
        state.propertyDetails.annualHoldingCosts = {
          ...state.propertyDetails.annualHoldingCosts,
          ...action.payload,
        };
      }
    },
    updateCashflowChart: (state, action: PayloadAction<CashFlowData[]>) => {
      state.CashflowChart = action.payload;
    }
    ,
    updateGrowthChart: (state, action: PayloadAction<GrowthData[]>) => {
      state.GrowthChart = action.payload;
    },
    updateAreaChartFlow: (state, action: PayloadAction<FormattedData[]>) => {
      state.areaChartFlows = action.payload;
    },


    updateAcquisitionCosts: (state, action: PayloadAction<Partial<AcquisitionCostsData>>) => {
      if (state.propertyDetails?.acquisitionCosts) {
        state.propertyDetails.acquisitionCosts = {
          ...state.propertyDetails.acquisitionCosts,
          ...action.payload,
        };
      }
    },
    updateCashFlowProjection: (state, action: PayloadAction<CashFlowProjectionData>) => {
      
      state.cashFlowProjection = action.payload;
    },
    submitCashFlowAnalysis: (state) => {
      // Submit cash flow analysis data to the backend API
    },
  },
});

export const {
  updatePropertyDetails,
  updateKeyAssumptions,
  updateAnnualHoldingCosts,
  updateAcquisitionCosts,
  updateCashFlowProjection,
  updateAreaChartFlow,
  updateCashflowChart,
  updateGrowthChart,
  submitCashFlowAnalysis,
} = cashFlowAnalysisSlice.actions;

export default cashFlowAnalysisSlice.reducer;