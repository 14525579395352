import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const data = {
  labels: ['Current Savings', '5 Months', '10 Months', '15 Months'],
  datasets: [
    {
      label: 'Months Left',
      data: [250000, 200000, 150000, 100000, 50000, 0],
      fill: true,
      backgroundColor: 'rgba(128, 0, 128, 0.2)',
      borderColor: 'rgba(128, 0, 128, 1)',
      pointBorderColor: 'rgba(128, 0, 128, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(128, 0, 128, 1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 10,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false // Assuming you don't want a legend for a single dataset
    },
    title: {
      display: true,
      text: 'Months Left of Savings',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const MonthsLeftSavingsChart = () => {
  return <Line data={data} options={options} />;
};

export default MonthsLeftSavingsChart;
