export enum AustralianStatesAndTerritories {
    NSW = "New South Wales",
    VIC = "Victoria",
    QLD = "Queensland",
    SA = "South Australia",
    WA = "Western Australia",
    TAS = "Tasmania",
    ACT = "Australian Capital Territory",
    NT = "Northern Territory"
  }
  
 export interface LoanFunding {
    id: number;
    state: AustralianStatesAndTerritories;
    stampDuty: number;
    governmentCost: number;
    mortgagePEXAFee: number;
    landFee: number;
    miscellaneousFees: number;
    lmi: number; // Lenders Mortgage Insurance
    solicitorConveyancerFee: number;
    adjustmentFeePercentage: number; // Assuming this is the percentage value for calculation
    totalCostForTransaction: number;
    depositPaid: number;
    depositRequired: number;
    loanRequired: number;
    totalAvailable: number;
    totalLVR: number; // Loan to Value Ratio
  }
  