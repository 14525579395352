import React, { useState, useRef, useEffect } from 'react';

import { Modal, Box, Typography, TextField, Button, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface EmailTemplate {
  id: string;
  name: string;
  content: string;
}

interface EmailCompositionModalProps {
  open: boolean;
  handleClose: () => void;
  onSendEmail: (investorEmail: string, investorName: string, emailContent: string) => Promise<void>;
  investorEmail: string;
  investorName: string;
}

const EmailCompositionModal: React.FC<EmailCompositionModalProps> = ({ open, handleClose, onSendEmail, investorEmail, investorName }) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [calendlyLink, setCalendlyLink] = useState<string>('');
  const modalRef = useRef<HTMLDivElement>(null);

  const emailTemplates: EmailTemplate[] = [
    { id: 'template1', name: 'Investment Opportunity', content: `Dear ${investorName},\n\nI have identified a new investment opportunity that aligns with your portfolio goals...` },
    { id: 'template2', name: 'Portfolio Review', content: `Hello ${investorName},\n\nIt's time for your quarterly portfolio review...` },
    // Add more templates as needed
  ];

  const handleTemplateChange = (event: SelectChangeEvent<string>) => {
    const templateId = event.target.value;
    setSelectedTemplate(templateId);
    const templateContent = emailTemplates.find(template => template.id === templateId)?.content || '';
    setEditorState(EditorState.createWithContent(ContentState.createFromText(templateContent)));
  };

  const handleCalendlyLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalendlyLink(event.target.value);
  };

  const insertCalendlyLink = () => {
    if (calendlyLink) {
      const contentState = editorState.getCurrentContent();
      const newContentState = ContentState.createFromText(contentState.getPlainText() + `\n\nBook a meeting: ${calendlyLink}`);
      setEditorState(EditorState.createWithContent(newContentState));
    }
  };

  const handleSendEmail = () => {
    const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onSendEmail(investorEmail, investorName, htmlContent);
    handleClose();
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (modalRef.current) {
        const contentHeight = modalRef.current.clientHeight;
        const modalHeight = modalRef.current.scrollHeight;
        if (contentHeight < modalHeight) {
          modalRef.current.style.height = `${modalHeight}px`;
        }
      }
    });
    if (modalRef.current) {
      observer.observe(modalRef.current);
    }
    return () => {
      if (modalRef.current) {
        observer.unobserve(modalRef.current);
      }
    };
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box ref={modalRef} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4, maxHeight: '90vh', overflowY: 'auto' }}>
        {/* <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4, maxHeight: '90vh', overflowY: 'auto' }}> */}
        <Typography variant="h6" component="h2" gutterBottom>
          Compose Email
        </Typography>
        <Select
          value={selectedTemplate}
          onChange={handleTemplateChange}
          fullWidth
          sx={{ mb: 2 }}
        >
          <MenuItem value="">
            <em>Select a template</em>
          </MenuItem>
          {emailTemplates.map((template) => (
            <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
          ))}
        </Select>

        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          toolbar={{
            options: ['inline', 'list', 'textAlign', 'history'], // Only include the options you want to show
            inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] }, // Customize inline options
            list: { options: ['unordered', 'ordered'] }, // Customize list options
            textAlign: { options: ['left', 'center', 'right'] }, // Customize text alignment options
            // link: { options: ['link'] }, // Keep link options
            history: { options: ['undo', 'redo'] }, // Keep history options
            // Exclude any options you don't want by not including them in the 'options' array above
          }}
          toolbarClassName="demo-toolbar"
          placeholder="Enter your text here..."
          localization={{ locale: 'en' }}
          wrapperStyle={{ border: '1px solid #ccc', padding: '5px', minHeight: '200px' }}
        />


        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Calendly Link"
            variant="outlined"
            size="small"
            value={calendlyLink}
            onChange={handleCalendlyLinkChange}
            sx={{ flexGrow: 1, mr: 1 }}
          />
          <Button onClick={insertCalendlyLink} variant="outlined">
            Insert Link
          </Button>
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button onClick={handleSendEmail} variant="contained"
            color="secondary">
            Send Email
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailCompositionModal;
