import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Slider, Switch, Tooltip, TextField, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer } from 'recharts';

const initialProperties = [
  {
    id: 1,
    address: "23 Wilside Crescent",
    initialValue: 636000,
    initialLoan: 508800,
    passiveIncome: 35681,
    leverageRatio: 80,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2019,
    projectedValue:0
  },
  {
    id: 2,
    address: "2 Kelburn Road",
    initialValue: 606000,
    initialLoan: 454500,
    passiveIncome: 38924,
    leverageRatio: 75,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2017,
    projectedValue:0
  },
  {
    id: 3,
    address: "27 Selina Street",
    initialValue: 350000,
    initialLoan: 245000,
    passiveIncome: 17151,
    leverageRatio: 70,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2021,
    projectedValue:0
  },
  {
    id: 4,
    address: "59 Oriestone Street",
    initialValue: 406000,
    initialLoan: 263900,
    passiveIncome: 17377,
    leverageRatio: 65,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2023,
    projectedValue:0
  }
];

const PropertyDecisionTool = () => {
    
  const [properties, setProperties] = useState(initialProperties);
  const [sellDecisions, setSellDecisions] = useState({});
  const [modelledYear, setModelledYear] = useState(2024);
  const [assumptions, setAssumptions] = useState({
    cgtRate: 33,
    goalPassiveIncome: 100000,
  });

  const handleSellDecision = (id: number, decision: boolean) => {
    setSellDecisions(prev => ({ ...prev, [id]: decision }));
  };

  const handleModelledYearChange = (event: Event, value: number | number[], activeThumb: number) => {
    setModelledYear(value as number);
  };
  
  const handleAssumptionChange = (key: string, value: string) => {
    setAssumptions(prev => ({ ...prev, [key]: parseFloat(value) }));
  };

  const handlePropertyChange = (id: number, key: string, value: string) => {
    setProperties(prevProps => prevProps.map(prop => 
      prop.id === id ? { ...prop, [key]: parseFloat(value) } : prop
    ));
  };

  const calculateProjectedValue = (initialValue: number, growthRate: number, years: number) => {
    return initialValue * Math.pow(1 + growthRate / 100, years);
  };

  const calculatePassiveIncome = (projectedValue: number, netYield: number) => {
    return projectedValue * (netYield / 100);
  };

  const calculateCapitalGainsTax = (initialValue: number, projectedValue: number, effectiveTaxRate: number) => {
    const gain = projectedValue - initialValue;
    return gain * (effectiveTaxRate / 100);
  };

  useEffect(() => {
    const updatedProperties = properties.map(prop => {
      const years = modelledYear - prop.purchaseYear;
      const projectedValue = calculateProjectedValue(prop.initialValue, prop.growthRate, years);
      const passiveIncome = calculatePassiveIncome(projectedValue, prop.netYield);
      const cgt = calculateCapitalGainsTax(prop.initialValue, projectedValue, prop.effectiveTaxRate);
      return { ...prop, projectedValue, passiveIncome, cgt };
    });
    setProperties(updatedProperties);
  }, [modelledYear, properties]);

  const totalPassiveIncome = properties.reduce((sum, prop) => 
        //@ts-ignore
    sum + (sellDecisions[prop.id] ? 0 : prop.passiveIncome), 0
  );

  const goalAchieved = totalPassiveIncome > assumptions.goalPassiveIncome;

  const chartData = properties.map(prop => ({
    name: prop.address,
    "Initial Value": prop.initialValue,
    //@ts-ignore
    "Projected Value": Math.round(prop.projectedValue),
    "Passive Income": Math.round(prop.passiveIncome),
  }));
  

  return (
    <Box sx={{ padding: '20px', fontFamily: 'Arial, sans-serif', maxWidth: '1200px', margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Invest Net: Property Decision Tool
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Box sx={{ width: '60%' }}>
          <Typography variant="h6" gutterBottom>
            Modelled Year: {modelledYear}
          </Typography>
          <Slider
            value={modelledYear}
            min={2024}
            max={2040}
            step={1}
            onChange={handleModelledYearChange}
            valueLabelDisplay="auto"
          />
        </Box>
        <Box sx={{ width: '35%' }}>
          <TextField
            label="CGT Rate (%)"
            type="number"
            value={assumptions.cgtRate}
            onChange={(e) => handleAssumptionChange('cgtRate', e.target.value)}
            sx={{ marginRight: '10px' }}
          />
          <TextField
            label="Passive Income Goal"
            type="number"
            value={assumptions.goalPassiveIncome}
            onChange={(e) => handleAssumptionChange('goalPassiveIncome', e.target.value)}
          />
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Property</TableCell>
              <TableCell align="right">Initial Value</TableCell>
              <TableCell align="right">Projected Value</TableCell>
              <TableCell align="right">Loan</TableCell>
              <TableCell align="right">Passive Income</TableCell>
              <TableCell align="right">Growth Rate</TableCell>
              <TableCell align="right">Effective Tax Rate</TableCell>
              <TableCell align="right">Net Yield</TableCell>
              <TableCell align="right">Sell Decision</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {properties.map((prop) => (
                    //@ts-ignore
              <TableRow key={prop.id} sx={{ backgroundColor: sellDecisions[prop.id] ? '#ffcccb' : 'inherit' }}>
                <TableCell component="th" scope="row">{prop.address}</TableCell>
                <TableCell align="right">${prop.initialValue.toLocaleString()}</TableCell>
                {//@ts-ignore 
                <TableCell align="right">${Math.round(prop.projectedValue).toLocaleString()}</TableCell> }
                <TableCell align="right">${prop.initialLoan.toLocaleString()}</TableCell>
                <TableCell align="right">${Math.round(prop.passiveIncome).toLocaleString()}</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={prop.growthRate}
                    onChange={(e) => handlePropertyChange(prop.id, 'growthRate', e.target.value)}
                    inputProps={{ min: 0, max: 100, step: 0.1 }}
                    sx={{ width: '60px' }}
                  />%
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={prop.effectiveTaxRate}
                    onChange={(e) => handlePropertyChange(prop.id, 'effectiveTaxRate', e.target.value)}
                    inputProps={{ min: 0, max: 100, step: 0.1 }}
                    sx={{ width: '60px' }}
                  />%
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={prop.netYield}
                    onChange={(e) => handlePropertyChange(prop.id, 'netYield', e.target.value)}
                    inputProps={{ min: 0, max: 100, step: 0.1 }}
                    sx={{ width: '60px' }}
                  />%
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Toggle to decide whether to sell this property">
                    <Switch
                    // @ts-ignore
                      checked={sellDecisions[prop.id] || false}
                      onChange={(e) => handleSellDecision(prop.id, e.target.checked)}
                      color="primary"
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h5" sx={{ marginTop: '20px', color: goalAchieved ? 'green' : 'red', fontWeight: 'bold' }}>
        Total Passive Income: ${Math.round(totalPassiveIncome).toLocaleString()}
        {goalAchieved ? " - Goal Achieved!" : " - Goal Not Yet Achieved"}
      </Typography>

      <Box sx={{ height: '400px', marginTop: '20px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <ChartTooltip />
            <Legend />
            <Bar dataKey="Initial Value" fill="#8884d8" />
            <Bar dataKey="Projected Value" fill="#82ca9d" />
            <Bar dataKey="Passive Income" fill="#ffa726" /> {/* Changed to a more visible orange */}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default PropertyDecisionTool;