import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const data = {
  labels: ['New Debt @ 80%', 'Current Debt', 'Borrowable Equity'],
  datasets: [
    {
      label: 'Borrowable Equity',
      data: [1.7, -1.5, 0.2], // These values should be your actual data
      backgroundColor: ['#3cba9f', '#c45850', '#8e5ea2'],
    },
  ],
};

const options = {
  indexAxis: 'x',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Borrowable Equity',
    },
  },
};

const BorrowableEquityChart = () => {
  const options = {
    indexAxis: "x" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Borrowable Equity',
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BorrowableEquityChart;
