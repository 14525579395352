import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import AuthenticatedAPI from "../../../auth_helper";
import { ResponsiveLine } from "@nivo/line";
import {
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Slider,
} from "@mui/material";
import { RootState } from "../../../store";

interface Property {
  id: string;
  address: string;
  currentestimatedvalue: number;
  initialpurchaseprice: number;
  netrentalyield: number;
  weeklyrent: number;
  yearofpurchase: number;
  currentloanamount: number;
  compoundingAnnualGrowthRate: number;
}

interface Recommendation {
  type: string;
  description: string;
}

interface YearData {
  year: number;
  portfolio: Property[];
  recommendations: Recommendation[];
  portfolioValue: number;
  passiveIncome: number;
}

const TimelineRecommendation: React.FC = () => {
  const personalInfo = useSelector(
    (state: RootState) => state.user.personalInfo
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [timelineData, setTimelineData] = useState<YearData[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  useEffect(() => {
    getAuthenticatedUser();
  }, []);

  const getAuthenticatedUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      getTimelineRecommendation(userData.username);
    } catch (error) {
      setError("Failed to authenticate user");
      setLoading(false);
    }
  };

  const getTimelineRecommendation = async (userId: string) => {
    try {
      const response = await AuthenticatedAPI.request(
        "InvestmentAPI",
        "get",
        `/api/timeline-recommendation/${userId}`
      );

      if (
        !response ||
        !response.data ||
        !Array.isArray(response.data) ||
        response.data.length === 0
      ) {
        throw new Error("Invalid timeline recommendation data");
      }

      const timelineData: YearData[] = response.data;
      setTimelineData(timelineData);
      setSelectedYear(timelineData[0].year);
    } catch (error) {
      setError("Failed to fetch timeline recommendation");
    } finally {
      setLoading(false);
    }
  };

  const handleYearChange = (event: Event, newValue: number | number[]) => {
    setSelectedYear(newValue as number);
  };

  const getCurrentYearData = (): YearData | undefined => {
    return timelineData.find((data) => data.year === selectedYear);
  };

  const chartData = [
    {
      id: "Portfolio Value",
      data: timelineData.map((item) => ({
        x: item.year,
        y: item.portfolioValue,
      })),
    },
    {
      id: "Passive Income",
      data: timelineData.map((item) => ({
        x: item.year,
        y: item.passiveIncome,
      })),
    },
  ];

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" align="center" color="error">
        {error}
      </Typography>
    );
  }

  const currentYearData = getCurrentYearData();

  if (!currentYearData) {
    return (
      <Typography variant="h6" align="center">
        No timeline data available.
      </Typography>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Timeline Recommendation for {personalInfo.first_name || "User"}{" "}
        {personalInfo.last_name || ""}
      </Typography>

      <Slider
        value={selectedYear ?? timelineData[0].year}
        min={timelineData[0].year}
        max={timelineData[timelineData.length - 1].year}
        step={1}
        marks
        onChange={handleYearChange}
        valueLabelDisplay="auto"
        aria-labelledby="year-slider"
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Year: {currentYearData.year}</Typography>
          <Typography>
            Portfolio Value: ${currentYearData.portfolioValue.toLocaleString()}
          </Typography>
          <Typography>
            Passive Income: ${currentYearData.passiveIncome.toLocaleString()}
            /year
          </Typography>
          <Typography>
            Properties in Portfolio: {currentYearData.portfolio.length}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Recommendations</Typography>
          <List>
            {currentYearData.recommendations.map((rec, index) => (
              <ListItem key={index}>
                <ListItemText primary={rec.type} secondary={rec.description} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Property Portfolio</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell align="right">Rent (Weekly)</TableCell>
                  <TableCell align="right">Yield</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentYearData.portfolio.map((property) => (
                  <TableRow key={property.id}>
                    <TableCell component="th" scope="row">
                      {property.address}
                    </TableCell>
                    <TableCell align="right">
                      ${property.currentestimatedvalue.toLocaleString()}
                    </TableCell>
                    <TableCell align="right">${property.weeklyrent}</TableCell>
                    <TableCell align="right">
                      {(property.netrentalyield * 100).toFixed(2)}%
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">
            Portfolio Value and Passive Income Over Time
          </Typography>
          <div style={{ height: 400 }}>
            <ResponsiveLine
              data={chartData}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Year",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Value ($)",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TimelineRecommendation;
