import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  BarChartPreTaxPassiveIncome,
  BuySellPortfolio,
  InvestedProperty,
} from "../../../types/BuySell";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  IconButton,
  useTheme,
  Tooltip,
  Card,
  Switch,
  TextField,
  Slider,
  Box,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  setBarChartPreTaxPassiveIncome,
  setLineChartModelledPropertyValue,
  setPieChartLoansAfterSalesItem,
  updateBuySellPortfolio,
  updateProperty,
} from "../../../features/investedProperties";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import { tokens } from "../../../theme";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GetUserProfile } from "../../../hooks/userprofile/user";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { emit } from "process";
import { useLocation } from 'react-router-dom';

interface State extends SnackbarOrigin {
  open: boolean;
}
interface ChartData {
  id: string;
  color: string;
  data: { x: string; y: number }[];
}
interface PassiveGoal {
  passive_income: number;
  // Add other properties if needed
}
interface ProfileData {
  financialGoal: {
    financialGoal: PassiveGoal[];
  };
  // Add other properties if needed
}
interface ModelledChartData {
  loanValuesBeforeSalesObject: { [key: string]: number };
  loanValuesAfterSalesObject: { [key: string]: number };
  remainingCashFromSalesAfterCGTObject: { [key: string]: number };
  propertiesWithNoLoansObject: { [key: string]: number };
  preTaxPassiveIncomeObject: { [key: string]: number };
  [key: string]: { [key: string]: number }; // Index signature for dynamic access
}

const initialProperties = [
  {
    id: 1,
    address: "23 Wilside Crescent",
    initialValue: 636000,
    initialLoan: 508800,
    passiveIncome: 35681,
    leverageRatio: 80,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2019,
    projectedValue: 1000,
  },
  {
    id: 2,
    address: "2 Kelburn Road",
    initialValue: 606000,
    initialLoan: 454500,
    passiveIncome: 38924,
    leverageRatio: 75,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2017,
    projectedValue: 500,
  },
  {
    id: 3,
    address: "27 Selina Street",
    initialValue: 350000,
    initialLoan: 245000,
    passiveIncome: 17151,
    leverageRatio: 70,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2021,
    projectedValue: 1500,
  },
  {
    id: 4,
    address: "59 Oriestone Street",
    initialValue: 406000,
    initialLoan: 263900,
    passiveIncome: 17377,
    leverageRatio: 65,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2023,
    projectedValue: 0,
  },
];

const PropertyResultsPage: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch: AppDispatch = useDispatch();
  const [snackBarState, setSnackBarState] = useState<SnackbarOrigin & { open: boolean }>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = snackBarState;
  const properties = useSelector(
    (state: RootState) => state.investedProperties.properties
  );
  console.log("properties", properties)
  const [modelledYear, setModelledYear] = useState(2024);
  const [propertyGraph, setPropertyGraph] = useState(initialProperties);
  const [passiveGoal, setPassiveGoal] = useState<PassiveGoal | null>(null);
  const [projectedValues, setProjectedValues] = useState<number[]>([]);
  const [remainingLoanAmount, setRemainingLoanAmount] = useState<number[]>([]);
  const [propertyIDS, setPropertyIDS] = useState<string[]>([]);
  const [GoalAchieved, setGoalAchieved] = useState(false);
  console.log('projectedValues :>> ', projectedValues);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const agentId = searchParams.get('agentId') || '';
  useEffect(() => {
    if (remainingLoanAmount[0] === 0) {
      setSnackBarState(prevState => ({ ...prevState, open: true }));
    }
  }, [remainingLoanAmount]);

  const handleSnackBarClose = () => {
    setSnackBarState(prevState => ({ ...prevState, open: false }));
  };
  const handleSnackBarOpen = () => {
    setSnackBarState(prevState => ({ ...prevState, open: true }));
  };

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      sessionStorage.setItem("redirectAfterReload", "true");
      e.returnValue = ''; // Display the confirmation dialog
      return '';
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const profileData = await GetUserProfile(agentId);
      console.log(profileData,'----------------------------------------------->')
      if (profileData && profileData.financialGoal && profileData.financialGoal.financialGoal) {
        
        setPassiveGoal(profileData.financialGoal.financialGoal[0]);

        // dispatch(updateProperty(profileData.currentPortfolio?.investmentProperties))
      }
    };
    fetchData()
  }, [passiveGoal && passiveGoal.passive_income])

  useEffect(() => {
    const redirectAfterReload = sessionStorage.getItem("redirectAfterReload");
    if (redirectAfterReload === "true") {
      sessionStorage.removeItem("redirectAfterReload");
      navigate("/buy-sell", { replace: true }); // Replace with your desired page
    }
  }, []);

  const [toggleClass, setToggleClass] = useState<boolean>(false);

  const handleModelledYearChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    // setModelledYear(value as number);
    setModelledYear(value as number);
    properties.map((item, index) => {

      dispatch(updateProperty({ index: index, data: { modelledYear: value as number } }));
    })
  };
  console.log('propertyIDS :>> ', propertyIDS);
  const handleSellAndPayOffLoansChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    propertyId: number
  ) => {


    const newValue = event.target.checked;
    setPropertyIDS((prev) => {
      if (newValue) {
        // If the checkbox is checked, add the propertyId if not present
        return prev.includes(propertyId.toString())
          ? prev
          : [...prev, propertyId.toString()];
      } else {
        // If the checkbox is unchecked, remove the propertyId if present
        return prev.filter((id) => id !== propertyId.toString());
      }
    });
    setToggleClass(true); // Set to true when switch is toggled

    const updatedProperty: Partial<InvestedProperty> = {
      sellAndPayOffLoans: newValue,
    };
    // outstandingLoansInProjectionYear
    const propertyIndex = properties.findIndex(
      (property) => property.id === propertyId
    );

    if (propertyIndex !== -1) {

      dispatch(
        updateProperty({
          index: propertyIndex,
          data: { ...updatedProperty, modelledYear: modelledYear },
        })
      );
    }
  };
  const handleCompoundingAnnualGrowthRateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    propertyId: number
  ) => {
    const updatedProperty: Partial<InvestedProperty> = {
      compoundingAnnualGrowthRate: parseFloat(event.target.value),
    };
    const propertyIndex = properties.findIndex(
      (property) => property.id === propertyId
    );

    if (propertyIndex !== -1) {
      dispatch(updateProperty({ index: propertyIndex, data: updatedProperty }));
    }
  };
  console.log('projectedValues :>> ', projectedValues);

  const handleMarginalTaxRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const newTaxRate = parseFloat(event.target.value);

    properties.forEach((property, index) => {
      const updatedProperty: Partial<InvestedProperty> = {
        marginalTaxRate: newTaxRate,
      };
      dispatch(updateProperty({ index, data: updatedProperty }));
    });
  };

  const handleNetYieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    propertyId: number
  ) => {
    const updatedProperty: Partial<InvestedProperty> = {
      netYield: parseFloat(event.target.value),
    };
    const propertyIndex = properties.findIndex(
      (property) => property.id === propertyId
    );
    if (propertyIndex !== -1) {
      dispatch(updateProperty({ index: propertyIndex, data: updatedProperty }));
    }
  };

  const calculateModelledYearPropertyValue = (property: InvestedProperty) => {
    const yearsDifference = Number(properties[0]?.modelledYear === 0 ? modelledYear : properties[0]?.modelledYear) - Number(property.purchaseYear);

    const modelledYearPropertyValue =
      parseFloat(property.value) *
      Math.pow(1 + property.compoundingAnnualGrowthRate / 100, yearsDifference);
    return Math.round(modelledYearPropertyValue);
  };

  ///'Modelled year' loans before sales nee to add + quity access

  const calculateModelledYearLeverageRatio = (property: InvestedProperty) => {
    const loansBeforeSales = calculateLoansBeforeSales(property);
    const propertyValue = calculateModelledYearPropertyValue(property);

    if (propertyValue === 0) {
      return 0;
    } else {
      return (loansBeforeSales / propertyValue) * 100;
    }
  };
  const calculateLoansBeforeSales = (property: InvestedProperty) => {
    return (property.leverageRatio / 100) * parseFloat(property.value);
  };
  const calculateLoansAfterSales = (property: InvestedProperty) => {
    return property.sellAndPayOffLoans
      ? 0
      : calculateLoansBeforeSales(property);
  };
  console.log('passiveGoal :>> ', passiveGoal);

  const calculateRemainingCashFromSales = (property: InvestedProperty) => {
    const loansAfterSales = calculateLoansAfterSales(property);
    // 
    if (loansAfterSales === 0) {
      const modelledYearPropertyValue =
        calculateModelledYearPropertyValue(property);
      const loansBeforeSales = calculateLoansBeforeSales(property);

      const capitalGain =
        (modelledYearPropertyValue - parseFloat(property.value)) * 0.5; // Assuming 50% capital gain
      const capitalGainsTax = capitalGain * (property.marginalTaxRate / 100);

      return modelledYearPropertyValue - loansBeforeSales - capitalGainsTax;
    } else {
      return 0;
    }
  };

  const calculatePropertyValueWithNoLoans = (
    property: InvestedProperty,
    totalPreTaxPassiveIncome: number
  ) => {
    const loansAfterSales = properties.reduce(
      (total, property) => total + calculateLoansAfterSales(property),
      0
    );

    const remainingCashafterFromSales = properties.reduce(
      (total, property) => total + calculateRemainingCashFromSales(property),
      0
    );

    if (totalPreTaxPassiveIncome < remainingCashafterFromSales) {
      return property.sellAndPayOffLoans
        ? 0
        : calculateModelledYearPropertyValue(property);
    } else {
      return "failed";
    }
  };

  const calculatePreTaxPassiveIncome = (
    property: InvestedProperty,
    totalPreTaxPassiveIncome: number
  ) => {

    const propertyValue = calculatePropertyValueWithNoLoans(
      property,
      totalPreTaxPassiveIncome
    );
    if (propertyValue === "failed") {
      return 0;
    } else {
      return Math.round(
        Number(propertyValue) * (Number(property.netYield) / 100)
      );
    }
  };

  const isTestPassed = (): "Goal Achieved" | "Goal Not Achieved" => {
    if (!properties || properties.length === 0) {
      console.error("Properties array is empty or undefined.");
      return "Goal Not Achieved";
    }


    return GoalAchieved && GoalAchieved
      ? "Goal Achieved"
      : "Goal Not Achieved";
  };







  //   useEffect(()=>{

  //     const filteredProperties = properties.filter((property) =>
  //       propertyIDS.includes(property.id.toString())
  //     );

  //     // Calculate projected values for the filtered properties
  //     const newProjectedValues = filteredProperties.map((property) =>
  //       calculateModelledYearPropertyValue(property)
  //     );

  //     setProjectedValues((prev) => {
  //       // Identify property IDs to remove
  //       const propertyIdsToRemove = prev
  //         .map((value, index) => propertyIDS[index])
  //         .filter((id) => !filteredProperties.some((property) => property.id.toString() === id));

  //       // Create a new array without the values corresponding to the IDs to be removed
  //       const updatedProjectedValues = prev.filter((value, index) =>
  //         !propertyIdsToRemove.includes(propertyIDS[index])
  //       );

  //       // Combine the remaining projected values with the new ones, avoiding duplicates
  //       newProjectedValues.forEach((value) => {
  //         if (!updatedProjectedValues.includes(value)) {
  //           updatedProjectedValues.push(value);
  //         }
  //       });

  //       return updatedProjectedValues;
  //     });
  //     // setProjectedValues((prev) => [
  //     //   ...prev,
  //     //   Number(projectedValues),
  //     // ]);

  //     const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
  //       return accumulator + currentValue;
  //     }, 0);
  //     const res = Number(totalProjectedValue) >= Number(outstandingLoansInProjectionYear.replace(/,/g, ''))
  //    const passiveIncome =  properties.reduce(
  //       (total, property) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
  //       0
  //     )
  //     const passiveTotal = Number(passiveIncome) >= Number(passiveGoal?.passive_income)

  //     const IsGoalacheived = res && passiveTotal 
  // console.log('IsGoalacheived :>> ', IsGoalacheived);
  // setGoalAchieved(IsGoalacheived)
  //   },[propertyIDS,modelledYear])
  // useEffect(() => {

  //   if (propertyIDS.length === 0) {
  //     const initialPropertyIds = properties
  //       .filter((property) => property.sellAndPayOffLoans)
  //       .map((property) => property.id.toString());

  //     setPropertyIDS(initialPropertyIds);

  //     return;
  //     // const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
  //     //   return accumulator + currentValue;
  //     // }, 0);
  //     // 
  //   }
  //   const filteredProperties = properties.filter((property) =>
  //     propertyIDS.includes(property.id.toString())
  //   );

  //   // Calculate projected values for the filtered properties
  //   const newProjectedValues = filteredProperties.map((property) => ({
  //     id: property.id.toString(),
  //     value: calculateModelledYearPropertyValue(property)
  //   }));

  //   setProjectedValues((prev) => {
  //     // Create a map of current projected values for quick lookup
  //     const projectedValueMap = new Map(prev.map((value, index) => [propertyIDS[index], value]));

  //     // Update the map with the new projected values
  //     newProjectedValues.forEach(({ id, value }) => {
  //       projectedValueMap.set(id, value);
  //     });

  //     // Convert the map back to an array, maintaining the order of propertyIDS
  //     const updatedProjectedValues = propertyIDS.map((id) => projectedValueMap.get(id)).filter((value): value is number => value !== undefined);

  //     return updatedProjectedValues;
  //   });

  //   // Calculate total projected value
  //   const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
  //     return accumulator + currentValue;
  //   }, 0);

  //   // Check if the total projected value meets or exceeds the outstanding loans amount
  //   const res = Number(totalProjectedValue) >= Number(outstandingLoansInProjectionYear.replace(/,/g, ''));

  //   // Calculate passive income
  //   const passiveIncome = properties.reduce(
  //     (total, property) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
  //     0
  //   );

  //   // Check if the passive income meets or exceeds the passive income goal
  //   const passiveTotal = Number(passiveIncome) >= Number(passiveGoal?.passive_income);

  //   // Determine if the goal is achieved
  //   const isGoalAchieved = res && passiveTotal;
  //   console.log('IsGoalAchieved :>> ', isGoalAchieved);
  //   setGoalAchieved(isGoalAchieved);
  // }, [propertyIDS, modelledYear]);


  const cashResidue = properties
    .reduce(
      (total, property) => total + calculateRemainingCashFromSales(property),
      0
    )
    .toLocaleString();
  const handleBack = () => {
    navigate("/buy-sell");
  };

  const handleSave = () => {
  };

  const years = Array.from({ length: 21 }, (_, index) => 2020 + index);
  // pre-tax passive income
  const totalPreTaxPassiveIncome = properties.reduce(
    (total, property) =>
      total +
      Math.round(
        Number(calculatePropertyValueWithNoLoans(property, 0)) *
        (Number(property.netYield) / 100)
      ),
    0
  );
  // modelled year property value
  const modelledPropertyValues = properties
    .reduce(
      (total, property) => total + calculateModelledYearPropertyValue(property),
      0
    )
    .toLocaleString();

  //'Modelled year' pre-tax passive income
  const calculatedValues = properties
    .reduce(
      (total, property) =>
        total +
        calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
      0
    )
    .toLocaleString();

  // inital property value
  const totalPropertyValue = properties.reduce(
    (total, property) => total + parseFloat(property.value),
    0
  );
  // .toLocaleString();
  //'Modelled year' remaining cash from sales (after CGT)
  const rememainingCashFromSales = properties
    .reduce(
      (total, property) => total + calculateRemainingCashFromSales(property),
      0
    )
    .toLocaleString();

  ///Loans After Sales (Loans Before Sales + Capital Gain)
  const loansAfterSales = properties
    .reduce((total, property) => total + calculateLoansAfterSales(property), 0)
    .toLocaleString();
  console.log('loansAfterSales :>> ', loansAfterSales);
  const ModelledPropertyValuesWithNoLoans = properties
    .reduce((total, property) => {
      const value = Number(calculatePropertyValueWithNoLoans(property, 0));
      return total + (isNaN(value) ? 0 : value);
    }, 0)
    .toLocaleString();


  const outstandingLoansInProjectionYear = properties
    .reduce((total, property) => total + calculateLoansBeforeSales(property), 0)
    .toLocaleString();

  console.log('outstandingLoansInProjectionYear :>> ', outstandingLoansInProjectionYear);
  // isTestPassed
  const isPassed = isTestPassed() === "Goal Achieved";

  useEffect(() => {
    if (properties.length > 0) {

      properties.map((property) => (

        calculateModelledYearPropertyValue(property)
      ))

    }
  }, [remainingLoanAmount])



  useEffect(() => {
    if (propertyIDS.length === 0) {
      // properties.map((property: InvestedProperty) => ({

      //  calculateModelledYearPropertyValue(property)
      // }));
      const initialPropertyIds = properties
        .filter((property) => property.sellAndPayOffLoans)
        .map((property) => property.id.toString());

      setPropertyIDS(initialPropertyIds);
      return;
    }

    const filteredProperties = properties.filter((property) =>
      propertyIDS.includes(property.id.toString())
    );

    const newProjectedValues = filteredProperties.map((property) => ({
      id: property.id.toString(),
      value: calculateModelledYearPropertyValue(property)
    }));

    setProjectedValues((prev) => {
      const projectedValueMap = new Map(prev.map((value, index) => [propertyIDS[index], value]));

      newProjectedValues.forEach(({ id, value }) => {
        projectedValueMap.set(id, value);
      });

      const updatedProjectedValues = propertyIDS.map((id) => projectedValueMap.get(id)).filter((value): value is number => value !== undefined);

      return updatedProjectedValues;
    });
  }, [propertyIDS, modelledYear]);

  useEffect(() => {
    const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    const res = Number(outstandingLoansInProjectionYear.replace(/,/g, '')) - Number(totalProjectedValue)
    console.log('resammm :>> ', res);
    {
      Number(totalProjectedValue) < Number(outstandingLoansInProjectionYear.replace(/,/g, '')) ? (
        setRemainingLoanAmount([Number(outstandingLoansInProjectionYear.replace(/,/g, '')) - Number(totalProjectedValue)])
      ) : (
        setRemainingLoanAmount([0])
      )
    }
    console.log('reminingLoanAmount :>> ', remainingLoanAmount);
    const passiveIncome = properties.reduce(
      (total, property) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
      0
    );

    const passiveTotal = Number(passiveIncome) >= Number(passiveGoal?.passive_income);

    const isGoalAchieved = remainingLoanAmount[0] === 0 && passiveTotal;
    console.log('IsGoalAchieved :>> ', isGoalAchieved);
    setGoalAchieved(isGoalAchieved);
  }, [projectedValues, properties, passiveGoal, outstandingLoansInProjectionYear, remainingLoanAmount]);
  const handleNext = () => {
    const buySellPortfolioEntry: BuySellPortfolio = {
      modelledYear: properties[0]?.modelledYear,
      isTestPassed: isPassed as unknown as boolean,
      initialPropertyValue: totalPropertyValue as unknown as number,
      modelledPropertyValue: modelledPropertyValues as unknown as number,
      propertyValueNoLoans:
        ModelledPropertyValuesWithNoLoans as unknown as number,
      preTaxPassiveIncome: calculatedValues as unknown as number,
      loansAfterSales: loansAfterSales as unknown as number,
      remainingCash: rememainingCashFromSales as unknown as number,
      outstandingLoansInProjectYear:
        outstandingLoansInProjectionYear as unknown as number,
    };

    dispatch(updateBuySellPortfolio(buySellPortfolioEntry));
    navigate("/buy-sell-reporting");
  };

  const passiveIncomeArray: BarChartPreTaxPassiveIncome[] = [];

  properties.forEach((property: InvestedProperty, index: number) => {
    const passiveIncome = calculatePreTaxPassiveIncome(
      property,
      totalPreTaxPassiveIncome
    );
    const incomeColor = "hsl(76, 70%, 50%)";
    passiveIncomeArray.push({
      property: property.address.split(" ").slice(0, 2).join(" "),
      "pre-tax income": passiveIncome.toLocaleString().replace(/,/g, ""),
      "pre-tax incomecolor": incomeColor,
    });
  });

  dispatch(setBarChartPreTaxPassiveIncome(passiveIncomeArray));

  /// pie chart loans after sales

  const loansAfterSalesObject: Record<string, string> = {};
  properties.forEach((property: InvestedProperty, index: number) => {
    const loansAfterSalesValue =
      calculateLoansAfterSales(property).toLocaleString();
    loansAfterSalesObject[property.address.split(" ").slice(0, 2).join(" ")] =
      loansAfterSalesValue;
  });
  // console.log("loansAfterSalesObject:", loansAfterSalesObject);
  const loansAfterSalesArray = Object.keys(loansAfterSalesObject).map(
    (key, index) => {
      const valueWithoutSeparator = loansAfterSalesObject[key].replace(
        /,/g,
        ""
      ); // Remove thousand separator
      const value = Number(valueWithoutSeparator); // Convert to number
      const labelWithValue = `${key} ($ ${value})`; // Concatenate value to label
      return {
        id: labelWithValue,
        label: labelWithValue,
        value: value,
        color: `hsl(${104 + index * 58}, 70%, 50%)`, // Generate color based on index
      };
    }
  );

  dispatch(setPieChartLoansAfterSalesItem(loansAfterSalesArray));


  const propertiesArray = properties.map(
    (property: { value: any }) => property.value
  );
  const propertiesObject: { [key: string]: any } = {};
  propertiesArray.forEach((value, index) => {
    propertiesObject[`prop value ${index + 1}`] = value;
  });


  ///signal loan before sales// Calculate loan values before sales for each property
  const loanValuesBeforeSales = properties.map((property, index) => ({
    [`loan before sales ${index + 1}`]: calculateLoansBeforeSales(property),
  }));

  const loanValuesBeforeSalesObject = Object.assign(
    {},
    ...loanValuesBeforeSales
  );

  /// single loan after sales

  const loanaftersales = properties.map((property, index) => ({
    [`loan after sales ${index + 1}`]: calculateLoansAfterSales(property),
  }));
  const loanValuesAfterSalesObject = Object.assign({}, ...loanaftersales);

  /// signal remaining cash from sales after (CGT)

  const remainingCashFromSalesAfterCGT = properties.map((property, index) => ({
    [`remaining cash after (CGT) ${index + 1}`]:
      calculateRemainingCashFromSales(property),
  }));
  const remainingCashFromSalesAfterCGTObject = Object.assign(
    {},
    ...remainingCashFromSalesAfterCGT
  );

  /// signal property value with no loans
  const propertiesWithNoLoans = properties.map((property, index) => ({
    [`property value with no loans ${index + 1}`]:
      calculatePropertyValueWithNoLoans(property, totalPreTaxPassiveIncome),
  }));
  const propertiesWithNoLoansObject = Object.assign(
    {},
    ...propertiesWithNoLoans
  );

  /// single pre tax passive income

  const preTaxPassiveIncome = properties.map(
    (property: InvestedProperty, index: number) => ({
      [`pre tax passive income ${index + 1}`]: calculatePreTaxPassiveIncome(
        property,
        totalPreTaxPassiveIncome
      ),
    })
  );
  const preTaxPassiveIncomeObject = Object.assign({}, ...preTaxPassiveIncome);

  const modelledChartData: ModelledChartData = {
    loanValuesBeforeSalesObject,
    loanValuesAfterSalesObject,
    remainingCashFromSalesAfterCGTObject,
    propertiesWithNoLoansObject,
    preTaxPassiveIncomeObject,
  };

  /// line chart modelled year property value
  const transformData = (): ChartData[] => {
    const transformedData: ChartData[] = [];
    const categories = Object.keys(modelledChartData);

    // Define a mapping of category IDs to colors
    const colorMapping: { [key: string]: string } = {
      loanValuesBeforeSalesObject: "darkgreen",
      loanValuesAfterSalesObject: "darkblue",
      remainingCashFromSalesAfterCGTObject: "darkgreen",
      propertiesWithNoLoansObject: "darkorange",
      preTaxPassiveIncomeObject: "purple",
    };

    categories.forEach((category) => {
      const color = colorMapping[category] || "black"; // Default to black if no color is specified for the category
      const data = Object.keys(modelledChartData[category]).map(
        (key, index) => ({
          x: properties[index].address.split(" ").slice(0, 2).join(" "), // Extract the property number from the key
          // x: `property_${parseInt(key.replace(/[^\d]/g, ""))}`, // Extract the property number from the key
          y: modelledChartData[category][key],
        })
      );

      transformedData.push({
        id: category
          .replace(/Object/g, "")
          .replace(/([A-Z])/g, " $1")
          .trim()
          .toLowerCase(),
        color: color,
        data: data,
      });
    });

    return transformedData;
  };

  const transformedData = transformData();

  dispatch(setLineChartModelledPropertyValue(transformedData));

  const testResult = isTestPassed(); // Get the test result
  // console.log(testResult, "222222222222222222")
  // Determine the CSS class based on the test result
  const textClass =
    testResult === "Goal Achieved"
      ? "gradient-text mr-1"
      : "notachivegradient-text";

  const chartData = propertyGraph.map((prop) => ({
    name: prop.address,
    "Initial Value": prop.initialValue,
    //@ts-ignore
    "Projected Value": Math.round(prop.projectedValue),
    "Passive Income": Math.round(prop.passiveIncome),
  }));




  const computeProjectedValue = (property: any) => {

    return calculateModelledYearPropertyValue(property);
  };

  const computePassiveIncome = (property: any) => {

    return calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome)
  };


  const barData = properties.map(property => ({
    name: property.address?.split(" ").slice(0, 3).join(" "),
    'Initial Value': Number(property.value),
    'Projected Value': computeProjectedValue(property),
    'Passive Income': computePassiveIncome(property)
  }));



  useEffect(() => {

  }, [toggleClass])
  useEffect(() => {
    setRemainingLoanAmount([Number(outstandingLoansInProjectionYear)])
  }, [])

  // const passiveIncome = properties.reduce((total: any, property: any) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome))
  // const goalAchieved = Number() > Number(passiveGoal) && Number(passiveGoal);


  // const buttons = (
  //   <React.Fragment>
  //     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
  //       <Button onClick={handleClick({ vertical: 'top', horizontal: 'center' })}>
  //         Top-Center
  //       </Button>

  //   </React.Fragment>
  // );
  return (
    <>
      <div>
        <Grid container spacing={3} columns={12} className="p-6">

          {/* {remainingLoanAmount[0] === 0 && ( */}

          {/* )} */}
          {/* {remainingLoanAmount[0] === 0 &&
            <Box sx={{ width: 500, vertical: 'top', horizontal: 'center' }}>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remainingLoanAmount[0] === 0 && open}
                // onClose={handleClose}
                message="I love snacks"
                key={vertical + horizontal}
              />
            </Box>
          } */}

          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >

              {/* {GoalAchieved && (
              <Box sx={{ backgroundColor: "#4cceac" }}>
                <Snackbar
                  anchorOrigin={{ vertical: snackBarState.vertical, horizontal: snackBarState.horizontal }}
                  open={snackBarState.open}
                  autoHideDuration={2000} 
                  onClose={handleSnackBarClose}
                  // onExited={handleSnackBarClose}
                  message="No outstanding loans 🏆" 
                  key={`${snackBarState.vertical}${snackBarState.horizontal}`}
                />
              </Box>
            )} */}


              {/* <IconButton onClick={handleBack} style={{ marginRight: "8px" }}>
                <ArrowBackIcon />
              </IconButton> */}

              <Button onClick={handleBack} color="secondary">
                <ArrowBackIcon sx={{ marginRight: "5px" }} /> Go Back
              </Button>
              <Typography variant="h4" gutterBottom>
                Invest Net : Reporting
              </Typography>
              <Typography
                variant="h4"
                align="right"
                className={textClass}
                style={{
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
              >
                {testResult}
              </Typography>
            </div>
          </Grid>
          <Grid item md={12} lg={8}>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                marginRight: "8px",
                color: colors.grey[100],
                fontWeight: "bold",
                marginLeft: 2,
              }}
            >
              Modelled Year - {properties[0]?.modelledYear === 0 ? modelledYear : properties[0]?.modelledYear}
            </Typography>

            <Slider
              value={properties[0]?.modelledYear === 0 ? modelledYear : properties[0]?.modelledYear}
              min={2024}
              max={2040}
              step={1}
              valueLabelDisplay="auto"
              onChange={handleModelledYearChange}
              aria-labelledby="vacancy-period-slider"

              marks

              sx={{ color: colors.greenAccent[500], width: '50%' }}
            />
          </Grid>
          <Grid item md={12} lg={4} style={{ display: "flex", gap: "1rem" }}>
            <TextField
              label="Passive Income Goal"
              type="text"
              InputProps={{
                startAdornment: '$',
              }}

              value={(passiveGoal && Number(passiveGoal.passive_income)?.toLocaleString()) || ''}

            />
            <>

              <TextField
                label="Effective tax (if sold)"
                type="text"
                value={properties[0]?.marginalTaxRate || ""}
                InputProps={{
                  endAdornment: '%',
                }}
                // onChange={(event) =>
                //   handleMarginalTaxRateChange(event)
                // }
                onChange={handleMarginalTaxRateChange}
              />

            </>
            {/* {testResult === "Goal Achieved" && */}
            <TextField
              label="Total Projected Value"
              type="text"

              InputProps={{
                startAdornment: '$',
              }}
              // value={testResult === "Goal Achieved" ? modelledPropertyValues : 0}
              value={testResult === "Goal Achieved" ? ModelledPropertyValuesWithNoLoans : 0}
            />

            <TextField
              label="Outstanding Loan Amount"
              type="text"
              className="gradient-border"



              InputProps={{
                startAdornment: '$',
              }}
              // value={testResult === "Goal Achieved" ? modelledPropertyValues : 0}
              value={remainingLoanAmount[0]?.toLocaleString() ?? 0}
            />
            {/* } */}


          </Grid>

          <Grid item md={12}>
            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                backgroundColor: colors.primary[400],
              }}
            >
              <TableContainer
                className="table_styleTwo"
                component={Paper}
                sx={{
                  mx: "auto",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: colors.primary[400],
                  alignItems: "center",
                  justifyContent: "center",
                  height: " 100%",
                  border: "0px",
                  boxShadow: "none",
                }}
              >
                <Table sx={{ minWidth: 700 }} aria-label="results table">
                  <TableHead style={{ padding: "8px" }}>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell style={{ fontWeight: "bold", fontSize: "14px" }}>
                        Property
                      </TableCell>
                      {[
                        // "Initial Asset Valuation",
                        "Initial Value", "Projected Value", "Loan", "Growth Rate",
                        // "Effective Tax Rate",
                        "Net Yield",
                        // "Liquidation Strategy - Y/N", 
                        "Passive Income",
                        "Sell Decision"

                      ].map((header, index) => (
                        <TableCell key={index} style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ backgroundColor: colors.primary[400], padding: "8px" }}>
                    {properties && properties.map((property, propertyIndex) => {

                      return (


                        <TableRow
                          key={propertyIndex}
                          className={property.sellAndPayOffLoans ? 'chnagebackgound' : ''}
                        // sx={rowStyles}
                        >
                          <TableCell style={{ fontSize: "14px" }}>
                            {property.address?.split(" ").slice(0, 3).join(" ")}
                          </TableCell>
                          <TableCell sx={{ fontSize: "14px" }}>
                            $ {property.value.toLocaleString()}
                          </TableCell>


                          <TableCell sx={{ fontSize: "14px" }}>
                            $ {calculateModelledYearPropertyValue(property).toLocaleString()}
                          </TableCell>

                          <TableCell sx={{ fontSize: "14px" }}>
                            $ {calculateLoansBeforeSales(property).toLocaleString()}
                          </TableCell>
                          <TableCell sx={{ fontSize: "14px" }}>
                            <input
                              type="text"
                              min="0"
                              className="bg-transparent border border-gray-500 text-center rounded p-1 gray-800 w-12 h-9"
                              max="100"
                              step="0.01"
                              value={property.compoundingAnnualGrowthRate || ""}
                              onChange={(event) =>
                                handleCompoundingAnnualGrowthRateChange(event, property.id)
                              }
                            />
                            <span> %</span>
                          </TableCell>


                          <TableCell sx={{ fontSize: "14px" }}>
                            <input
                              type="number"
                              min="0"
                              max="100"
                              className="bg-transparent border border-gray-500 text-center rounded p-1 gray-800 w-12 h-9"
                              step="0.01"
                              value={property.netYield}
                              onChange={(event) =>
                                handleNetYieldChange(event, property.id)
                              }
                            />
                            <span> %</span>
                          </TableCell>
                          <TableCell sx={{ fontSize: "14px" }}>
                            $ {calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome).toLocaleString()}
                          </TableCell>

                          <TableCell sx={{
                            fontSize: "14px",

                          }}>
                            <Tooltip title="Toggle to decide whether to sell this property">
                              <Switch
                                color="primary"
                                checked={property.sellAndPayOffLoans}
                                onChange={(event) => handleSellAndPayOffLoansChange(event, property.id)}


                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          <Grid item md={12}>
            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                backgroundColor: colors.primary[400],
              }}
            >
              {/* <Typography variant="h5" sx={{ marginTop: '20px', color: goalAchieved ? 'green' : 'red', fontWeight: 'bold' }}>  */}
              <Typography
                variant="h4"
                align="left"
                className={textClass}
                style={{
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
              >
                {properties.reduce(
                  (total, property) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
                  0
                ).toLocaleString() !== "0" ? (
                  <>
                    Total Passive Income: ${properties.reduce(
                      (total, property) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
                      0
                    ).toLocaleString()} - {testResult}
                  </>
                ) : (
                  <>
                    {testResult}
                  </>
                )}

              </Typography>
              {/* <Typography
                variant="h4"
                align="left"
                className={textClass}
                style={{
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
              >
                {testResult}
              </Typography> */}

              <Box sx={{ height: "400px", marginTop: "20px" }}>

                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={barData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <ChartTooltip />
                    <Legend />
                    <Bar dataKey="Initial Value" fill="#8884d8" />
                    <Bar dataKey="Projected Value" fill="#82ca9d" />
                    <Bar dataKey="Passive Income" fill="#ffa726" />{" "}

                  </BarChart>
                </ResponsiveContainer>

              </Box>
            </Card>
          </Grid>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "36px",
              }}
            >
              {/* <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}
              >
                <BookmarkAddedOutlinedIcon sx={{ mr: 1 }} /> Save
              </Button> */}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
              >
                Next
                <ArrowForwardIcon sx={{ ml: 2 }} />
              </Button>
            </div>
          </Grid>
        </Grid>
      </div >
    </>
  );
};
export default PropertyResultsPage;