// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   Grid,
//   Typography,
//   Button,
//   Box,
//   IconButton,
//   useTheme,
// } from "@mui/material";
// import InvestedPropertyColumn from "../../components/investedProperty";
// import { useSelector, useDispatch } from "react-redux";
// import { RootState, AppDispatch } from "../../../store";
// import { addProperty, updateProperty } from "../../../features/investedProperties";
// import AddIcon from "@mui/icons-material/Add";
// import { tokens } from "../../../theme";
// import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import { GetUserProfile } from "../../../hooks/userprofile/user";
// import { CurrentPortfolioInfo, CurrentPortFolio } from "../../../types/User";

// const BuyAndSellPage: React.FC = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const navigate = useNavigate();
//   const dispatch: AppDispatch = useDispatch();
//   const [investmentProperties, setInvestmentProperties] = useState<CurrentPortFolio[]>([]);

//   const properties = useSelector((state: RootState) => state.investedProperties.properties);

//   const handleSave = () => {
//     console.log("Save to Redux and backend");
//     // Dispatch save action here
//     // navigate('/results');
//   };

//   const handleNext = () => {
//     console.log("Go to next page");
//     navigate('/buy-sell-modelling');
//   };

//   const handleAddProperty = () => {
//     const newProperty: CurrentPortFolio = {
//       id: null,
//       address: "",
//       yearofpurchase: 0,
//       currentestimatedvalue: "",
//       initialpurchaseprice: 0,
//       netrentalyield: 0,
//       weeklyrent: 0,
//       currentloanamount: 0,
//       propertydetails: [],
//       propertyid: "",
//     };
//     setInvestmentProperties([...investmentProperties, newProperty]);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       const profileData = await GetUserProfile();
//       if (profileData && profileData.currentPortfolio) {
//         setInvestmentProperties(profileData.currentPortfolio?.investmentProperties);
//         // dispatch(updateProperty(profileData.currentPortfolio?.investmentProperties))
//       }
//     };

//     fetchData();
//   }, []);
//   const handleRemoveProperty = (index: number) => {
//     setInvestmentProperties((prevProperties) =>
//       prevProperties.filter((_, i) => i !== index)
//     );
//   };

//   return (
//     <Container>
//       <Box sx={{ textAlign: "center", mt: 4, mb: 2 }}>
//         <Typography
//           variant="h4"
//           component="h1"
//           gutterBottom
//           sx={{ color: colors.grey[100], py: 2 }}
//         >
//           Invest Net: User Input
//         </Typography>
//         <Typography
//           variant="subtitle1"
//           sx={{ color: colors.grey[300], mb: 3 }}
//         >
//           Enter your property details below. You can add up to 20 properties for analysis.
//         </Typography>
//       </Box>
//       <Grid container spacing={4} justifyContent="center">
//         {investmentProperties.map((property, index) => (
//           <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
//             <InvestedPropertyColumn
//               key={property.id}
//               index={index}

//               property={property}
//               handleRemove={handleRemoveProperty}
//             />
//           </Grid>
//         ))}
//         {investmentProperties && investmentProperties.length < 20 && (
//           <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
//             <Box
//               padding={2}
//               boxShadow={3}
//               bgcolor="background.paper"
//               borderRadius={2}
//               display="flex"
//               alignItems="center"
//               justifyContent="center"
//               height="100%"
//               sx={{ backgroundColor: colors.primary[400] }}
//             >
//               <IconButton onClick={handleAddProperty} size="large">
//                 <AddIcon fontSize="large" />
//               </IconButton>
//             </Box>
//           </Grid>
//         )}
//       </Grid>
//       <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4, color: colors.grey[100] }}>
//         <Button variant="contained" color="secondary" onClick={handleSave}>
//           <BookmarkAddedOutlinedIcon sx={{ mr: 1 }} /> Save
//         </Button>
//         <Button variant="contained" color="secondary" onClick={handleNext}>
//           Next <ArrowForwardIcon sx={{ ml: 1 }} />
//         </Button>
//       </Box>
//     </Container>
//   );
// };

// export default BuyAndSellPage;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  useTheme,
  CircularProgress,
} from "@mui/material";
import InvestedPropertyColumn from "../../components/investedProperty";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../store";
import {
  addProperty,
  updateProperty,
} from "../../../features/investedProperties";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../../theme";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CurrentPortFolio } from "../../../types/User";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { GetUserProfile } from "../../../hooks/userprofile/user";
import { profile } from "console";
import AuthenticatedAPI from "../../../auth_helper";
const InvestmentPropertiesSchema = yup.object({
  investmentProperties: yup.array().of(
    yup.object({
      // purchaseYear: yup.number().required("Purchase year is required").min(1900, "Invalid year").max(new Date().getFullYear(), "Invalid year"),
      deposit: yup
        .number()
        .required("Deposit is required")
        .min(0, "Invalid deposit amount"),
      // purchaseYear: yup.number().required("Purchase is required").min(0, "Invalid deposit amount"),
    })
  ),
});
interface BuyAndSellPageProps {
  buyerAgent: any;
}

const BuyAndSellPage: React.FC<BuyAndSellPageProps> = ({ buyerAgent }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [investmentProperties, setInvestmentProperties] = useState<
    CurrentPortFolio[]
  >([]);
  const properties = useSelector(
    (state: RootState) => state.investedProperties.properties
  );
  // const[depositError, setDepositError]=useState('')
  const [depositError, setDepositError] = useState("");
  const handleSave = () => {
    // Dispatch save action here
    // navigate('/results');
  };

  const handleNext = async () => {
    // const errors = await validateForm();

    // if (Object.keys(errors && errors).length === 0) {
    //   console.log("Go to next page");
    if(buyerAgent){
      navigate(`/buy-sell-modelling?agentId=${buyerAgent.investor_id}`);
    }else{
      navigate("/buy-sell-modelling");
    }
   
    // } else {

    //   console.log("Validation errors:", errors.investmentProperties?.[0]?.deposit);
    //   setDepositError(errors.investmentProperties?.[0]?.deposit)
    // }
  };

  const handleAddProperty = () => {
    dispatch(addProperty());
  };

  //  const GetUserProfile = async () => {
  //   try {

  //     const response = await AuthenticatedAPI.request(
  //       'InvestmentAPI',
  //       'get',
  //       `/api/profile/${currentUser.username}`
  //     );

  //     if (!response) {
  //       throw new Error('Failed to fetch user profile');
  //     }

  //     return {
  //       ...response.data,
  //       logo_url: response.data.logo_url || null,
  //       theme_color: response.data.theme_color || null
  //     };

  //   } catch (error) {
  //     console.error('Error fetching user profile:', error);
  //     return null; // Or some default/fallback value
  //   }
  // };
  useEffect(() => {
    const fetchData = async () => {
      const profileData = await GetUserProfile(buyerAgent.investor_id);
      if (profileData && profileData.currentPortfolio) {
        setInvestmentProperties(
          profileData.currentPortfolio?.investmentProperties
        );
        // dispatch(updateProperty(profileData.currentPortfolio?.investmentProperties))
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (investmentProperties.length > 0 && properties.length == 0) {
      investmentProperties.forEach((property, index) => {
        dispatch(
          updateProperty({
            index,
            data: {
              id: Number(property.id),
              address: property.address,
              value: property.initialpurchaseprice.toLocaleString(),
              purchaseYear: property.yearofpurchase,
              netYield: 4,
              compoundingAnnualGrowthRate: 7,
              marginalTaxRate: 45,
              loanBeforeSales: 0,
              sellAndPayOffLoans: false,
              leverageRatio: 0,
              deposit: 0,
              equityAccessed: 0,
              modelledYear: 0,
            },
          })
        );
      });
    }
  }, [investmentProperties, dispatch]);

  return (
    <Formik
      initialValues={{ investmentProperties: properties }}
      validationSchema={InvestmentPropertiesSchema}
      onSubmit={(values) => console.log(values)}
    >
      {({ validateForm }) => (
        <Container>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            textAlign="center"
            marginTop={4}
            sx={{ color: colors.grey[100], py: 2 }}
          >
            Investnet Scenario
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {Array.isArray(properties) &&
                  properties
                    // .slice(0, 3)
                    .map((property) => (
                      <InvestedPropertyColumn
                        key={property.id}
                        index={properties.indexOf(property)}
                        properties={investmentProperties}
                      />
                    ))}
                {Array.isArray(properties) && properties.length < 20 && (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <Box
                      padding={2}
                      boxShadow={3}
                      bgcolor="background.paper"
                      borderRadius={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                      sx={{ backgroundColor: colors.primary[400] }}
                    >
                      <IconButton onClick={handleAddProperty} size="large">
                        <AddIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mt: 4,
              color: colors.grey[100],
            }}
          >
            {/* <Button variant="contained"  color="secondary"onClick={handleSave} 
      >
        <BookmarkAddedOutlinedIcon sx={{ mr: 1 }} />  Save
        </Button> */}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleNext()}
            >
              Next <ArrowForwardIcon sx={{ ml: 1 }} />
            </Button>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default BuyAndSellPage;
