import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useTheme, Box, Typography } from '@mui/material';
import { formatNumber } from '../../../../hooks/properties/properties';

interface BorrowableEquityProps {
  borrowableEquities: number[];
  propertyname: string[];
}

const BorrowableEquity: React.FC<BorrowableEquityProps> = ({ borrowableEquities, propertyname }) => {
  const theme = useTheme();

  // const barColors = ['#e8c1a0', '#858acf', '#ba9397', '#97e3d5'];

  // const barColors = [
  //   theme.palette.mode === 'dark' ? '#24E0DC' : '#24E0DC',
  //   theme.palette.mode === 'dark' ? '#FFB22C' : '#FFB22C',
  //   theme.palette.mode === 'dark' ? '#DC0083' : '#DC0083',
  //   theme.palette.mode === 'dark' ? '#3FA2F6' : '#3FA2F6',
  //   theme.palette.mode === 'dark' ? '#C738BD' : '#C738BD',
  //   theme.palette.mode === 'dark' ? '#9BEC00' : '#9BEC00',
  // ];
  const barColors = [
    theme.palette.mode === 'dark' ? '#BB86FC' : '#BB86FC',
    theme.palette.mode === 'dark' ? '#9BEC00' : '#9BEC00',
    theme.palette.mode === 'dark' ? '#3700B3' : '#3700B3',
    theme.palette.mode === 'dark' ? '#3700B3' : '#3FA2F6',
    theme.palette.mode === 'dark' ? '#C738BD' : '#C738BD',
    theme.palette.mode === 'dark' ? '#9BEC00' : '#9BEC00',
  ];

  const [series, setSeries] = useState([{ data: [] as number[] }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (borrowableEquities.length > 0) {
      const borrowdata = borrowableEquities.map(borrowableEquitiesValue => parseFloat(borrowableEquitiesValue.toFixed(0)));
      setSeries([{ data: borrowdata }]);
      setLoading(false);
    
    }else if(borrowableEquities&&borrowableEquities.length==0){
      setLoading(false);
    }
  }, [borrowableEquities]);


  const options: ApexOptions = {
    chart: {
      height: 280,
      type: 'bar',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false // Disable zooming
      },
      selection: {
        enabled: false // Disable selection
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    colors: barColors,
    plotOptions: {
      bar: {
        columnWidth: '40px',
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: 'top', // Positioning data labels at the top
        },
      }
    },
    labels: propertyname.map((property, index) => `${property}`),
    dataLabels: {
      enabled: true,
      // formatter: (value: number, { seriesIndex, dataPointIndex, w }) => {
      //   // Access the property names and format the label
      //   const propertyName = propertyname[dataPointIndex];
      //   return `${propertyName}`;
      // },
      formatter: (value: number) => {
        return `${value.toFixed(0)}`; // Show the bar value instead of property name
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: barColors,
      }
    },
    legend: {
      show: true,
      customLegendItems: propertyname,
      markers: {
        fillColors: barColors
      },
      labels: {
        colors: theme.palette.mode === 'dark' ? '#FFFFFF' : '#333333',
      },
    },

    xaxis: {
      // categories: propertyname,
      labels: {
        show: false,
        style: {
          colors: barColors,
          fontSize: '14px'
        }
      },
      title: {
        text: 'Properties',
        style: {
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333',
          fontSize: '12px',
          // fontWeight: 'bold'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (value: number) => formatNumber(value),
        style: {
          // fontSize: '14px',
          colors: theme.palette.mode === 'dark' ? '#FFFFFF' : '#333333',
        }
      },
      title: {
        text: 'Borrowable equity in AUD',
        // rotate: -90,
        style: {
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333',
          fontSize: '12px',
          // fontWeight: 'bold'
        }
      }
    },
    grid: {
      show: false, // Hide the grid lines
    },
    fill: {

    },
    tooltip: {
      enabled: true,
      theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
      style: {
        fontSize: '12px',
      },
      x: {
        show: true,
      },
      y: {
        // formatter: (val) => formatNumber(val),
        title: {
          formatter: () => '',
        },
      },
      marker: {
        show: true,
      },

    }
  };

  return (
    <Box
      id="chart-borrowable-equity"
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#ffffff',
        padding: '0px',
        borderRadius: '10px',
        display: loading ? 'flex' : 'block',
        justifyContent: loading ? 'center' : 'initial',
        alignItems: loading ? 'center' : 'initial',
        // height: loading ? '260px' : 'auto'
        height: "300px"
      }}
    >
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : borrowableEquities&& borrowableEquities.length==0 ? (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
        textAlign="center"
      >
        <Typography variant="h6">No Data Found!</Typography>
      </Box>):(
        <ReactApexChart options={options} series={series} type="bar" height={300}
          width="100%" />
      )}
    </Box>
  );
};

export default BorrowableEquity;

