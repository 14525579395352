import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { mockAreaData } from '../data/mockdata';
import { useSelector } from "react-redux";
const AreaGrowthChart = () => {
  const areaChartFlow = useSelector((state) => state.cashFlowAnalysis.GrowthChart);


  return (
    <ResponsiveContainer width="100%" height="100%">
    <AreaChart
      width={200}
      height={300}
      data={areaChartFlow}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="year" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="equity" stackId="1" stroke="#8884d8" fill="#8884d8" />
      
    </AreaChart>
  </ResponsiveContainer>
  )
}

export default AreaGrowthChart