import React from 'react'
import { ResponsivePie } from "@nivo/pie";
import { mockPieData as data } from '../data/mockdata';
import { useTheme } from '@mui/material';
import { tokens } from '../../../../theme';
import { useSelector } from 'react-redux';
const PieChart = ({ isDashboard = false }) => {
  const PieChartLoansAfterSalesItem = useSelector((state) => state.investedProperties.pieChartLoansAfterSalesItem);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <ResponsivePie
      data={PieChartLoansAfterSalesItem}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {

          text: {
            fill: colors.grey[100],

          },

        },
      }}
      margin={{ top: 50, right: 130, bottom: 80 }}


      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}


      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={7}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      // arcLinkLabelsOffset = {-15}
      arcLinkLabelsDiagonalLength={10}
      legends={[
        {
          anchor: "bottom",
          direction: "column",
          justify: false,
          translateX: 260,
          scrollX: 0,
          translateY: 75,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 10,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
      
        },
      ]}
    />
  );
};

export default PieChart;
