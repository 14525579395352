import { useState } from 'react';

const useSubmitWithStepProgression = () => {
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [submissionCompleted, setSubmissionCompleted] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleSubmitWithProgression = async (formdata: any, handleSubmit: (data: any) => Promise<void>) => {
    
    setSubmissionInProgress(true);
    setSubmissionCompleted(false);
    setError(null);
    try {

      await handleSubmit(formdata);
      setSubmissionCompleted(true);
    } catch (err) {
      setError(err as Error);
    } finally {
      setSubmissionInProgress(false);
    }
  };

  return {
    handleSubmitWithProgression,
    submissionInProgress,
    submissionCompleted,
    error,
  };
};

export default useSubmitWithStepProgression;
