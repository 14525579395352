import React, { useEffect, useState } from 'react';
import { useTheme, Box, Button, Card, Grid, Typography, Container, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import AuthenticatedAPI from '../../../auth_helper';
import { tokens } from "../../../theme";

interface Advisor {
  id: string;
  advisor_type: string;
}

const items: { title: string; slug: string }[] = [
  { title: 'Financial Advisor', slug: 'financial-advisor' },
  { title: 'Solicitor', slug: 'solicitor' },
  { title: 'Building Inspector', slug: 'building-inspector' },
  { title: 'Pest Inspector', slug: 'pest-inspector' },
  { title: 'Property Manager', slug: 'property-manager' },
  { title: 'Quantity Surveyor', slug: 'quantity-surveyor' },
  { title: 'Insurance Consultant', slug: 'insurance-consultant' },
  { title: 'Real Estate Agent', slug: 'real-estate-agent' },
  { title: 'Conveyancer', slug: 'conveyancer' },
];

const Index: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [advisorsData, setAdvisorsData] = useState<Advisor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchAdvisors();
  }, []);

  const fetchAdvisors = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.username; // Assuming `username` is the correct property for user ID
      const response = await AuthenticatedAPI.request('AdvisorsAPI', 'get', `/advisors/${userId}`);
      setAdvisorsData(response);
      if (!response) {
        throw new Error('Failed to fetch advisors');
      }
    } catch (error) {
      console.error('Error fetching advisors:', error);
    } finally {
      setLoading(false);
    }
  };

  // Filter items based on the advisorsData
  const notYetSelected = items.filter(item =>
    !advisorsData.some(advisor => advisor?.advisor_type?.toLowerCase() === item?.title?.toLowerCase())
  );
  const workingAdvisors = advisorsData.filter(advisor =>
    items.some(item => item?.title?.toLowerCase() === advisor?.advisor_type?.toLowerCase())
  );


  return (
    <div className="p-6">
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <div>
          <Grid container spacing={3} columns={12} mb={1}>
            <Grid item md={12}>
              <Typography variant="h2" gutterBottom sx={{ fontSize: "30px", fontWeight: "400", color: colors.grey[100] }}>
                Advisors
              </Typography>
            </Grid>
          </Grid>

          <Grid item md={12} mb={3} >
            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                backgroundColor: colors.primary[400],
              }}
            >
              <Typography variant="h4" align="left" pb={2} zIndex={1} gutterBottom
                sx={{
                  fontWeight: "700",
                }}
              >
                Not yet Selected
              </Typography>

              <Grid container spacing={4}>
                {notYetSelected.map((x, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Card
                      className="flex justify-between items-center"
                      sx={{
                        backgroundColor: theme.palette.mode === 'dark' ? "#0c0c28" : "#d8d8dc",
                        padding: "20px !important",
                        marginBottom: "0px !important",
                        borderRadius: "10px !important",
                        boxShadow: "none !important",
                      }}
                    >
                      <Typography variant="h4" component="p">
                        {x.title}
                      </Typography>
                      <Button
                        variant="contained"
                        component={Link}
                        to={`/advisor/create/${encodeURIComponent(x.slug)}`} // Dynamic link to advisor create form
                        sx={{
                          color: theme.palette.mode === 'dark' ? '#5bc0be !important' : '#ffffff !important',
                          backgroundColor: theme.palette.mode === 'dark' ? '#345060 !important' : '#4cceac !important',
                        }}
                      >
                        Add
                      </Button>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>

          {workingAdvisors.length ? <Grid item md={12}>
            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "0px !important",
                borderRadius: "15px !important",
                boxShadow: "none !important",
                backgroundColor: colors.primary[400],
              }}
            >
              <Typography variant="h4" align="left" pb={2} zIndex={1} gutterBottom
                sx={{
                  fontWeight: "700",
                }}
              >
                Working Advisors
              </Typography>

              <Grid container spacing={4}>
                {workingAdvisors.map((advisor, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Card
                      className="flex justify-between items-center"
                      sx={{
                        backgroundColor: theme.palette.mode === 'dark' ? "#0c0c28" : "#d8d8dc",
                        padding: "20px !important",
                        marginBottom: "0px !important",
                        borderRadius: "10px !important",
                        boxShadow: "none !important",
                      }}
                    >
                      <Typography variant="h4" component="p">
                        {items.find(item => item.title.toLowerCase() === advisor.advisor_type.toLowerCase())?.title || 'Unknown Advisor'}
                      </Typography>
                      <Button
                        variant="contained"
                        component={Link}
                        to={`/advisor/edit/${advisor.id}`} // Dynamic link to advisor edit form
                        sx={{
                          color: theme.palette.mode === 'dark' ? '#5bc0be !important' : '#ffffff !important',
                          backgroundColor: theme.palette.mode === 'dark' ? '#345060 !important' : '#4cceac !important',
                        }}
                      >
                        Edit
                      </Button>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid> : ''}
        </div>
      )}
    </div>
  );
}

export default Index;
