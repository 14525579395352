import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';

const PrivateRoute = ({ user }: any) => {
  if (!user) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

const customAuthenticatorConfig = {
  hideSignUp: true,
  formFields: {
    setupTOTP: {
      QR: {
        totpIssuer: 'investar.io',
        totpAppName: 'investar.io',
      }
    }
  }
};

export default withAuthenticator(PrivateRoute, customAuthenticatorConfig);