import { useTheme, Box, Typography } from "@mui/material";
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface Props {
  investmentProperties: { currentestimatedvalue: any }[],
  propertyname: string[]
}

const PortfolioWealth: React.FC<Props> = ({ investmentProperties, propertyname }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // Extracting estimated values from investmentProperties
  const seriesData = investmentProperties.map(property => parseFloat(property.currentestimatedvalue) || 0);
  const barColors = [
    theme.palette.mode === 'dark' ? '#BB86FC' : '#BB86FC',
    theme.palette.mode === 'dark' ? '#9BEC00' : '#9BEC00',
    theme.palette.mode === 'dark' ? '#3700B3' : '#3700B3',
    theme.palette.mode === 'dark' ? '#3700B3' : '#3FA2F6',
    theme.palette.mode === 'dark' ? '#C738BD' : '#C738BD',
    theme.palette.mode === 'dark' ? '#9BEC00' : '#9BEC00',
  ];


  useEffect(() => {
    if (investmentProperties.length > 0) {
      setLoading(false);
    }
    else if(!investmentProperties){
      setLoading(false)
      setError(true)
    }
  }, [investmentProperties]);

  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels: propertyname,
    colors: barColors,  // Setting the colors for the donut slices
    legend: {
      show: true,
      customLegendItems: propertyname,
      markers: {
        fillColors: barColors,
      },
      labels: {
        colors: theme.palette.mode === 'dark' ? '#FFFFFF' : '#333333',
      },
      position: "bottom",
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <Box id="chart-property-growth" sx={{
      padding: '0px',
      borderRadius: '10px',
      display: loading ? 'flex' : 'block',
      justifyContent: loading ? 'center' : 'initial',
      alignItems: loading ? 'center' : 'initial',
    }}>
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) :error ? (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
        textAlign="center"
      >
        <Typography variant="h6">No Data Found!</Typography>
      </Box>):  (
        <ReactApexChart options={options} series={seriesData} type="donut" height={410} width="100%" />
      )}
    </Box>
  );
};

export default PortfolioWealth;
