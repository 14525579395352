import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Typography, Box, Button } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  indexAxis: 'y',
  elements: {
    bar: { borderWidth: 2 },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Property Yield',
    },
  },
};

const data = {
  labels: ['Property 1', 'Property 2'],
  datasets: [
    {
      label: 'Yield',
      data: [4, 3], // Dummy data
      backgroundColor: ['rgba(53, 102, 255, 0.5)', 'rgba(75, 192, 192, 0.5)'],
      borderColor: ['rgba(53, 102, 255, 1)', 'rgba(75, 192, 192, 1)'],
      borderWidth: 1,
    },
  ],
};

const PropertyYieldChart = () => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Property Yield
    </Typography>
    <Bar data={data} options={{ ...options, indexAxis: 'x' }} />
    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="caption">
        Not seeing what you would expect here? Fill out your <Button>Portfolio</Button>
      </Typography>
      <Button>Want more info?</Button>
    </Box>
  </Box>
);

export default PropertyYieldChart;
