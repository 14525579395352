import React, { useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme';
import { PopupWidget, PopupButton } from 'react-calendly';

const PricingPage: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box className="pricingPage" sx={{
      backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "#141B2D",
      minHeight: '100vh',
      paddingTop: '64px', // Adjust based on your navbar height
    }}>
      <Container maxWidth="xl">
        <Typography variant="h2" gutterBottom sx={{ 
          fontSize: "36px", 
          fontWeight: "700", 
          color: colors.grey[100], 
          textAlign: "center" 
        }} mb={4}>
          Choose Your Pricing
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ 
          fontSize: "18px", 
          fontWeight: "400", 
          color: colors.grey[300], 
          textAlign: "center" 
        }} mb={8}>
          Select the plan that best fits your business goals and operations.
        </Typography>

        {/* Stripe Pricing Table */}
        <Box mb={8}>
          <div dangerouslySetInnerHTML={{
            __html: `
              <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
              <stripe-pricing-table pricing-table-id="prctbl_1PdtI3CYdLzPHpqCkpf3eJRf"
              publishable-key="pk_live_51PFXSvCYdLzPHpqCCVSvcWjOfzFb7hjqYDVv5kEz5VGyP2vS1EbuAJhT8XanPKfzjr4AzuUMKWMznDGWpdNJEnXp006U0uEKyv">
              </stripe-pricing-table>
            `
          }} />
        </Box>

        {/* Additional Content */}
        <Box textAlign="center" mb={8}>
          <Typography variant="h4" gutterBottom sx={{ 
            fontSize: "24px", 
            fontWeight: "600", 
            color: colors.grey[100]
          }} mb={2}>
            Want to discuss custom pricing and volumes?
          </Typography>
          <PopupButton
            url="https://calendly.com/amol-investar/30min"
            rootElement={document.getElementById('root') as HTMLElement}
            text="Schedule a Consultation"
            styles={{
              backgroundColor: "#5BC0BE",
              color: "#ffffff",
              border: "none",
              borderRadius: "10px",
              padding: "12px 24px",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PricingPage;