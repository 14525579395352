
import React, { useEffect, useState } from 'react'
import SearchPage from './SearchPage'
import BuyersAgentDashboard from './DashBoard'
import { Auth } from 'aws-amplify';
import { fetchAdvisors } from '../../../hooks/buyerAgent';
import { toast } from 'react-toastify';
import AuthenticatedAPI from '../../../auth_helper';
import { Box, CircularProgress } from '@mui/material';

const Index = () => {
  const [buyerAgent, setBuyersAgent] = useState()
  const [activeIndex, setActiveIndex] = useState(0)
  const [agents, setAgents] = useState()
  const [agentDetails, setAgentsDetail] = useState()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAllAdvisors();
  }, [])

  const fetchAllAdvisors = async () => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userAttributes = await Auth.userAttributes(user);
      const id = userAttributes.find((attr) => attr.Name === "sub")?.Value ?? "";
      const result = await fetchAdvisors(id);
      await fetchAdvisorsEquity(id);
      setAgents(result);
      console.log('result', result);
    } catch (error) {
      console.error('Error fetching advisors:', error);
      toast.error('Failed to fetch advisors. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  const fetchAdvisorsEquity = async (id: any) => {
    try {
      const response = await AuthenticatedAPI.request(
        'BuyersAgentsAPI',
        'get',
        `/agents/buyers-agents-equity/${id}?sort=equity`
      );

      if (!response) {
        throw new Error('Failed to fetch buyer-agent information');
      }
      setAgentsDetail(response);
      return response;
    } catch (error) {
      console.error('Error fetching buyer-agent information:', error);
      toast.error('Failed to fetch buyer-agent information. Please try again later.');
      return null; // Or some default/fallback value
    }
  }

  const handleSearch = async (result: any) => {
    if (result) {
      const initialAgentId = result.investor_id;
      setBuyersAgent(result);
      setActiveIndex(1);
    } else {
      toast.error('Agent not found!');
    }
  };

  return (
    <>
      {loading && (<> <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%"
      >
        <CircularProgress color="secondary" />
      </Box>
      </>
      )}
      {activeIndex === 0 && <SearchPage handleSearch={handleSearch} agents={agents} agentsDetail={agentDetails} />}
      {activeIndex === 1 && <BuyersAgentDashboard buyerAgentData={buyerAgent} agents={agents} />}
    </>
  )
}

export default Index
