import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import PropertyGrowthChart from '../../components/mapCharts/propertyGrowth'; // Import your Property Growth Chart component
import PropertyYieldChart from '../../components/mapCharts/propertyYieldChart'; // Import your Property Yield Chart component
import PropertyCashflowChart from '../../components/mapCharts/propertyCashflowChart'; // Import your Property Cashflow Chart component
import BorrowableEquityChart from '../../components/mapCharts/borrowableEquityChart'; // Import your Property Cashflow Chart component
import LoanToValueRatioChart from '../../components/mapCharts/loanToValueRatioChart'; // Import your Property Cashflow Chart component
import PortfolioCashflowChart from '../../components/mapCharts/portfolioCashflow'; // Import your Property Cashflow Chart component
import BucketsChart from '../../components/mapCharts/bucketsChart'; // Import your Property Cashflow Chart component
import SafetyBufferChart from '../../components/mapCharts/safetyBufferChart'; // Import your Property Cashflow Chart component
import MonthsLeftSavingsChart from '../../components/mapCharts/monthsLeftSavingsChart'; // Import your Property Cashflow Chart component






const MapPage = () => {
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
      Heres your current positon.
      </Typography>
      <Grid container spacing={3}>
        {/* Property Growth Chart */}
        <Grid item xs={12} md={6} lg={4}>
          <PropertyGrowthChart />
        </Grid>
        
        {/* Property Yield Chart */}
        <Grid item xs={12} md={6} lg={4}>
          <PropertyYieldChart />
        </Grid>
        
        {/* Property Cashflow Chart */}
        <Grid item xs={12} md={6} lg={4}>
          <PropertyCashflowChart />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BorrowableEquityChart />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <LoanToValueRatioChart />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PortfolioCashflowChart />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <BucketsChart  />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SafetyBufferChart  />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MonthsLeftSavingsChart  />
        </Grid>
        {/* Add more charts here following the same pattern */}
      </Grid>
    </Box>
  );
};

export default MapPage;
