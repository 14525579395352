import { Box, IconButton, Badge, useTheme, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import AuthenticatedAPI from "../../../auth_helper";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 70,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(32px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#4cceac' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));
interface ProfileData {
  financialGoal?: any[];

  familyInfo?: { dependentKids: any[]; earningMembers: any[] };
  employmentInfo?: any[];
  currentPortfolio?: { investmentProperties: any[] };
  investmentStrategy?: any;
  [key: string]: any;
}


const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [profileData, setProfileData] = useState<ProfileData | null>(null);

  const dispatch = useDispatch()
  const getUserProfile = async () => {
    try {
      const userData = await Auth?.currentAuthenticatedUser().then(
        async (res) => {
          const response = await AuthenticatedAPI.request(
            "InvestmentAPI",
            "get",
            `/api/profile/${res.username}`
          );

          if (!response) {

            throw new Error("Failed to fetch user profile");
          }
          if (response) {
            setProfileData(response.data);

          }
          return response;
        }
      );
    } catch (error) {
      // setLoading(false);
      console.log("Error fetching user profile:", error);
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {

        getUserProfile();

      } catch (error) {
      }
    };

    fetchUserData();
  }, [dispatch]);

  const investmentProperties =
    profileData?.currentPortfolio?.investmentProperties || [];

  const borrowableEquities = investmentProperties.map((property: any) => {
    const currentEstimatedValue =
      parseFloat(property.currentestimatedvalue) || 0;
    const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
    return currentEstimatedValue * 0.8 - currentLoanAmount;
  });

  const toatlEquity = borrowableEquities.reduce(
    (total, value) => total + value,
    0
  );

  console.log('toatlEquity :>> ', toatlEquity);
  const handleChange = (event: any) => {
    colorMode.toggleColorMode();
  };

  const notifications = toatlEquity >= 6500
  ? [`Your Portfolio Borrowable Equity is ${toatlEquity.toFixed(0)}`] 
  : [];
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadNotifications, setUnreadNotifications] = useState(notifications);


  const handleMarkAsRead = (index: number) => {
    const updatedNotifications = [...unreadNotifications];
    updatedNotifications.splice(index, 1); // Remove the notification at the given index
    setUnreadNotifications(updatedNotifications);
  };
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as unknown as React.SetStateAction<null>);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{

  },[unreadNotifications])
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        sx={{
          display: "flex",
          backgroundColor: colors.primary[400],
          borderRadius: "3px"
        }}
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
      <IconButton type="button" sx={{ p: 1 }}>
        <SearchIcon />
      </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">

        <FormControlLabel
          className="custom_switch"
          onChange={handleChange}
          control={<MaterialUISwitch defaultChecked />}
          label="MUI switch"
        />
        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event)}>
          <Badge
            badgeContent={unreadNotifications.length}
            color="error"
            overlap="circular"
          >
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: '500px',
              width: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              padding: '10px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {unreadNotifications.length > 0 ? (
            unreadNotifications.map((notification, index) => (
              <MenuItem key={index} onClick={handleClose}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    {notification}
                  </Typography>
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the menu from closing
                      handleMarkAsRead(index);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </MenuItem>
            ))
          ) : (
            <MenuItem onClick={handleClose}>
              <Typography variant="body2">No new notifications</Typography>
            </MenuItem>
          )}
        </Menu>

        {/* <IconButton onClick={colorMode.toggleColorMode}>
        {theme.palette.mode === "dark" ? (
          <DarkModeOutlinedIcon />
        ) : (
          <LightModeOutlinedIcon />
        )}
      </IconButton> */}

        {/* <IconButton>
        <NotificationsOutlinedIcon />
      </IconButton>
      <IconButton>
        <SettingsOutlinedIcon />
      </IconButton>
      <IconButton>
        <PersonOutlinedIcon />
      </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;
