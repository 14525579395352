import React from "react";
import { Formik, Form, Field } from "formik";
import {
  Button,
  LinearProgress,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Card,
  CardContent,
  useTheme,
  Box,
} from "@mui/material";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { EmploymentType, UserState } from "../../../types/User";
import { tokens } from "../../../theme";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import { updatePersonalInfo } from "../../../features/userSlice";

const IncomeSchema = yup.object({
  employmentType: yup.string().required("Employment type is required"),
  selfEmployedIncome: yup
    .number()
    .when("employmentType", (employmentType, schema) =>
      //@ts-ignore
      employmentType === "SelfEmployed"
        ? schema
          .required("Self Employed Income is required")
          .positive("Income must be positive")
        : schema
    )
    .nullable(),
  netMonthlyIncomeMA: yup
    .number()
    .when("employmentType", (employmentType, schema) =>
      //@ts-ignore
      employmentType === "PAYG"
        ? schema
          .required("Net Monthly Income (MA) is required")
          .positive("Income must be positive")
        : schema
    )
    .nullable(),
  netMonthlyIncomeFA: yup
    .number()
    .when("employmentType", (employmentType, schema) =>
      //@ts-ignore
      employmentType === "PAYG"
        ? schema
          .required("Net Monthly Income (FA) is required")
          .positive("Income must be positive")
        : schema
    )
    .nullable(),
  // dividends: yup.number().positive("Dividends must be positive").nullable(),
  // overtime: yup.number().positive("Overtime must be positive").nullable(),
  // otherIncome: yup.number().positive("Other Income must be positive").nullable(),
  // centreLink: yup.number().positive("CentreLink must be positive").nullable(),
  // portfolio_value: yup.number().positive("portfolio_value must be positive").nullable(),
});

interface income {

  employmentInfo: any;

}
interface ProfileData {
  personalInfo: any;
  employmentInfo: income;
}
interface IncomeProps {
  handleNext: (values: Partial<UserState>) => void;
  handleBack: () => void;
  profiledata: ProfileData,
  formData: UserState;
}

const Income: React.FC<IncomeProps> = ({ handleNext, handleBack, formData, profiledata }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{
        employmentType: profiledata?.employmentInfo?.employmentInfo[0]?.employment_type ?? formData.employmentInfo.employmentType,
        selfEmployedIncome: profiledata?.employmentInfo?.employmentInfo[0]?.self_employed_income ?? formData.employmentInfo.selfEmployedIncome,
        netMonthlyIncomeMA: profiledata?.employmentInfo?.employmentInfo[0]?.net_monthly_income_ma ?? formData.employmentInfo.netMonthlyIncomeMA,
        netMonthlyIncomeFA: profiledata?.employmentInfo?.employmentInfo[0]?.net_monthly_income_fa ?? formData.employmentInfo.netMonthlyIncomeFA,
        dividends: profiledata?.employmentInfo?.employmentInfo[0]?.dividends ?? formData.employmentInfo.dividends,
        overtime: profiledata?.employmentInfo?.employmentInfo[0]?.overtime ?? formData.employmentInfo.overtime,
        otherIncome: profiledata?.employmentInfo?.employmentInfo[0]?.other_income ?? formData.employmentInfo.otherIncome,
        centreLink: profiledata?.employmentInfo?.employmentInfo[0]?.centre_link ?? formData.employmentInfo.centreLink,
        portfolio_value: profiledata?.employmentInfo?.employmentInfo[0]?.portfolio_value ?? formData.employmentInfo.portfolio_value,
      }}
      validationSchema={IncomeSchema}
      onSubmit={(values, { setSubmitting }) => {
        // 
        // dispatch(updatePersonalInfo(profiledata?.personalInfo?.personalInfo[0] || {}));
        handleNext({
          employmentInfo: {
            employmentType: values.employmentType,
            selfEmployedIncome: values.selfEmployedIncome,
            netMonthlyIncomeMA: values.netMonthlyIncomeMA,
            netMonthlyIncomeFA: values.netMonthlyIncomeFA,
            dividends: values.dividends || Number(0),
            overtime: values.overtime || Number(0),
            otherIncome: values.otherIncome || Number(0),
            centreLink: values.centreLink || Number(0),
            portfolio_value: values.portfolio_value || Number(0),
          },
        });
        setSubmitting(false);
      }}
    >
      {({ values, submitForm, isSubmitting, handleChange }) => (
        <div className="flex flex-col items-center justify-center">

          <Form className="w-full">

            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: colors.primary[100],
              }}
            >
              Income Details
            </Typography>
            <Box my={3} display="flex" justifyContent="space-between">
              {/* <Button variant="contained" onClick={handleBack}
                sx={{
                  color: colors.grey[900],
                  backgroundColor: colors.blueAccent[100],
                  padding: '15px 18px 15px 15px',
                  height: '40px',
                  "&:hover": {
                    backgroundColor: colors.blueAccent[300], // Adjust hover color as needed
                  },
                }}>
                <ArrowBackOutlinedIcon sx={{ mr: 1 }} />
                Back
              </Button> */}
              <Box sx={{ position: 'relative', width: 200 }} onClick={handleBack}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    color: '#fff',
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                      '& .overlay': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'white',
                      overflow: 'hidden',
                      width: 0,
                      height: '100%',
                      transition: 'all 0.6s ease-in-out 0s',
                    }}
                  >
                    <Box
                      className="text"
                      sx={{
                        color: 'black',
                        fontSize: 16,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ArrowBackOutlinedIcon sx={{ mr: 1, }} />Back
                    </Box>
                  </Box>
                </Button>
              </Box>

              {/* <Button
                variant="contained"
                disabled={isSubmitting}
                onClick={submitForm}
                sx={{
                  color: colors.grey[900],
                  backgroundColor: colors.blueAccent[100],
                  padding: '15px 15px 15px 18px',
                  height: '40px',
                  "&:hover": {
                    backgroundColor: colors.blueAccent[300], // Adjust hover color as needed
                  },
                }}>
                Save & Continue.. <ArrowForwardIcon sx={{ ml: 1 }} />
              </Button> */}
              <Box sx={{ position: 'relative', width: 200 }} >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  sx={{
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    color: '#fff',
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                      '& .overlay': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  Save & Continue<ArrowForwardIcon sx={{ ml: 1, }} />
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'white',
                      overflow: 'hidden',
                      width: 0,
                      height: '100%',
                      transition: 'all 0.6s ease-in-out 0s',
                    }}
                  >
                    <Box
                      className="text"
                      sx={{
                        color: 'black',
                        fontSize: 16,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Save & Continue<ArrowForwardIcon sx={{ ml: 1, }} />
                    </Box>
                  </Box>
                </Button>
              </Box>
            </Box>

            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "30px !important",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                borderRadius: "10px"
                // backgroundColor: colors.blueAccent[900],
              }}
            >
              <CardContent sx={{ padding: "10px !important" }}>

                <FormControl component="fieldset" sx={{ marginBottom: '20px !important', }}>
                  <FormLabel component="legend" className="mb-3" sx={{ fontSize: "21px", fontWeight: "600", color: `${colors.grey[100]} !important`, }}>Employment Type</FormLabel>
                  <RadioGroup
                    row
                    className="radio_styleOne"
                    name="employmentType"
                    value={values.employmentType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="PAYG"
                      control={<Radio />}
                      label="PAYG"
                      sx={{
                        color: `${colors.grey[100]} !important`,
                        '& .Mui-checked': {
                          color: `${colors.grey[100]} !important`,
                        },
                      }}

                    />
                    <FormControlLabel
                      value="SelfEmployed"
                      control={<Radio />}
                      label="Self Employed"
                      sx={{
                        color: `${colors.grey[100]} !important`,
                        '& .Mui-checked': {
                          color: `${colors.grey[100]} !important`,
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                {values.employmentType === "PAYG" && (
                  <>
                    <Grid
                      container
                      columns={12}
                      spacing={4}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field
                          component={TextField}
                          name="netMonthlyIncomeMA"
                          label="Net Monthly Income (MA)"
                          type="number"
                          fullWidth
                          margin="normal"
                          sx={{
                            marginBottom: '30px !important',
                            "& .MuiInputBase-input-focused": {
                              color: colors.grey[100],
                              borderColor: colors.grey[100],
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: colors.grey[100],
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: colors.grey[100],
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} className="mb-3">
                        <Field
                          component={TextField}
                          name="netMonthlyIncomeFA"
                          label="Net Monthly Income (FA)"
                          type="number"
                          fullWidth
                          margin="normal"
                          sx={{
                            marginBottom: '30px !important',
                            "& .MuiInputBase-input-focused": {
                              color: colors.grey[100],
                              borderColor: colors.grey[100],
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: colors.grey[100],
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: colors.grey[100],
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {values.employmentType === "SelfEmployed" && (
                  <Grid
                    container
                    columns={12}
                    spacing={4}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Field
                        component={TextField}
                        name="selfEmployedIncome"
                        label="Self Employed Income"
                        type="number"
                        fullWidth
                        margin="normal"
                        sx={{
                          marginBottom: '30px !important',
                          "& .MuiInputBase-input-focused": {
                            color: colors.grey[100],
                            borderColor: colors.grey[100],
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: colors.grey[100],
                          },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: colors.grey[100],
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Typography variant="h5" gutterBottom sx={{ marginBottom: '30px !important', }}>
                  Additional Income (if applicable)
                </Typography>

                <Grid
                  container
                  columns={12}
                  spacing={4}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingTop: '15px !important', }}>
                    <Field
                      component={TextField}
                      name="dividends"
                      label="Dividends"
                      type="number"
                      fullWidth
                      margin="normal"
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingTop: '15px !important', }}>
                    <Field
                      component={TextField}
                      name="overtime"
                      label="Overtime"
                      type="number"
                      fullWidth
                      margin="normal"
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingTop: '15px !important', }}>
                    <Field
                      component={TextField}
                      name="otherIncome"
                      label="Other Income"
                      type="number"
                      fullWidth
                      margin="normal"
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingTop: '15px !important', }}>
                    <Field
                      component={TextField}
                      name="centreLink"
                      label="CentreLink"
                      type="number"
                      fullWidth
                      margin="normal"
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingTop: '15px !important', }}>
                    <Field
                      component={TextField}
                      name="portfolio_value"
                      label="Property Portfolio Value"
                      type="number"
                      fullWidth
                      margin="normal"
                      sx={{
                        "& .MuiInputBase-input-focused": {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: colors.grey[100],
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {isSubmitting && <LinearProgress />}

              </CardContent>
            </Card>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default Income;