import React, { useContext, useState } from 'react';
import axios from 'axios';
import AuthenticatedAPI from '../../../auth_helper';
import { useTheme, Box, Button, Card, Grid, Typography, Container, IconButton } from '@mui/material';
import { ColorModeContext, tokens } from "../../../theme";
import { Link } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";


interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  ownProperty: string;
  properties: number;
}

const PropertyForm: React.FC = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const imageSrc = theme.palette.mode === 'dark' ? '/logo-light.svg' : '/logo-dark.svg';


  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    ownProperty: 'no',
    properties: 0,
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: id === 'properties' ? Number(value) : value,
    }));
  };

  const validate = (): boolean => {
    const newErrors: Partial<FormData> = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await AuthenticatedAPI.request('waitlist', 'post', '/interest',  {
        body: formData,
      });
  
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
    
      <div style={{ backgroundColor: theme.palette.mode === 'dark' ? "#1F2A40" : "#ffffff", }}>
        <Container maxWidth="xl">
          {/* <nav className="bg-white dark:bg-white w-full z-20 top-0 start-0 border-b border-gray-100 dark:border-gray-100"> */}
          <nav
            style={{
              backgroundColor: theme.palette.mode === 'dark' ? "#1F2A40" : "#ffffff",
            }}
          >
            <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
            <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                <img src={imageSrc} className="h-8" alt="Logo" />
              </Link>
              <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse ml-auto">
              <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}
                    sx={{
                      width: "48px",
                      height: "48px",
                      marginRight: "15px",
                      color: theme.palette.mode === 'dark' ? '#5BC0BE !important' : '#5BC0BE !important',
                      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(91 192 190 / 25%) !important' : '#EFF9F8 !important',
                    }}
                  >
                    {theme.palette.mode === "dark" ? (
                      <DarkModeOutlinedIcon />
                    ) : (
                      <LightModeOutlinedIcon />
                    )}
                  </IconButton>
                </Box>
                <Link to="/dashboard">
                  <Button variant="contained" color="secondary" startIcon={<LockIcon />}
                    sx={{
                      borderRadius: "10px",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      color: "#ffffff",
                      padding: "12px 50px",
                    }}>Login</Button>
                  </Link>
                <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                  <span className="sr-only">Open main menu</span>
                  <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h15M1 7h15M1 13h15" />
                  </svg>
                </button>
              </div>
              <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1 mx-20" id="navbar-sticky">
                <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 bg-transparent">
                  <li>
                    {/* <a href="#" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-gray-900 md:dark:hover:bg-transparent dark:border-gray-700">Home</a> */}
                    <a href="#" style={{
                      display: "block",
                      color: theme.palette.mode === 'dark' ? "#ffffff" : "#000000",
                      backgroundColor: "transparent",
                    }}>Home</a>
                  </li>
                  {/* <li>
                    <a href="#" style={{
                      display: "block",
                      color: theme.palette.mode === 'dark' ? "#ffffff" : "#000000",
                      backgroundColor: "transparent",
                    }}>Marketplace</a>
                  </li> */}
                  <li>
                    <a href="#" style={{
                      display: "block",
                      color: theme.palette.mode === 'dark' ? "#ffffff" : "#000000",
                      backgroundColor: "transparent",
                    }}>Investor.io</a>
                  </li>
                  <li>
                    <a href="#" style={{
                      display: "block",
                      color: theme.palette.mode === 'dark' ? "#ffffff" : "#000000",
                      backgroundColor: "transparent",
                    }}>Our Story</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </Container>
      </div>
    
      <Box>
        <div className="flex items-center justify-center min-h-screen" style={{ backgroundColor: theme.palette.mode === 'dark' ? "#182036" : "#E9FFFC", }}>
          <Box className="p-8 rounded shadow-md w-full max-w-md" 
            sx={{
              padding: "45px 30px !important",
              height: "100% !important",
              borderRadius: "20px !important",
              textAlign: "left !important",
              backgroundColor: theme.palette.mode === 'dark' ? "#1F2A40" : "#ffffff",
              boxShadow: "0px 4px 34px 0px rgb(0 0 0 / 09%) !important",                  
            }}
          >
            <h2 className="text-2xl font-bold mb-6 text-center" style={{ color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}>Register your intrest</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="firstName" className="block" style={{ color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}>First Name</label>
                <input
                  type="text"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-4 py-2 border rounded-md shadow-sm text-gray-900 focus:ring focus:ring-opacity-50 focus:ring-indigo-300 ${errors.firstName ? 'border-red-500' : 'border-gray-300'}`}
                  style={{ backgroundColor: theme.palette.mode === 'dark' ? "#1f2a40" : "#ffffff", color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}
                />
                {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="lastName" className="block" style={{ color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}>Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-4 py-2 border rounded-md shadow-sm text-gray-900 focus:ring focus:ring-opacity-50 focus:ring-indigo-300 ${errors.lastName ? 'border-red-500' : 'border-gray-300'}`}
                  style={{ backgroundColor: theme.palette.mode === 'dark' ? "#1f2a40" : "#ffffff", color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}
                />
                {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block" style={{ color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}>Email</label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-4 py-2 border rounded-md shadow-sm text-gray-900 focus:ring focus:ring-opacity-50 focus:ring-indigo-300 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                  style={{ backgroundColor: theme.palette.mode === 'dark' ? "#1f2a40" : "#ffffff", color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="ownProperty" className="block" style={{ color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}>Are you investor ?</label>
                <select
                  id="ownProperty"
                  value={formData.ownProperty}
                  onChange={handleChange}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm text-gray-900 focus:ring focus:ring-opacity-50 focus:ring-indigo-300"
                  style={{ backgroundColor: theme.palette.mode === 'dark' ? "#1f2a40" : "#ffffff", color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              {/* {formData.ownProperty === 'yes' && ( */}
                <div className="mb-4">
                  <label htmlFor="properties" className="block" style={{ color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}>How many properties do you have in your portlio ?</label>
                  <div className="text-xl text-center mb-2 font-bold" style={{ color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}>{formData.properties}</div>
                  <input
                    type="range"
                    id="properties"
                    min="0"
                    max="20"
                    value={formData.properties}
                    onChange={handleChange}
                    className="block w-full"
                    style={{ backgroundColor: theme.palette.mode === 'dark' ? "#1f2a40" : "#ffffff", color: theme.palette.mode === 'dark' ? "#ffffff" : "#121212", }}
                  />
                </div>
              {/* )} */}
              <button
                type="submit"
                className="w-full px-4 py-2 bg-indigo-500 text-white rounded-md shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </form>
          </Box>
        </div>
      </Box>

    </>
  );
}

export default PropertyForm;
