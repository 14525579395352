import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const data = {
  labels: ['Rental Income', 'Expenses', 'Net'],
  datasets: [
    {
      label: 'Portfolio Cashflow',
      data: [45500, -90000, -44500], // Replace these with actual data
      backgroundColor: [
        'rgba(75, 192, 192, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(153, 102, 255, 0.2)'
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(255,99,132,1)',
        'rgba(153, 102, 255, 1)'
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Portfolio Cashflow',
    },
  },
};

const PortfolioCashflowChart = () => {
  return <Bar data={data} options={options} />;
};

export default PortfolioCashflowChart;
