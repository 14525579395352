import React from "react";
import { Box, Container, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../../../theme";

type FAQItem = {
    panel: string;
    question: string;
    answer: string;
  };
type FAQSectionProps = {
    expanded: string | false;
    handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  };

  const FAQSection: React.FC<FAQSectionProps> = ({ expanded, handleChange }) => {
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const faqData = [
    { panel: 'panel1', question: "How long is subscription valid for ?", answer: "subscription is valid for 1 year" },
    { panel: 'panel2', question: "Can I change my plan later?", answer: "You can change your plan after the initial subscription." },
    { panel: 'panel3', question: "What is your cancellation policy?", answer: "You can cancel your subscription after the initial subscription." },
    { panel: 'panel4', question: "Can other info be added to an invoice?", answer: "Yes we ask for your name and email address." },
    { panel: 'panel5', question: "How does billing work?", answer: "Billing is handled by Stripe for upfront and recurring payments." },
    { panel: 'panel6', question: "How do I change my account email?", answer: "Reachout at support@investar.io" },
  ];

  return (
    <Box className="faqSection" py={isMobile ? 4 : 8}>       
      <Container maxWidth="xl">  
        <Grid container spacing={0} columns={12} alignItems="center">
          <Grid item xs={12} mb={isMobile ? 4 : 8}>
            <Box className="faq_box"
              sx={{
                backgroundColor: theme.palette.mode === 'light' ? "#FBFBFB" : "#1F2A40",
                padding: isMobile ? "20px" : "40px",
                borderRadius: "20px",
              }}
            >
              <Typography variant="h1" gutterBottom sx={{ 
                fontSize: isMobile ? "28px" : isTablet ? "32px" : "36px", 
                fontWeight: "700", 
                color: colors.grey[100], 
                letterSpacing: "-1px" 
              }} mb={2}>
                Frequently asked questions
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ 
                fontSize: isMobile ? "16px" : "20px", 
                fontWeight: "400", 
                color: colors.grey[100] 
              }} mb={isMobile ? 3 : 5}>
                Know more about our product features.
              </Typography>

              {faqData.map((faq) => (
                <Accordion 
                  key={faq.panel}
                  expanded={expanded === faq.panel} 
                  onChange={handleChange(faq.panel)}
                  sx={{
                    backgroundColor: "transparent !important",
                    backgroundImage: "none",
                    padding: isMobile ? "16px 0" : "24px 0", 
                    color: colors.grey[100],
                    margin: "0 !important",
                    boxShadow: "none !important",
                    borderBottom: "1px solid #EAECF0",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === faq.panel
                        ? <img src={`${process.env.PUBLIC_URL}/icon_minus.svg`} alt="collapse icon" />
                        : <img src={`${process.env.PUBLIC_URL}/icon_plus.svg`} alt="expand icon" />
                    }
                    aria-controls={`${faq.panel}bh-content`}
                    id={`${faq.panel}bh-header`}
                    sx={{ padding: "0" }}
                  >
                    <Typography sx={{ 
                      fontSize: isMobile ? "16px" : "18px", 
                      fontWeight: "600", 
                      color: colors.grey[100] 
                    }}>
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "8px 0 0" }}>
                    <Typography sx={{ 
                      fontSize: isMobile ? "14px" : "16px", 
                      fontWeight: "400", 
                      color: theme.palette.mode === 'light' ? "#667085" : "rgb(255 255 255 / 60%)" 
                    }}>
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Grid>
        </Grid>            
      </Container>
    </Box>
  );
};

export default FAQSection;