import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, ToggleButton, ToggleButtonGroup, Card } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { tokens } from "../../../../theme";
import {
  CashFlowProjectionData,
  PropertyDetailsData,
  KeyAssumptionsData,
  PIScenarioData,
  IOScenarioData,
  CashFlowYearData,
} from "../../../../types/CashFlow";
import CashFlowScenario from "../cashFlowScenario";
import KeyAssumptions from "../keyAssumptions";
import { updateCashFlowProjection, updateKeyAssumptions } from "../../../../features/cashFlow";
import { vacantPerioudGrossRent } from "../../../../hooks/properties/properties";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(16),
  padding: theme.spacing(1.5, 3),
  border: `2px solid ${theme.palette.primary.main}`,
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface CashFlowProjectionProps {
  propertyDetails: PropertyDetailsData | null;
  keyAssumptions?: KeyAssumptionsData | null;
  cashFlowProjection: CashFlowProjectionData | null;
  // updateCashFlowProjections: (projection: CashFlowProjectionData) => void;
}

const CashFlowProjection: React.FC<CashFlowProjectionProps> = ({
  propertyDetails,
  keyAssumptions,
  cashFlowProjection,
  // updateCashFlowProjections,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedScenario, setSelectedScenario] = useState<"scenario1" | "scenario2">("scenario1");
  // const [selectedScenario, setSelectedScenario] = useState('scenario1');
  const dispatch = useDispatch();
  const cashFlowAnalysis = useSelector((state: RootState) => state.cashFlowAnalysis);

  const handleScenarioChange = (event: React.MouseEvent<HTMLElement>, newScenario: "scenario1" | "scenario2") => {
    setSelectedScenario(newScenario);
  };

  const calculatePrincipalInterestRepayment = (
    (loanAmount: number, interestRate: number, loanTerm: number): number => {

      const annualInterestRate = interestRate / 100;
      // const monthlyInterestRate = interestRate / 100 / 12;
      // const numPayments = loanTerm * 12;
      const numPayments = loanTerm;
      const payment =
        (loanAmount * (annualInterestRate * Math.pow(1 + annualInterestRate, numPayments))) /
        (Math.pow(1 + annualInterestRate, numPayments) - 1);
      // const payment =
      //   (loanAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numPayments))) /
      //   (Math.pow(1 + monthlyInterestRate, numPayments) - 1);
      console.log('payment :>> ', payment);
      return payment;
    }
    // [loanAmount, interestRate, loanTerm]
  );

  const calculateInterestOnlyRepayment = (
    (loanAmount: number, interestRate: number): number => {

      // const monthlyInterestRate = interestRate / 100 / 12;
      // // const monthlyInterestRate = interestRate / 100 ;
      // // const interestPayment = loanAmount * monthlyInterestRate;
      // const interestPayment = loanAmount * monthlyInterestRate;
      const annualInterestRate = interestRate / 100;
      const interestPayment = loanAmount * annualInterestRate;
      console.log('interestPayment :>> ', interestPayment);
      return interestPayment;
    }
  );

  const generateCashFlowYearsData = useCallback(
    (
      propertyDetails: PropertyDetailsData,
      keyAssumptions: KeyAssumptionsData,
      principalInterestRepayment: number,
      interestOnlyRepayment: number
    ): CashFlowYearData[] => {
      const { estimatedWeeklyRent, annualHoldingCosts, propertyValue, loanAmount, interestRate, loanTerm } = propertyDetails;
      const { capitalGrowthRate, rentalGrowthRate, inflationRate, vacancyPeriod } = keyAssumptions;

      const PMFofWR = ((annualHoldingCosts.propertyManagementFee * estimatedWeeklyRent) / 100) * 52
      console.log('PMFofWR', PMFofWR)
      const LFofWR = annualHoldingCosts.lettingFee * estimatedWeeklyRent;
      const cashFlowYears: CashFlowYearData[] = [];

      let currentPropertyValue = propertyValue;
      let currentLoanAmount = loanAmount;
      let currentRent = estimatedWeeklyRent * vacantPerioudGrossRent(vacancyPeriod);
      // let currentRent = estimatedWeeklyRent * 52;
      // let currentRentalExpenses =
      //   PMFofWR +
      //   LFofWR +
      //   annualHoldingCosts.maintenanceCostPA +
      //   annualHoldingCosts.councilRates +
      //   annualHoldingCosts.waterRates +
      //   annualHoldingCosts.insurance;
      //   console.log(currentRentalExpenses,"const LFofWR = (annualHoldingCosts.lettingFee * estimatedWeeklyRent) / 100")
      for (let year = 1; year <= 30; year++) {
        // Property Value
        if (year > 1) {
          currentPropertyValue *= 1 + capitalGrowthRate / 100;
        }

        // Loan Amount
        if (year === 1) {
          currentLoanAmount = loanAmount;
        } else {
          if (selectedScenario === "scenario1") {
            // Principal + Interest scenario
            currentLoanAmount =
              currentLoanAmount - principalInterestRepayment + (currentLoanAmount * interestRate) / 100 / 12;
          } else {
            // Interest Only scenario
            currentLoanAmount = loanAmount;
          }
        }

        // Equity
        const equity = currentPropertyValue - currentLoanAmount;

        // Gross Rent
        const grossRent = currentRent;
        let currentRentalExpenses =
          (PMFofWR + LFofWR +
            annualHoldingCosts.maintenanceCostPA +
            annualHoldingCosts.councilRates +
            annualHoldingCosts.waterRates +
            annualHoldingCosts.insurance)
        // grossRent * inflationRate / 100  // * year;


        //  let currentRentalExpenses = 
        //  (PMFofWR + LFofWR + 
        //   annualHoldingCosts.maintenanceCostPA + 
        //   annualHoldingCosts.councilRates + 
        //   annualHoldingCosts.waterRates + 
        //   annualHoldingCosts.insurance) * 
        //   grossRent *(1+inflationRate/100)


        //  let currentRentalExpenses = 
        //  (PMFofWR + LFofWR + 
        //   annualHoldingCosts.maintenanceCostPA + 
        //   annualHoldingCosts.councilRates + 
        //   annualHoldingCosts.waterRates + 
        //   annualHoldingCosts.insurance) * 
        //   grossRent *(1+ inflationRate/100)**year
        // Rental Expenses
        const rentalExpenses = currentRentalExpenses;
        // Before Tax Cash Flow
        const beforeTaxCashFlow =
          grossRent -
          rentalExpenses -
          (selectedScenario === "scenario1" ? principalInterestRepayment : interestOnlyRepayment);

        // Cost/Income pw
        const costIncomePw = beforeTaxCashFlow / 52;
        const PrincipalInterestRepayment = calculatePrincipalInterestRepayment(currentLoanAmount, interestRate, loanTerm);
        const InterestOnlyRepayment = calculateInterestOnlyRepayment(currentLoanAmount, interestRate);
        cashFlowYears.push({
          propertyValue: currentPropertyValue,
          loanAmount: currentLoanAmount,
          PrincipalInterestRepayment,
          InterestOnlyRepayment,
          year,
          equity,
          grossRent,
          rentalExpenses,
          beforeTaxCashFlow,
          costIncomePw,
        });

        // Update values for the next year
        currentRent *= 1 + rentalGrowthRate / 100;
        currentRentalExpenses *= 1 + inflationRate / 100;
      }

      return cashFlowYears;
    },
    [selectedScenario]
  );


  // const generateCashFlowYearsData = useCallback(
  //   (
  //     propertyDetails: PropertyDetailsData,
  //     keyAssumptions: KeyAssumptionsData,
  //     principalInterestRepayment: number,
  //     interestOnlyRepayment: number
  //   ): CashFlowYearData[] => {
  //     const { estimatedWeeklyRent, annualHoldingCosts, propertyValue, loanAmount, interestRate } = propertyDetails;
  //     const { capitalGrowthRate, rentalGrowthRate, inflationRate } = keyAssumptions;

  //     const cashFlowYears: CashFlowYearData[] = [];

  //     let currentPropertyValue = propertyValue;
  //     let currentLoanAmount = loanAmount;
  //     let currentRent = estimatedWeeklyRent * 52;
  //     let currentRentalExpenses =
  //       annualHoldingCosts.propertyManagementFee +
  //       annualHoldingCosts.lettingFee +
  //       annualHoldingCosts.maintenanceCostPA +
  //       annualHoldingCosts.councilRates +
  //       annualHoldingCosts.waterRates +
  //       annualHoldingCosts.insurance;

  //     for (let year = 1; year <= 30; year++) {
  //       // Property Value
  //       if (year === 1) {
  //         currentPropertyValue = propertyValue;
  //       } else {
  //         currentPropertyValue *= 1 + capitalGrowthRate / 100;
  //       }

  //       // Loan Amount
  //       if (year === 1) {
  //         currentLoanAmount = loanAmount;
  //       } else {
  //         if (selectedScenario === "scenario1") {
  //           // Principal + Interest scenario
  //           currentLoanAmount =
  //             currentLoanAmount - principalInterestRepayment + (currentLoanAmount * interestRate) / 100 / 12;
  //         } else {
  //           // Interest Only scenario
  //           currentLoanAmount = loanAmount;
  //         }
  //       }

  //       // Equity
  //       const equity = currentPropertyValue - currentLoanAmount;

  //       // Gross Rent
  //       const grossRent = currentRent;

  //       // Rental Expenses
  //       const rentalExpenses = currentRentalExpenses;

  //       // Before Tax Cash Flow
  //       const beforeTaxCashFlow =
  //         grossRent -
  //         rentalExpenses -
  //         (selectedScenario === "scenario1" ? principalInterestRepayment : interestOnlyRepayment);

  //       // Cost/Income pw
  //       const costIncomePw = beforeTaxCashFlow / 52;

  //       cashFlowYears.push({
  //         propertyValue,
  //         loanAmount,
  //         year,
  //         equity,
  //         grossRent,
  //         rentalExpenses,
  //         beforeTaxCashFlow,
  //         costIncomePw,
  //       });

  //       // Update values for the next year
  //       currentRent *= 1 + rentalGrowthRate / 100;
  //       currentRentalExpenses *= 1 + inflationRate / 100;
  //     }

  //     return cashFlowYears;
  //   },
  //   [selectedScenario]
  // );

  const calculateCashFlowProjection = useCallback(
    (propertyDetails: PropertyDetailsData, keyAssumptions: KeyAssumptionsData): CashFlowProjectionData => {
      const { loanAmount, interestRate, loanTerm } = propertyDetails;
      console.log('loanAmount :>> ', loanAmount);
      const principalInterestRepayment = calculatePrincipalInterestRepayment(loanAmount, interestRate, loanTerm);
      console.log('principalInterestRepayment :>> ', principalInterestRepayment);
      const interestOnlyRepayment = calculateInterestOnlyRepayment(loanAmount, interestRate);
      console.log('interestOnlyRepayment :>> ', interestOnlyRepayment);
      const cashFlowYears: CashFlowYearData[] = generateCashFlowYearsData(
        propertyDetails,
        keyAssumptions,
        principalInterestRepayment,
        interestOnlyRepayment
        // true
      );



      const scenario1: PIScenarioData = {
        principalInterestRepayment,
        cashFlowYears,
      };

      const scenario2: IOScenarioData = {
        InterestOnlyRepayment: interestOnlyRepayment,
        cashFlowYears,
      };

      return {
        assumptions: keyAssumptions,
        scenario1,
        scenario2,
      };
    },
    [calculatePrincipalInterestRepayment, calculateInterestOnlyRepayment, generateCashFlowYearsData, propertyDetails?.loanAmount]
  );

  // Utility function to compare objects for equality
  const areObjectsEqual = useCallback((obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }, []);

  const cashFlowProjectionHandler = useCallback(() => {
    if (propertyDetails && keyAssumptions) {
      const projection = calculateCashFlowProjection(propertyDetails, keyAssumptions);
      // Check if the new projection is different from the existing one

      if (!areObjectsEqual(projection, cashFlowProjection)) {
        dispatch(updateCashFlowProjection(projection));
      }
    }
  }, [propertyDetails, keyAssumptions, cashFlowProjection, calculateCashFlowProjection, areObjectsEqual]);

  useEffect(() => {
    cashFlowProjectionHandler();
  }, [propertyDetails, propertyDetails?.loanAmount, keyAssumptions, selectedScenario]);

  return (
    <Box id='myChart' >
      <Card
        sx={{
          padding: "18px 20px 20px 20px !important",
          marginBottom: "30px !important",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
          backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
        }}>
        <Box mb={4}>
          <KeyAssumptions
            keyAssumptions={cashFlowAnalysis.keyAssumptions}
            updateKeyAssumptions={(updates) =>
              dispatch(updateKeyAssumptions(updates))
            }
          />
        </Box>
        <Box display="flex" justifyContent="start">
          <ToggleButtonGroup
            value={selectedScenario}
            exclusive
            onChange={handleScenarioChange}
            sx={{ gap: 2 }}
          >
            {/* <StyledToggleButton
                  value="scenario1"
                  className="btn_styleOne"
                  sx={{
                    backgroundColor: colors.primary[900],
                    borderColor: colors.primary[400],
                  }}
                >
                  Principal & Interest
                </StyledToggleButton> */}
            <StyledToggleButton
              value="scenario1"
              className={selectedScenario === "scenario1" ? "btn_styleOne active" : "btn_styleOne"}
              onClick={() => {
                setSelectedScenario("scenario1");
                // Remove "active" class from the other button
                document.querySelector(".btn_styleTwo")?.classList.remove("active");
              }}
              sx={{
                backgroundColor: selectedScenario === "scenario1" ? "#5c59fd" : colors.primary[900],
                borderColor: colors.primary[400],
                color: selectedScenario === "scenario1" ? "#fff" : "#4cceac",
              }}
            >
              Principal & Interest
            </StyledToggleButton>
            {/* <StyledToggleButton
                  value="scenario2"
                  className="btn_styleTwo"
                  sx={{
                    backgroundColor: colors.primary[900],
                    borderColor: colors.primary[400],
                  }}
                >
                  Interest Only
                </StyledToggleButton> */}
            <StyledToggleButton
              value="scenario2"
              className={selectedScenario === "scenario2" ? "btn_styleTwo active" : "btn_styleTwo"}
              onClick={() => {
                setSelectedScenario("scenario2");
                // Remove "active" class from the other button
                document.querySelector(".btn_styleOne")?.classList.remove("active");
              }}
              sx={{
                backgroundColor: selectedScenario === "scenario2" ? "#5c59fd" : colors.primary[900],
                borderColor: colors.primary[400],
                color: selectedScenario === "scenario2" ? "#fff" : "#4cceac",
              }}
            >
              Interest Only
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Card>
      {selectedScenario === "scenario1" && cashFlowProjection?.scenario1 ? (
        <CashFlowScenario
          title="Principal & Interest Loan"
          scenario={cashFlowProjection.scenario1}
          loanType="principal-interest"
        />
      ) : selectedScenario === "scenario2" && cashFlowProjection?.scenario2 ? (
        <CashFlowScenario
          title="Interest-Only Loan"
          scenario={cashFlowProjection.scenario2}
          loanType="interest-only"
        />
      ) : (
        <Typography>No data available for the selected scenario</Typography>
      )}
    </Box>
  );
};

export default CashFlowProjection;
