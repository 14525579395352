import React from 'react';
import { Box, Typography, Button, Card, Grid, useTheme, CardMedia, CardContent } from '@mui/material';

import { tokens } from '../../../theme';
import { Link } from "react-router-dom";

interface Property {
    image: string;
    address: string;
    location: string;
}

interface PropertyCardProps {
    image: string;
    address: string;
    location: string;
}

const PropertyCard: React.FC<PropertyCardProps> = ({ image, address, location }) => (
    <Card>
        <CardMedia
            component="img"
            image={image}
            alt={address}
            style={{ width: '100%', height: '150px', objectFit: 'cover' }}
        />
        <CardContent>
        <Typography variant="h6">
        <Link to="/properties-details" style={{ textDecoration: 'none', color: 'inherit' }}>
          {address}
        </Link>
        </Typography>
            <Typography color="textSecondary">{location}</Typography>
        </CardContent>
    </Card>
);

interface MapProps {
    center: { lat: number; lng: number };
    zoom: number;
}

// const MapMarker: React.FC<{ lat: number; lng: number }> = ({ lat, lng }) => (
//     <div
//         style={{
//             position: 'absolute',
//             width: '10px',
//             height: '10px',
//             backgroundColor: 'red',
//             borderRadius: '50%',
//             transform: 'translate(-50%, -50%)',
//         }}
//     />
// );

const Map: React.FC<MapProps> = ({ center, zoom }) => (
    <Box style={{ height: '300px', width: '100%' }}>
        {/* <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyA9_-pJe4c69syfNQ5zw1Gf7-9HzE9nCwU' }}
            defaultCenter={center}
            defaultZoom={zoom}
        >
            <MapMarker lat={center.lat} lng={center.lng} />
        </GoogleMapReact> */}
    </Box>
);

const Properties: React.FC = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const properties: Property[] = [
        {
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTon_6btz9M09E7-Hmd0X-LsgQ3QvaoiU4T6g&s', // Placeholder image URL
            address: '2 Kelburn Rd',
            location: 'Berwick 3806',
        },
        {
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAYlV_-VdER1ZO9ySzMiitblLr4lIUWmoziQ&s', // Placeholder image URL
            address: '23 Willslie Cres',
            location: 'Berwick 3806',
        },
        {
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6W8620oBu_58QwR8OqREHfNWz01qssv9BdQ&s', // Placeholder image URL
            address: '27 Selina St',
            location: 'Morphett Vale 5162',
        },
        {
            image: 'https://images.pexels.com/photos/975771/pexels-photo-975771.jpeg?auto=compress&cs=tinysrgb&w=600', // Placeholder image URL
            address: '59 Orlestone St',
            location: 'Gosnells 6110',
        },
    ];

    const mapCenter = {
        lat: -31.9505, // Latitude for Perth, WA
        lng: 115.8605, // Longitude for Perth, WA
    };

    const mapZoom = 5;
    
    return (
        <Box padding={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                <Typography>

                    <Typography variant="h5" gutterBottom>
                        Hi Amar,
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        Here's your properties.
                    </Typography>
                </Typography>
                <Typography>


                    <Button variant="contained" color="secondary" style={{ marginBottom: theme.spacing(3) }}>
                        Add Property
                    </Button>
                </Typography>
            </Box>

            <Grid container spacing={3}>
                {properties.map((property, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <PropertyCard {...property} />
                    </Grid>
                ))}
            </Grid>
            <Typography paddingTop={3}>


                <Map center={mapCenter} zoom={mapZoom} />
            </Typography>
        </Box>
    );
};

export default Properties;
