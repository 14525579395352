import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserState,
  PersonalInfo,
  FinancialGoal,
  FamilyInfo,
  CurrentPortFolio,
  // Income,
  Expenses,
  EmploymentInfo,
  PropertyInfo,
  LoanInfo,
  InvestmentStrategy,
  EmploymentType,
  CurrentPortfolioInfo,
  BankingInfo,
} from '../types/User';

export const initialState: UserState = {
  personalInfo: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  },
  financialGoal: {
    year_of_retirement: 0,
    number_of_properties: 0,
    equity: 0,
    passive_income: 0,
    targetRentalYield: 0,
    isFirstIp: false,
    // rentVesting: false,
    needAssetProtection: false
  },
  familyInfo: {
    earningMembers: [],
    dependentKids: [],
  },
  currentPortfolio: {
    investmentProperties: []
    // address: '',
    // title: '',
    // currentEstimatedValue: 0,
    // initialDeposit: 0,
    // netRentalYield: 0,
    // currentLoanAmount: 0,
  },
  // income: {
  //   income: {},
  // },
  expenses: {
    existingInvestmentLoanAmount: 0,
    personalLoansAmount: 0,
    creditCardLimit: 0,
    livingExpenses: 0,
    leasePayments: 0,
    miscExpenses: 0,
  },
  employmentInfo: {
    employmentType: EmploymentType.PAYG,
    selfEmployedIncome: 0,
    netMonthlyIncomeMA: 0,
    netMonthlyIncomeFA: 0,
    dividends: 0,
    overtime: 0,
    otherIncome: 0,
    centreLink: 0,
    portfolio_value: 0,
    totalInvestedPropertyValue: 0,
  },
  propertyInfo: {
    properties: [],
  },
  loanInfo: {},
  investmentStrategy: {
    strategy: {},
  },
  bankingInfo: {
    consent: {},
    consentUrl: '',
    accounts: [],
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updatePersonalInfo(state, action: PayloadAction<Partial<PersonalInfo>>) {
      
      state.personalInfo = { ...state.personalInfo, ...action.payload };

    },
    updateFinancialGoal(state, action: PayloadAction<Partial<FinancialGoal>>) {
      state.financialGoal = { ...state.financialGoal, ...action.payload };
    },
    updateFamilyInfo(state, action: PayloadAction<Partial<FamilyInfo>>) {
      state.familyInfo = { ...state.familyInfo, ...action.payload };
    },
    // updateCurrentPortfolio(state, action: PayloadAction<Partial<CurrentPortfolioInfo>>) {
    //   const newInvestmentProperties = action.payload.investmentProperties || [];
    //   const existingInvestmentProperties = state.currentPortfolio.investmentProperties;

    //   // Filter out any new properties that already exist in the current state
    //   const uniqueNewProperties = newInvestmentProperties.filter(newProp => {
    //     return !existingInvestmentProperties.some(existingProp => 
    //       existingProp.address === newProp.address &&
    //       existingProp.currentestimatedvalue === newProp.currentestimatedvalue &&
    //       existingProp.initialdeposit === newProp.initialdeposit &&
    //       existingProp.netRentalyield === newProp.netRentalyield &&
    //       existingProp.currentloanamount === newProp.currentloanamount
    //     );
    //   });

    //   
    //   state.currentPortfolio.investmentProperties = [
    //     ...existingInvestmentProperties,
    //     ...uniqueNewProperties
    //   ];
    // },
    updateCurrentPortfolio(state, action: PayloadAction<Partial<CurrentPortfolioInfo>>) {
      
      state.currentPortfolio = { ...state.currentPortfolio, ...action.payload };
    },
    // updateIncome(state, action: PayloadAction<Partial<Income>>) {
    //   state.income = { ...state.income, ...action.payload };
    // },
    updateExpenses(state, action: PayloadAction<Partial<Expenses>>) {
      state.expenses = { ...state.expenses, ...action.payload };
    },
    updateEmploymentInfo(state, action: PayloadAction<Partial<EmploymentInfo>>) {
      state.employmentInfo = { ...state.employmentInfo, ...action.payload };
    },
    updatePropertyInfo(state, action: PayloadAction<Partial<PropertyInfo>>) {
      state.propertyInfo = { ...state.propertyInfo, ...action.payload };
    },
    updateLoanInfo(state, action: PayloadAction<Partial<LoanInfo>>) {
      state.loanInfo = { ...state.loanInfo, ...action.payload };
    },
    updateInvestmentStrategy(state, action: PayloadAction<Partial<InvestmentStrategy>>) {
      state.investmentStrategy = { ...state.investmentStrategy, ...action.payload };
    },
    updateBankingInfo(state, action: PayloadAction<Partial<BankingInfo>>) {
      
      state.bankingInfo = { ...state.bankingInfo, ...action.payload };
      
    },
    logout(state) {
      return initialState;
    },
  },
});

export const {
  updatePersonalInfo,
  updateFinancialGoal,
  updateFamilyInfo,
  updateCurrentPortfolio,
  updateBankingInfo,
  // updateIncome,
  updateExpenses,
  updateEmploymentInfo,
  updatePropertyInfo,
  updateLoanInfo,
  updateInvestmentStrategy,
  logout,
} = userSlice.actions;

export default userSlice.reducer;