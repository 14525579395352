import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const data = {
  labels: ['1yr', '2yr', '3yr', '4yr', '5yr'],
  datasets: [
    {
      label: 'Safety Buffer',
      data: [100000, 200000, 500000, 300000, 100000],
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false // Assuming you don't want a legend for a single dataset
    },
    title: {
      display: true,
      text: 'Safety Buffer',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const SafetyBufferChart = () => {
  return <Bar data={data} options={options} />;
};

export default SafetyBufferChart;
