import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../../../theme";

const ReportCard = ({
  title,
  price,
  color,
}: {
  title: string;
  price: number;
  color: string;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formattedPrice = title === "Cash Residue Post-Sale"
    && Math.round(parseFloat(price.toString().replace(/,/g, '')))


  console.log('formattedPrice :>> ', formattedPrice);
  return (
    <Box width="305px" m="0 30px">
      <Box display="flex" flexDirection="column" >
        <Box width="335px" p={2}>
          <Typography
            variant="h5"
            sx={{
              color: colors.grey[100],
              fontWeight: "bold",
              display: "flex",
              mb: 4,
              mr: 3,


              justifyContent: "center",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              m: "-18px 0px 0px 0px",
              color: { color },
              display: "flex",
              justifyContent: "center",
            }}
          >
            {title === "Cash Residue Post-Sale" ? (
              <>
                ${formattedPrice.toLocaleString()}

              </>
            ) : (
              <>
            ${price.toLocaleString()}
            </>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportCard;
