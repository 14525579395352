import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Typography, Box, Button } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  indexAxis: 'x',
  elements: {
    bar: { borderWidth: 2 },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Property Cashflow',
    },
  },
};

const data = {
  labels: ['Rental Income', 'Expenses', 'Net'],
  datasets: [
    {
      label: 'Cashflow',
      data: [20000, -15000, 5000], // Dummy data for Rental Income, Expenses, Net
      backgroundColor: ['rgba(53, 102, 255, 0.5)', 'rgba(255, 99, 132, 0.5)', 'rgba(75, 192, 192, 0.5)'],
      borderColor: ['rgba(53, 102, 255, 1)', 'rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)'],
      borderWidth: 1,
    },
  ],
};

const PropertyCashflowChart = () => (
  <Box>
    <Typography variant="h5" gutterBottom>
      Property Cashflow
    </Typography>
    <Bar data={data} options={{ ...options, indexAxis: "x" }} />
    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="caption">
        Not seeing what you would expect here? Fill out your <Button>Portfolio</Button>
      </Typography>
      <Button>Want more info?</Button>
    </Box>
  </Box>
);

export default PropertyCashflowChart;
