import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Dummy data for completion status
const completedSections = {
  financialGoal: true,
  familyMembers: false,
  currentPortfolio: true,
  income: true,
  expenses: false,
  strategy: true,
};

// Chart configuration
const barChartOptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false, // Set to false since we only have one dataset
    },
    title: {
      display: false, // Set to false if you don't want a title, or customize as needed
    },
  },
};

// Dummy data for charts
const chartData = {
  labels: Object.keys(completedSections),
  datasets: [
    {
      label: 'Completion Status',
      data: Object.values(completedSections).map((status) => (status ? 100 : 0)), // Changed to 100 for a full bar
      backgroundColor: Object.values(completedSections).map((status) =>
        status ? 'rgba(53, 162, 235, 0.5)' : 'rgba(255, 99, 132, 0.5)'
      ),
    },
  ],
};

const Scoreboard = () => {
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Hi Amar, Here's your scoreboard.
      </Typography>
      <Grid container spacing={3} alignItems="center">
        {Object.entries(completedSections).map(([section, isComplete]) => (
          <Grid item xs={12} sm={6} md={4} key={section}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              {isComplete ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <RadioButtonUncheckedIcon color="action" />
              )}
              <Typography variant="subtitle1">{section}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Completion Chart
        </Typography>
        <Paper elevation={3}>
          <Bar data={chartData} options={{ ...barChartOptions, indexAxis: "y" }} />
        </Paper>
      </Box>
    </Box>
  );
};

export default Scoreboard;
