import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LogoUpload from "../../components/LogoUpload";
import ColorSelection from "../../components/ColorSelection";
import AuthenticatedAPI from "../../.././auth_helper";
import { Auth } from "aws-amplify";
import { tokens } from "../../../theme";

// import PaymentSection from './PaymentSection';

interface BuyerAgentProfile {
  userid: string;
  name: string;
  email: string;
  logo_url: string;
  theme_color: string;
}

const BuyerAgentProfilePage: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [profile, setProfile] = useState<BuyerAgentProfile | null>(null);

  useEffect(() => {
    fetchProfile();
  }, [profile?.logo_url, profile?.theme_color, profile]);

  const fetchProfile = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log("usesdfr", user);
      const userId = user.attributes.sub; // Assuming the user ID is stored in the 'sub' attribute
      console.log("userId", userId);
      const name = user.attributes.given_name;
      const email = user.attributes.email;
      const response = await AuthenticatedAPI.request(
        "BuyersAgentsAPI",
        "get",
        `/agents/buyers-agent/${userId}`
      );
      console.log(response, "sdfd");
      if (response && response) {
        setProfile((prev) => (
          prev ? { ...prev, ...response[0], name, email } : { ...response[0], name, email }
        ));
        // setProfile({ ...response[0], name: name, email: email });
      } else {
        throw new Error("Failed to fetch buyer agent profile");
      }
    } catch (error) {
      console.error("Error fetching buyer agent profile:", error);
    }
  };


  const handleLogoUpdate = (logoUrl: string) => {
    setProfile((prev) => (prev ? { ...prev, logoUrl } : null));
  };

  const handleColorUpdate = (themeColor: string) => {
    setProfile((prev) => (prev ? { ...prev, theme_color: themeColor } : null));
  };



  if (!profile) {
    return <Typography>Loading...</Typography>;
  }
  console.log("profile", profile);
  return (
    <Container>
      <Box
        className="profileSection my-12 xl:my-20"
        p={isMobile ? 5 : 7}
        sx={{
          backgroundColor:
            theme.palette.mode === "light" ? "#f2f0f0" : "#1F2A40",
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <Box className="profile_inner">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: "36px",
                  fontWeight: "700",
                  color: colors.grey[100],
                }}
                mb={3}
                textAlign={"center"}
              >
                Buyer Agent <span className="text-[#00bda2]">Profile</span>
              </Typography>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: colors.grey[100],
                }}
                mb={0}
              >
                Basic Information
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography mb={2}>
                <strong className="text-base text-[#00bda2]">Name</strong>
                <br />
                <span className="text-2xl">{profile.name}</span>
              </Typography>
              <Typography mb={2}>
                <strong className="text-base text-[#00bda2]">Email</strong>
                <br />
                <span className="text-2xl">{profile.email}</span>
              </Typography>

              <Typography mb={1}>
                <strong className="text-base text-[#00bda2]">Logo</strong>
              </Typography>
              <LogoUpload
                agentId={profile.userid}
                onLogoUpdate={handleLogoUpdate}
              />
              {profile.logo_url && (
                <img
                src={`${profile.logo_url}?${new Date().getTime()}`}
                  alt="Agent Logo"
                  className="profile_img"
                  style={{ marginTop: "20px" }}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography mb={2}>
                <strong className="text-base text-[#00bda2]">
                  Theme Color
                </strong>
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <ColorSelection
                    agentId={profile.userid}
                    onColorUpdate={handleColorUpdate}
                  />
                </Grid>
                <Grid item xs={5}>
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: profile.theme_color,
                      borderRadius: "10px",
                      // border: "2px solid #ffffff",
                      boxShadow: "0px 3px 6px -3px rgb(0 0 0 / 20%)",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography variant="h6">Payment Information</Typography>
              <PaymentSection agentId={profile.id} onPaymentUpdate={handlePaymentUpdate} />
              </Grid> */}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default BuyerAgentProfilePage;
