import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useTheme, Box, Typography } from '@mui/material';

interface PropertyyieldsProps {
  netRentalYields: number[];
  propertyname: string[];
}

const Propertyyields: React.FC<PropertyyieldsProps> = ({ netRentalYields, propertyname }) => {
  const theme = useTheme();
  const barColors = [
    theme.palette.mode === 'dark' ? '#BB86FC' : '#BB86FC',
    theme.palette.mode === 'dark' ? '#9BEC00' : '#9BEC00',
    theme.palette.mode === 'dark' ? '#3700B3' : '#3700B3',
    theme.palette.mode === 'dark' ? '#3700B3' : '#3FA2F6',
    theme.palette.mode === 'dark' ? '#C738BD' : '#C738BD',
    theme.palette.mode === 'dark' ? '#9BEC00' : '#9BEC00',
  ];

  const [series, setSeries] = useState([{ data: [] as number[] }]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (netRentalYields.length > 0) {
      const formattedYields = netRentalYields.map(yieldValue => parseFloat(yieldValue.toFixed(2)));
      setSeries([{ data: formattedYields }]);
      setLoading(false);
      setError(false);
    } else {
      setLoading(false);
      setError(true);
    }
  }, [netRentalYields]);

  const formatNumber = (value: number): string => {
    return value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value.toString();
  };

  const options: ApexOptions = {
    chart: {
      height: 280,
      type: 'bar',
      toolbar: { show: false },
      zoom: { enabled: false },
      selection: { enabled: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: { enabled: true, delay: 150 },
        dynamicAnimation: { enabled: true, speed: 350 },
      },
    },
    colors: barColors,
    plotOptions: {
      bar: {
        columnWidth: '40px',
        distributed: true,
        dataLabels: { position: 'top' },
      },
    },
    labels: propertyname,
    dataLabels: {
      enabled: true,
      formatter: (value: number) => value.toFixed(0),
      offsetY: -20,
      style: { fontSize: '12px', colors: barColors },
    },
    legend: {
      show: true,
      customLegendItems: propertyname,
      markers: { fillColors: barColors },
      labels: {
        colors: theme.palette.mode === 'dark' ? '#FFFFFF' : '#333333',
      },
    },
    xaxis: {
      labels: {
        show: false,
        style: { colors: barColors, fontSize: '14px' },
      },
      title: {
        text: 'Properties',
        style: {
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333',
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: formatNumber,
        style: {
          colors: theme.palette.mode === 'dark' ? '#ffffff' : '#333333',
        },
      },
      title: {
        text: 'Yields in %',
        style: {
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333',
          fontSize: '12px',
        },
      },
    },
    grid: { show: false },
    tooltip: {
      enabled: true,
      theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
      style: { fontSize: '12px' },
      y: { formatter: formatNumber },
    },
  };

  return (
    <Box
      id="chart-property-growth"
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#ffffff',
        padding: '0px',
        borderRadius: '10px',
        display: loading ? 'flex' : 'block',
        justifyContent: loading ? 'center' : 'initial',
        alignItems: loading ? 'center' : 'initial',
        height: "300px",
      }}
    >
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
          textAlign="center"
        >
          <Typography variant="h6">No Data Found!</Typography>
        </Box>
      ) : (
        <ReactApexChart options={options} series={series} type="bar" height={300} />
      )}
    </Box>
  );
};

export default Propertyyields;
