import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@mui/material';
import { useSelector } from "react-redux";
import { tokens } from '../../../../theme';

const BarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const BarChartPreTaxIncome = useSelector(
    (state) => state.investedProperties.barChartPreTaxPassiveIncome
  );

  // Define your color array
  const barColors = [
    '#24E0DC',
    "#FFB22C",
    '#DC0083',
    '#06D001',
    '#4535C1',
    '#9BEC00'
  ];

  // Function to determine color based on index
  const colorScale = (bar) => {
    // Get the index of the bar and return a color from the barColors array
    const index = bar.index;
    return barColors[index % barColors.length]; // Use modulo to cycle through colors
  };

  return (
    <ResponsiveBar
      data={BarChartPreTaxIncome}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.greenAccent[100],
            },
          },
          legend: {
            text: {
              fill: colors.greenAccent[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.greenAccent[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.greenAccent[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.greenAccent[100],
          },
        },
      }}
      keys={["pre-tax income"]}
      indexBy="property"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={colorScale} // Apply the color scale function
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "property",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "pre-tax income",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      // legends={[
      //   {
      //     dataFrom: "keys",
      //     anchor: "bottom-right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 120,
      //     translateY: 0,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 20,
      //     itemDirection: "left-to-right",
      //     itemOpacity: 0.85,
      //     symbolSize: 20,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemOpacity: 1,
      //         },
      //       },
      //     ],
      //   },
      // ]}
      role="application"
      barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in property: ${e.indexValue}`}
    />
  );
};

export default BarChart;
