import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import AuthenticatedAPI from '../../../auth_helper';
import TermsModal from '.';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AxiosError } from 'axios';

interface TermsModalWrapperProps {
  children: ReactNode;
}

const TermsModalWrapper: React.FC<TermsModalWrapperProps> = ({ children }) => {
  const [userAgreedTerms, setUserAgreedTerms] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const handleClose = () => null;

  const createUserProfile = useCallback(async (): Promise<void> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userAttributes = await Auth.userAttributes(user);
      const id =
        userAttributes.find((attr) => attr.Name === "sub")?.Value ?? "";
      const email =
        userAttributes.find((attr) => attr.Name === "email")?.Value ?? "";
      const phone_number =
        userAttributes.find((attr) => attr.Name === "phone_number")?.Value ??
        "";
      const first_name =
        userAttributes.find((attr) => attr.Name === "given_name")?.Value ??
        "";
      const last_name =
        userAttributes.find((attr) => attr.Name === "family_name")?.Value ??
        "";
      const newProfile = {
       
            id: id,
            first_name:first_name,
            last_name:last_name,
            email: email,
            phone_number: phone_number,
            buisnessName: '',
            buisnessIdNo: '',
            buisnessIdNoType: '',
            buisnessAddress: '',
            verificationStatus: '',
            verificationDate: null,
            agreed_terms: true,
        
          // financialGoal: {
          //   year_of_retirement: 0,
          //   number_of_properties: 0,
          //   isFirstIp: false,
          //   needAssetProtection: false,
          //   equity: 0,
          //   passive_income: 0,
          //   targetRentalYield: 0,
          // },
          // familyInfo: {
          //   earningMembers: [],
          //   dependentKids: [],
          // },
          // currentPortfolio: {
          //   investmentProperties: []
          // },
          // expenses: {
          //   existingInvestmentLoanAmount: 0,
          //   personalLoansAmount: 0,
          //   creditCardLimit: 0,
          //   livingExpenses: 0,
          //   leasePayments: 0,
          //   miscExpenses: 0,
          // },
          // employmentInfo: {
          //   employmentType: 'PAYG',
          //   selfEmployedIncome: 0,
          //   netMonthlyIncomeMA: 0,
          //   netMonthlyIncomeFA: 0,
          //   dividends: 0,
          //   overtime: 0,
          //   otherIncome: 0,
          //   centreLink: 0,
          //   totalInvestedPropertyValue: 0,
          // },
          // propertyInfo: {
          //   properties: [],
          // },
          // loanInfo: {
          //   loanFinance: {}
          // },
        
      };
console.log(newProfile,'sjdksjdk')
      await AuthenticatedAPI.request(
        'InvestmentAPI',
        'post',
        `/api/users`,
        { body: newProfile }
      );
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Error updating user profile');

    } finally {
      setLoading(false);
      setUserAgreedTerms(true);
    }
  }, []);

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        try {
          const response = await AuthenticatedAPI.request(
            "InvestmentAPI",
            "get",
            `/api/users/${user.username}`
          );
          console.log('dfjkdfj',response)
          if (response && response.data && response.data.personalInfo && response.data.personalInfo.personalInfo) {
            setUserAgreedTerms(response.data.personalInfo.personalInfo[0].agreed_terms);
          } else {
            setError("Unexpected response structure from API");
          }
        } catch (error) {
          const axiosError = error as AxiosError;
          console.log('error',error)
          if (axiosError.response) {
            if (axiosError.response.status === 404 ) {
              // await createUserProfile(user.username);
              console.log('---------------------------->')
              setUserAgreedTerms(false);
            } else {
              console.log(`Error fetching user profile: ${axiosError.response.status}`);
            }
          } else {
            setError("Error fetching user profile");
          }
        }
      } catch (error) {
        console.log("Error in authentication or profile creation:", error);
        setError("Error in authentication or profile creation");
      } finally {
        setLoading(false);
      }
    };

    getUserProfile();
  }, []);

  const handleAgree = async () => {
    setLoading(true);
    try {
      const res = await Auth.currentAuthenticatedUser();
      await AuthenticatedAPI.request(
        'InvestmentAPI',
        'put',
        `/api/profile/update-agreed-terms`,
        {
          body: {
            userId: res.username,
            agreedTerms: true,
          },
        }
      );
      setUserAgreedTerms(true);
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Error updating user profile');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
  //       <Typography color="error">{error}</Typography>
  //     </Box>
  //   );
  // }

  if (userAgreedTerms === false) {
    return (
      <TermsModal open={true} onClose={handleClose} onAgree={createUserProfile} />
    );
  }

  return <>{children}</>;
};

export default TermsModalWrapper;