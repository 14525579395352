import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTheme, Box, Typography } from "@mui/material";

interface BorrowableEquityProps {
  equity: string;
  loanamount: string;
  totalLoan: number | null;
  totalEquity: number | null;
}

const LoanToValueRatioChart: React.FC<BorrowableEquityProps> = ({ equity, loanamount, totalLoan, totalEquity }) => {
  const theme = useTheme();

  const equityValue = parseFloat(equity);
  const loanAmountValue = parseFloat(loanamount);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  // Prepare series data directly from props
  const series = [loanAmountValue, equityValue];

  const barColors = ['#6870fa', '#4cceac'];

  useEffect(() => {
    if (equity.length > 0 && loanamount.length > 0 && totalLoan && totalEquity) {
      setLoading(false);
      setNoData(false);
    } else if (equity.length === 0 || loanamount.length === 0 || !totalLoan || !totalEquity) {
      setLoading(false);
      setNoData(true);
    }
  }, [equity, loanamount, totalLoan, totalEquity]);

  const options: ApexOptions = {
    chart: {
      type: 'pie',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
    },
    labels: ["Debt", "Equity"],
    legend: {
      show: true,
      customLegendItems: ["Debt", "Equity"],
      markers: {
        fillColors: barColors,
      },
      labels: {
        colors: theme.palette.mode === 'dark' ? '#FFFFFF' : '#333333',
      },
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    colors: barColors,
    fill: {
      opacity: 1,
    },
    title: {
      style: {
        fontSize: '16px',
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value, { seriesIndex }) => {
          const total = seriesIndex === 0 ? totalLoan : totalEquity;
          return `${total?.toFixed(0)}`;
        },
      },
    },
  };

  return (
    <Box
      id="chart-property-growth"
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#ffffff',
        padding: '0px',
        borderRadius: '10px',
        display: `${loading ? 'flex' : 'block'}`,
        justifyContent: `${loading ? 'center' : 'flex-start'}`,
        alignItems: `${loading ? 'center' : 'flex-start'}`,
      }}
    >
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : noData ? (
        <Typography variant="h6">No data found!</Typography>
      ) : (
        <Chart options={options} series={series} type="donut" height={335} width={`100%`} />
      )}
    </Box>
  );
};

export default LoanToValueRatioChart;
