import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";

const A4_WIDTH = 210; // mm
const A4_HEIGHT = 297; // mm
const PAGE_MARGIN = 10; // mm

interface InvestmentProperty {
    address: string;
    currentestimatedvalue: number;
    netrentalyield: number;
    currentloanamount: number;
    initialpurchaseprice: number;
    yearofpurchase: number;
    weeklyrent: number;

}

export async function GeneratePdf(profileData: any, auth: any, portfolio: any) {
    console.log('auth :>> ', auth);
    const doc = new jsPDF({ format: [A4_WIDTH, A4_HEIGHT] });
    console.log('profileData123 :>> ', profileData);

    const totalValue = portfolio?.reduce((accumulator: any, x: any) => {
        return accumulator + parseFloat(x.currentEstimatedValue);
    }, 0) || 0;


    const totalYield = portfolio?.reduce((accumulator: any, x: any) => {
        return accumulator + parseInt(x.netRentalYield)
    }, 0) || 0;
    const averageYield = portfolio?.length ? totalYield / portfolio.length : 0;

    const investmentProperties =
        profileData?.currentPortfolio?.investmentProperties || [];
    // Borrowable Equity
    const borrowableEquities = investmentProperties.map((property: any) => {
        const currentEstimatedValue =
            parseFloat(property.currentestimatedvalue) || 0;
        const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
        return currentEstimatedValue * 0.8 - currentLoanAmount;
    });
    const toatlEquity = borrowableEquities.reduce(
        (total: any, value: any) => total + value,
        0
    );

    let startY = PAGE_MARGIN; // Initial Y position for content
    const pageHeight = A4_HEIGHT - 2 * PAGE_MARGIN;

    // Function to add content and handle page breaks
    function addContent(contentHeight: number, contentCallback: () => void) {
        // Check if the content fits on the current page
        if (startY + contentHeight > pageHeight) {
            // Add a new page if necessary
            addFooter(doc, (doc as any).internal.getNumberOfPages(), profileData);
            doc.addPage();
            startY = PAGE_MARGIN; // Reset Y position for new page
        }
        contentCallback(); // Add the content
        startY += contentHeight; // Adjust Y position after content
    }

    // Add header
    const headerHeight = 20;
    addContent(headerHeight, () => {
        addHeader(doc, profileData, auth);
    });


    // Add a section similar to the provided image
    const sectionHeight = 60; // Adjust the height based on content
    addContent(sectionHeight, () => {
        // Draw horizontal line
        doc.setLineWidth(0.2);


        // Add "Estimated Value" text
        doc.setFontSize(16);
        doc.text("Portfolio Summary", PAGE_MARGIN, startY + 25);

        // Draw another horizontal line below the title
        doc.line(PAGE_MARGIN, startY + 27, A4_WIDTH - PAGE_MARGIN, startY + 27);

        // Add content similar to the image
        doc.setFontSize(12);

        // First column: Estimated Value
        doc.text("Total Properties:", PAGE_MARGIN, startY + 35);
        doc.setFontSize(20);
        doc.text(`${profileData?.currentPortfolio?.investmentProperties?.length}`, PAGE_MARGIN, startY + 50);

        // Second column: Estimated Value Range
        doc.setFontSize(12);
        doc.text("Total Value:", PAGE_MARGIN + 50, startY + 35);
        doc.setFontSize(20);
        doc.text(`$${totalValue.toLocaleString()}`, PAGE_MARGIN + 50, startY + 50);

        // Third column: Estimated Value Confidence
        doc.setFontSize(12);
        doc.text("Average Yield:", PAGE_MARGIN + 90, startY + 35);
        doc.setFontSize(20);
        doc.text(`${averageYield}%`, PAGE_MARGIN + 90, startY + 50);

        // Fourth column: Borrowable Equity
        doc.setFontSize(12);
        doc.text("Borrowable Equity:", PAGE_MARGIN + 130, startY + 35);
        doc.setFontSize(20);
        doc.text(`$${toatlEquity.toLocaleString()}`, PAGE_MARGIN + 130, startY + 50);

        doc.line(PAGE_MARGIN, startY + 55, A4_WIDTH - PAGE_MARGIN, startY + 55);

    });
    const fundingsectionHeight = 20; // Adjust the height based on content
    addContent(fundingsectionHeight, () => {
        // Draw horizontal line
        doc.setLineWidth(0.2);


        // Add "Estimated Value" text
        doc.setFontSize(16);
        doc.text("Financial Goals", PAGE_MARGIN, startY + 10);

        // Draw another horizontal line below the title
        doc.line(PAGE_MARGIN, startY + 12, A4_WIDTH - PAGE_MARGIN, startY + 12);

        // Add content similar to the image
        doc.setFontSize(12);

        // First column: Estimated Value
        doc.text("Retirement Year", PAGE_MARGIN, startY + 17);
        doc.setFontSize(14);
        doc.text(`${profileData.financialGoal?.financialGoal[0]?.year_of_retirement}`, PAGE_MARGIN, startY + 25);


        // Second column: Estimated Value Range
        doc.setFontSize(12);
        doc.text("Target Properties", PAGE_MARGIN + 35, startY + 17);
        doc.setFontSize(14);
        doc.text(`${profileData.financialGoal?.financialGoal[0]?.number_of_properties}`, PAGE_MARGIN + 35, startY + 25);

        // Third column: Estimated Value Confidence
        doc.setFontSize(12);
        doc.text("Equity Goal", PAGE_MARGIN + 75, startY + 17);
        doc.setFontSize(14);
        doc.text(`$${profileData.financialGoal?.financialGoal[0]?.equity.toLocaleString()}`, PAGE_MARGIN + 75, startY + 25);

        // Fourth column: Borrowable Equity
        doc.setFontSize(12);
        doc.text("Passive Income Goal", PAGE_MARGIN + 110, startY + 17);
        doc.setFontSize(14);
        doc.text(`$${profileData.financialGoal?.financialGoal[0]?.passive_income.toLocaleString()}`, PAGE_MARGIN + 110, startY + 25);
        // // Fourth column: Borrowable Equity
        doc.setFontSize(12);
        doc.text("Target Rental Yield", PAGE_MARGIN + 155, startY + 17);
        doc.setFontSize(14);
        doc.text(`${profileData.financialGoal?.financialGoal[0]?.targetrentalyield}%`, PAGE_MARGIN + 155, startY + 25);


        doc.line(PAGE_MARGIN, startY + 30, A4_WIDTH - PAGE_MARGIN, startY + 30);

    });
    // Add property data with autoTable to handle pagination

    doc.setFontSize(15);
    doc.text(`Property Details`, PAGE_MARGIN, startY + 23);
    autoTable(doc, {
        startY: startY + 27,

        head: [['Address', 'Value', 'Yield', 'Loan Amount', 'Purchase Price', 'Year', 'Weekly Rent']],

        body: investmentProperties.map((item: InvestmentProperty) => [
            item.address,
            `$${Number(item.currentestimatedvalue).toLocaleString()}`,
            `${Number(item.netrentalyield).toFixed(2)}%`,
            `$${Number(item.currentloanamount).toLocaleString()}`,
            `$${Number(item.initialpurchaseprice).toLocaleString()}`,
            item.yearofpurchase.toString(),
            `$${Number(item.weeklyrent).toLocaleString()}`
        ]),


        margin: { top: 10, bottom: 10 },
        pageBreak: 'auto', // Handle automatic page breaks
        didDrawPage: function (data) {
            // Add footer on every page
            addFooter(doc, (doc as any).internal.getNumberOfPages(), profileData);
        },
        theme: 'striped',
        headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            fontSize: 12,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'middle',
        },
        alternateRowStyles: {
            fillColor: [240, 240, 240],
        },
        styles: {
            minCellHeight: 10,
            overflow: 'linebreak',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
        },

    });


    startY = (doc as any).autoTable.previous.finalY + 10; // Update startY based on the final position of the table

    // Generate charts and add them to the PDF
    try {
        const pieChartImage = await GenerateChart('pie', investmentProperties);
        console.log('investmentProperties :>> ', investmentProperties);


        const portFolioGrowthChart = await GenerateChart('bar', investmentProperties);
        const portfolioYieldsbarChart = await GenerateChart('bar1', investmentProperties);
        const portfolioEquitybarChart = await GenerateChart('bar2', investmentProperties);

        // Determine chart heights
        const chartHeight = 60; // Height of each chart

        // Add pie chart

        addContent(chartHeight, () => {

            doc.setFont("Helvetica", "bold");
            doc.setFontSize(10);
            doc.text('Portfolio Loan to Value Ratio', PAGE_MARGIN, startY - 5);
            doc.addImage(pieChartImage, 'PNG', PAGE_MARGIN, startY, 80, 60); // X, Y, width, height
            doc.text('Portfolio Growth', PAGE_MARGIN + 85, startY - 5);
            doc.addImage(portFolioGrowthChart, 'PNG', PAGE_MARGIN + 85, startY, 90, 60); // X, Y, width, height

            // addContent(chartHeight, () => {
            //     doc.addImage(pieChartImage, 'PNG', PAGE_MARGIN, startY, 90, 60); // X, Y, width, height
            // });
        })
        addContent(chartHeight, () => {

            doc.setFont("Helvetica", "bold");
            doc.setFontSize(10);

            startY += 10;
            doc.text('Portfolio Yields', PAGE_MARGIN, startY - 5);
            doc.addImage(portfolioYieldsbarChart, 'PNG', PAGE_MARGIN , startY,90, 60); // X, Y, width, height
            startY += 10;
            doc.text('Portfolio Equity', PAGE_MARGIN + 85, startY - 5);
            doc.addImage(portfolioEquitybarChart, 'PNG', PAGE_MARGIN + 85, startY , 100, 60); // X, Y, width, height


            // addContent(chartHeight, () => {
            //     doc.addImage(pieChartImage, 'PNG', PAGE_MARGIN, startY, 90, 60); // X, Y, width, height
            // });
        })


    } catch (error) {
        console.error('Error generating charts:', error);
    }




    startY = (doc as any).autoTable.previous.finalY + 10;


    startY = (doc as any).autoTable.previous.finalY + 10;
    addFooter(doc, (doc as any).internal.getNumberOfPages(), profileData);
    // Save the PDF
    doc.save('Investart Report.pdf');
}

async function GenerateChart(type: 'pie' | 'bar' | 'bar1' | 'bar2', investmentProperties: any): Promise<string> {
   
    const data = investmentProperties.map(
        (property: any) => property.currentestimatedvalue - property.initialpurchaseprice || 0
    );

    const borrowableEquities = investmentProperties.map((property: any) => {
        const currentEstimatedValue =
            parseFloat(property.currentestimatedvalue) || 0;
        const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
        return currentEstimatedValue * 0.8 - currentLoanAmount;
    });
    const totalLoan = investmentProperties.map(
        (loan: any) => loan.currentloanamount || 0
    );

    const totalEquity = borrowableEquities.reduce(
        (total: number, value: number) => total + value,
        0
    );

    const totalLoanAmount = totalLoan.reduce(
        (total: number, value: number) => total + Number(value),
        0
    );
    const total = totalEquity + totalLoanAmount;
    const Debtpercente = ((totalLoanAmount / total) * 100).toFixed(2);
    const formattedYields = data.map((value: number) => parseFloat(value.toFixed(2)));
    const Equitypercente = ((totalEquity / total) * 100).toFixed(2);
    const equityValue = parseFloat(Equitypercente);
    const loanAmountValue = parseFloat(Debtpercente);
    
    // Prepare series data directly from props
    const LTAseries = [loanAmountValue, equityValue];

    // If no valid data, return early
    if (!formattedYields.length || formattedYields.every((value: any) => isNaN(value))) {
        console.error("No valid data available for chart generation.");
        return Promise.reject("No valid data available.");
    }
    const netRentalYields = investmentProperties.map(
        (property: any) => (property.weeklyrent * 52) / property.initialpurchaseprice * 100
      );
      const Yields = netRentalYields &&netRentalYields.map((yieldValue: number) => parseFloat(yieldValue.toFixed(2)));
      const borrowdata = borrowableEquities.map((value: number) => parseFloat(value.toFixed(0)))
      console.log('borrowdata :>> ', borrowdata);
   console.log('Yields :>> ', Yields);
    const chartOptions: any = {
        pie: {
            series: LTAseries,
            chart: { type: 'pie', width: 600, height: 400 },
            plotOptions: {
                pie: {
                    donut: { size: '50%' },
                    dataLabels: { offset: -10 }
                }
            },
            labels: ["Debt", "Equity"],
            fill: { type: 'solid' },
        },
        bar: {
            chart: { type: 'bar', width: 600, height: 400 },
            plotOptions: {
                bar: {
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: investmentProperties.map((property: any) =>
                    `${property.address.split(' ').slice(0, 2).join(' ')}`
                ),
            },
            series: [{
                name: 'Portfolio Growth',
                data: formattedYields,

            }]
        },
        bar1: {
            chart: { type: 'bar', width: 600, height: 400 },
            plotOptions: {
                bar: {
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: investmentProperties.map((property: any) =>
                    `${property.address.split(' ').slice(0, 2).join(' ')}`
                ),
            },
            series: [{
                name: 'Portfolio Yields',
                data: Yields,

            }]
        },
        bar2: {
            chart: { type: 'bar', width: 600, height: 400 },
            plotOptions: {
                bar: {
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: investmentProperties.map((property: any) =>
                    `${property.address.split(' ').slice(0, 2).join(' ')}`
                ),
            },
            series: [{
                name: 'Portfolio Borrowable Equity',
                data: borrowdata,

            }]
        }
    };

    try {
        // Create a promise that resolves after a delay
        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        // Render the chart on a canvas
        const chartContainer = document.createElement('div');
        chartContainer.style.position = 'absolute';
        chartContainer.style.top = '-10000px'; // Position off-screen to avoid UI disruption
        document.body.appendChild(chartContainer);

        const chart = new ApexCharts(chartContainer, chartOptions[type]);
        await chart.render();

        // Wait for 5 seconds to ensure the chart is fully rendered
        await delay(3000);

        const base64Image = await chart.dataURI();

        // Clean up
        chart.destroy();
        document.body.removeChild(chartContainer);
        return (base64Image as { imgURI: string; }).imgURI;
    } catch (error) {
        console.error("Error generating chart image:", error);
        return Promise.reject(error);
    }
}








function addHeader(doc: jsPDF, profileData: any, auth: any) {

    const headerMargin = 10;
    const headerWidth = A4_WIDTH - 2 * headerMargin;


    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Investar Report', headerMargin, headerMargin + 10);

    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    doc.text(`Name: ${profileData.personalInfo.personalInfo[0]?.first_name} ${profileData.personalInfo.personalInfo[0]?.last_name} | Investar Score: ${profileData.investarScore}`, headerMargin, headerMargin + 20);







    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(100);

    const today = new Date();
    const dateStr = today.getDate() + ' ' + getMonthName(today.getMonth()) + ' ' + today.getFullYear();
    doc.text(`Prepared on ${dateStr} for ${profileData.personalInfo.personalInfo[0]?.first_name} ${profileData.personalInfo.personalInfo[0]?.last_name}`, headerMargin, headerMargin + 30);
    doc.text(`Prepared by ${auth?.first_name} | ${auth?.email} | ${auth?.phone_number}`, headerMargin, headerMargin + 35);
    // doc.text('Investor Name: John Doe', headerMargin, headerMargin + 30,{ align: 'right' });
    // doc.text('Investor Email: john.doe@example.com', headerMargin, headerMargin + 35,{ align: 'right' });
    // doc.text('Prepared by Amol Khuntale | amol@askfinancials.com.au | 0433944055', headerMargin, headerMargin + 35);
    // doc.text('Investor Name: John Doe | Investor Email: john.doe@example.com', headerMargin, headerMargin + 40);

}

function getMonthName(month: number) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[month];

}

function addFooter(doc: jsPDF, pageNumber: number, profileData: any) {
    const footerText1 = `Investar Report of ${profileData?.personalInfo?.personalInfo[0]?.first_name} ${profileData?.personalInfo?.personalInfo[0]?.last_name}`
    const footerText = `© Copyright 2024 | Investar Ltd and its licensors are the sole and exclusive owners of all rights, title and interest (including intellectual property rights) subsisting in this publication including any data, analytics, statistics and other information.`;

    doc.setFontSize(8);
    doc.setTextColor(150);
    const footerMargin = 10; // Margin from the bottom

    doc.setPage(pageNumber);
    doc.text(`Page ${pageNumber}`, footerMargin, A4_HEIGHT - footerMargin - 5);
    doc.text(`${footerText1}`, A4_WIDTH - footerMargin, A4_HEIGHT - footerMargin - 5, { align: 'right' });
    doc.text(footerText, footerMargin, A4_HEIGHT - footerMargin, { maxWidth: A4_WIDTH - 2 * footerMargin });
}