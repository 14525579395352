import React, { useState } from 'react';
import { Box, Modal, Typography, Checkbox, Button, Link, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';

const StyledModal = styled(Modal)({
  backdropFilter: 'blur(10px)', // Blurry background
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const style = {
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px', // Rounded corners
  textAlign: 'center',
};

const linkStyle = {
  color: 'rgb(76, 206, 172)',
  textDecoration: 'underline',
  fontWeight: 'bold',
  display: 'block',
  mt: 2,
};

const buttonStyle = {
  mt: 3,
  borderRadius: '20px',
  fontWeight: 'bold',
  textTransform: 'none' as const,
  backgroundColor: 'rgb(76, 206, 172)',
  '&:hover': {
    backgroundColor: 'rgb(60, 165, 138)',
  },
};

const checkboxStyle = {
  color: 'rgb(76, 206, 172)',
  '&.Mui-checked': {
    color: 'rgb(76, 206, 172)',
  },
};

interface TermsModalProps {
  open: boolean;
  onClose: () => void;
  onAgree: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ open, onClose, onAgree }) => {
  const [checked, setChecked] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleAgree = () => {
    if (checked) {
      onAgree();
      onClose();
    } else {
      toast.error('You must agree to the terms and conditions in order to proceed!');
    }
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography id="modal-modal-description" variant="body1" gutterBottom>
          Please read and agree to the terms and conditions by checking the box below.
        </Typography>
        <Link href="/terms" target="_blank" rel="noopener" sx={linkStyle}>
          Read the full terms and conditions
        </Link>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              sx={checkboxStyle}
            />
          }
          label="I agree to the terms and conditions"
          sx={{ mt: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleAgree} sx={buttonStyle}>
          Agree
        </Button>
      </Box>
    </StyledModal>
  );
};

export default TermsModal;
