import React, { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Card,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  ConstructionOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import awsExports from "../../../aws-exports";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";

Amplify.configure(awsExports);

const formFields = {
  signUp: {
    email: {
      order: 1,
      isRequired: true,
    },
    password: {
      order: 2,
      isRequired: true,
    },
    confirm_password: {
      order: 3,
      isRequired: true,
    },
    "custom:company_name": {
      order: 4,
      label: "Company Name",
      placeholder: "Enter your company name",
      isRequired: true,
    },
    "custom:primary_contact_name": {
      order: 5,
      label: "Primary Contact Name",
      placeholder: "Enter primary contact name",
      isRequired: true,
    },
    phone_number: {
      order: 6,
      isRequired: true,
      dialCode:"+61"
    },
    "custom:user_type": {
      order: 7,
      value: "InvestarPartner",
    },
  },
};

const components = {
  
  Header() {
    return (
      <Box textAlign="center" padding="1rem">
        <img
          alt="Investar logo"
          src="/logo.png"
          style={{ width: "auto", height: "80px", margin: "0 auto 15px" }}
        />
      </Box>
    );
  },
  SignUp: {
    Header() {
      return (
        <Typography
          variant="h1"
          sx={{
            fontSize: "36px",
            fontWeight: "700",
            // color: colors.grey[100],
          }}
          pt={4}
          textAlign={"center"}
        >
          Create <span className="text-[#00bda2]">Account</span>
        </Typography>
      );
    },
  },
};

const tabsStyle = `
  .amplify-tabs {
    display: none;
  }
`;
function BuyersAgentSignup() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const { authStatus } = useAuthenticator();
  useEffect(() => {
    if (authStatus === "authenticated") {
      navigate("/ba-dashboard");
    }
  }, [authStatus, navigate]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#fcfcfc",
        padding: "50px 0px",
      }}
    >
      <Container maxWidth="sm" className="custom_container">
        <style>{tabsStyle}</style>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundColor: "#fcfcfc",
            padding: "25px 0px 50px",
          }}
        >
          <Card
            style={{
              boxShadow: "none",
            }}
            sx={{
              padding: "0rem",
              borderRadius: "15px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              background: "transparent",
              width: "100%",
            }}
          >
            <Authenticator
              formFields={formFields}
              components={components}
              initialState="signUp"
              // hideSignIn={true}
              services={{
                async validateCustomSignUp(formData) {
                  const errors: { [key: string]: string } = {};
                  if (!formData["custom:company_name"]) {
                    errors["custom:company_name"] = "Company Name is required";
                  }
                  if (!formData["custom:primary_contact_name"]) {
                    errors["custom:primary_contact_name"] =
                      "Primary Contact Name is required";
                  }
                  return errors;
                },
              }}
            >
              {({ signOut, user }) => (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Welcome, {user?.attributes?.email}
                  </Typography>
                  <Button
                    onClick={signOut}
                    variant="contained"
                    color="secondary"
                  >
                    Sign out
                  </Button>
                </Box>
              )}
            </Authenticator>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default BuyersAgentSignup;
