import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Card,
} from "@mui/material";
import { IOScenarioData, PIScenarioData } from "../../../../types/CashFlow";
import { styled, useTheme } from "@mui/material/styles";

import {
  updateAreaChartFlow,
  updateCashflowChart,
  updateGrowthChart,
} from "../../../../features/cashFlow";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../../store";

import { tokens } from "../../../../theme";
import AreaGrowthChart from "../../charts/GrowthChart";
import AreaChartCash from "../../charts/AreaChart";
import AreaCashflowChart from "../../charts/CashflowChart";
import { formatNumberWithCommas } from "../../../../hooks/properties/properties";
interface CashFlowScenarioProps {
  title: string;
  scenario: PIScenarioData | IOScenarioData | null;
  loanType: "principal-interest" | "interest-only";
}
interface AreaChartData {
  beforeTaxCashFlow: string[];
  repaymentAmount: string | null;
  afterTaxCashFlow: string[];
  year: number[];
}

type FormattedData = {
  year: number;
  beforeTaxCashFlow: number;
  repaymentAmount: number;
  afterTaxCashFlow: number;
};
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   fontWeight: "bold",
//   backgroundColor: theme.palette.primary.dark,
//   color: theme.palette.common.white,
//   padding: theme.spacing(1.5),
// }));

// const SectionHeadingCell = styled(TableCell)(({ theme }) => ({
//   fontWeight: "bold",
//   backgroundColor: theme.palette.primary.light,
//   padding: theme.spacing(1),
//   "& .MuiTypography-root": {
//     fontWeight: "bold",
//   },
// }));
interface EquityData {
  equity: number;
  year: number;
}
interface GrowthData {
  year: number;
  beforeTaxCashFlow: number;
}

// const DataCell = styled(TableCell)(({ theme }) => ({
//   padding: theme.spacing(1),
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

const CashFlowScenario: React.FC<CashFlowScenarioProps> = ({
  title,
  scenario,
  loanType,
}) => {
  console.log('scenario :>> ', scenario);
  const dispatch: AppDispatch = useDispatch();
  const CashChartFlow = useSelector(
    (state: RootState) => state.cashFlowAnalysis.CashflowChart
  );
  const GrowthChartFlow = useSelector(
    (state: RootState) => state.cashFlowAnalysis.GrowthChart
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!scenario) {
    return (
      <Box mb={4}>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Typography>No data available for this scenario.</Typography>
      </Box>
    );
  }

  const extractDataAtSpecificYears = (
    data: EquityData[],
    years: number[]
  ): EquityData[] => {
    return data.filter((d) => years.includes(d.year));
  };
  const extractDataAtSpecificYearsBeforceTax = (
    data: GrowthData[],
    years: number[]
  ): GrowthData[] => {
    return data.filter((d) => years.includes(d.year));
  };

  // Years at intervals of 9 years
  const yearsAtIntervalsOf9Years = [1, 10, 20];

  // Usage example
  const dataAtSpecificYears = extractDataAtSpecificYears(
    GrowthChartFlow,
    yearsAtIntervalsOf9Years
  );
  const cashIntervaldata = extractDataAtSpecificYearsBeforceTax(
    CashChartFlow,
    yearsAtIntervalsOf9Years
  );
  // Now you can use dataAtSpecificYears array as needed in your TSX code
  // console.log("Data at specific years:");
  // console.log(dataAtSpecificYears);
  // const yearColumns = scenario.cashFlowYears.map((year) => (
  //   <StyledTableCell key={year.year}>{year.year}</StyledTableCell>
  // ));

  const beforeTaxCashFlow = scenario.cashFlowYears.map((year) =>
    year.beforeTaxCashFlow.toFixed(2)
  );
  let repaymentAmount = null;

  if (
    loanType === "principal-interest" &&
    "principalInterestRepayment" in scenario
  ) {
    repaymentAmount = scenario.principalInterestRepayment.toFixed(2);
  } else if (
    loanType === "interest-only" &&
    "InterestOnlyRepayment" in scenario
  ) {
    repaymentAmount = scenario.InterestOnlyRepayment.toFixed(2);
  }

  const afterTaxCashFlow = scenario.cashFlowYears.map((year) =>
    year.costIncomePw.toFixed(2)
  );
  const year = scenario.cashFlowYears.map((year) => year.year);
  const areaChartData: AreaChartData = {
    beforeTaxCashFlow: beforeTaxCashFlow,
    repaymentAmount: repaymentAmount,
    afterTaxCashFlow: afterTaxCashFlow,
    year: year,
  };
  const equity = scenario.cashFlowYears.map((year) => year.equity.toFixed(2));

  const convertToNumberArray = (arr: string[]): number[] =>
    arr.map((val) => parseFloat(val));
  const convertToNumber = (val: string | null): number =>
    val ? parseFloat(val) : 0; // Provide a default value if null

  ///equity Growth Projections
  const eaquityArray = convertToNumberArray(equity);
  const eaquityObjectsArray = eaquityArray.map((equity, index) => ({
    equity,
    year: areaChartData.year[index], // Adding year data
  }));


  /// Cashflow Projections
  const beforeTaxCashFlowArray = convertToNumberArray(beforeTaxCashFlow);
  const beforeTaxCashFlowObjectsArray = beforeTaxCashFlowArray.map(
    (beforeTaxCashFlow, index) => ({
      beforeTaxCashFlow,
      afterTaxCashFlow: convertToNumber(afterTaxCashFlow[index]),
      year: areaChartData.year[index], // Adding year data
    })
  );


  const formatData = (data: AreaChartData): FormattedData[] => {
    const beforeTaxCashFlow = convertToNumberArray(data.beforeTaxCashFlow);
    const repaymentAmount = convertToNumber(data.repaymentAmount);
    const afterTaxCashFlow = convertToNumberArray(data.afterTaxCashFlow);

    return data.year.map((year, index) => ({
      year: year,
      beforeTaxCashFlow: beforeTaxCashFlow[index],
      repaymentAmount: repaymentAmount,
      afterTaxCashFlow: afterTaxCashFlow[index],
    }));
  };

  const formattedData: FormattedData[] = formatData(areaChartData);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // Check if any of the required data is undefined or null before dispatching actions
    if (dispatch && formattedData && eaquityObjectsArray && beforeTaxCashFlowObjectsArray) {
      dispatch(updateAreaChartFlow(formattedData));
      dispatch(updateGrowthChart(eaquityObjectsArray));
      dispatch(updateCashflowChart(beforeTaxCashFlowObjectsArray));
    }
  }, [dispatch, scenario]);

  return (
    <Box
      alignContent={"center"}
      flexDirection={"column"}
      display={"flex"}
      justifyContent={"center"}
    >

      <Card
        className="table_responsive"
        sx={{
          padding: "20px 20px 20px 20px !important",
          marginBottom: "30px !important",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
          backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
        }}>

        <Typography variant="h5" align="left" pb={3} zIndex={1} gutterBottom>
          {title}
        </Typography>

        <TableContainer
          component={Paper}
          sx={{
            overflowX: "auto", // Add horizontal scrollbar if content exceeds container width
            backgroundColor: colors.primary[400],
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Table className="loan_table" aria-label="results table">
            <TableHead sx={{ backgroundColor: colors.primary[400] }}>
              <TableRow style={{ backgroundColor: colors.primary[400] }}>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    backgroundColor: colors.primary[400],
                  }}
                >
                  year
                </TableCell>
                {scenario.cashFlowYears.map((year) => (
                  <TableCell
                    key={year.year}
                    align="right"
                  >
                    {year.year}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ backgroundColor: colors.primary[400] }}>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  colSpan={scenario.cashFlowYears.length + 1}
                >
                  Capital Growth
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell >
                  Property Value
                </TableCell>
                {scenario.cashFlowYears.map((year) => (
                  <TableCell
                    key={year.year}
                    align="right"
                  >
                    ${formatNumberWithCommas(year?.propertyValue)}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>
                  Loan Amount
                </TableCell>
                {scenario.cashFlowYears.map((year) => (
                  <TableCell
                    key={year.year}
                    align="right"
                  >
                    {formatNumberWithCommas(year.loanAmount && Number(year.loanAmount))}
                  
                  </TableCell>
                ))}

              </TableRow>{" "}
              <TableRow>
                <TableCell>Equity</TableCell>
                {scenario.cashFlowYears.map((year) => (
                  <TableCell
                    key={year.year}
                    align="right"
                  >
                    ${formatNumberWithCommas(year.equity)}
                  </TableCell>
                ))}
              </TableRow>
              {/* Income */}
              <TableRow sx={{ backgroundColor: colors.primary[400] }}>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  colSpan={scenario.cashFlowYears.length + 1}
                >
                  Income
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Gross Rent
                </TableCell>
                {scenario.cashFlowYears.map((year) => (
                  <TableCell
                    key={year.year}
                    align="right"
                  >
                    ${formatNumberWithCommas(year.grossRent)}
                  </TableCell>
                ))}
              </TableRow>
              {/* Cash Deduction */}
              <TableRow sx={{ backgroundColor: colors.primary[400] }}>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  colSpan={scenario.cashFlowYears.length + 1}
                >
                  Cash Deduction
                </TableCell>
              </TableRow>
              {loanType === "principal-interest" &&
                "principalInterestRepayment" in scenario ? (
                <TableRow>
                  <TableCell>
                    Principal & Interest Repayment
                  </TableCell>
                  {scenario.cashFlowYears.map((year) => (
                  <TableCell align="right">
                    ${formatNumberWithCommas(year.PrincipalInterestRepayment)}
                  </TableCell>
                  ))}
                </TableRow>
              ) : loanType === "interest-only" &&
                "InterestOnlyRepayment" in scenario ? (
                <TableRow>
                  <TableCell >
                    Interest Repayment
                  </TableCell>
                  {scenario.cashFlowYears.map((year) => (
                  <TableCell align="right" >
                    ${formatNumberWithCommas(year.InterestOnlyRepayment)}
                  </TableCell>
                  ))}
                </TableRow>
              ) : null}
              <TableRow>
                <TableCell >
                  Rental Expenses
                </TableCell>
                {scenario.cashFlowYears.map((year) => (
                  <TableCell
                    key={year.year}
                    align="right"

                  >
                    ${formatNumberWithCommas(year.rentalExpenses)}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell >
                  Before Tax Cash Flow
                </TableCell>
                {scenario.cashFlowYears.map((year) => (
                  <TableCell
                    key={year.year}
                    align="right"

                  >
                    ${formatNumberWithCommas(year.beforeTaxCashFlow)}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell >
                  Cash/Income pw
                </TableCell>
                {scenario.cashFlowYears.map((year) => (
                  <TableCell
                    key={year.year}
                    align="right"

                  >
                    ${formatNumberWithCommas(year.costIncomePw)}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {/* <Box
        my={6}
        height="400px"
        alignContent={"center"}
        flexDirection={"column"}
        display={"flex"}
        justifyContent={"center"}
      > */}

      <Grid container spacing={2} columns={12} mb={0}>


        <Grid item xs={12}>
          <Typography variant="h5" align="left" pb={3} zIndex={1} gutterBottom>
            {title}
          </Typography>
          <Card
            className="card_verticalHeading"
            sx={{
              height: "380px",
              padding: "20px 20px 20px 30px !important",
              marginBottom: "15px !important",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
              backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
            }}>
            <Typography
              className="vertical_heading"
              variant="h5"
              mb={2}
              px={2}
              gutterBottom
              sx={{ color: colors.greenAccent[400], textAlign: "left" }}
            >
              Value
            </Typography>
            <Box height="300px">
              <AreaChartCash />
            </Box>
            <Typography
              variant="h5"
              mt={1}
              gutterBottom
              sx={{ color: colors.greenAccent[400], textAlign: "center" }}
            >
              Year
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" align="left" pb={3} zIndex={1} gutterBottom>
            Cashflow Projections
          </Typography>
          <Card
            className="card_verticalHeading"
            sx={{
              height: "280px",
              padding: "20px 5px 20px 30px !important",
              marginBottom: "30px !important",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
              backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
            }}>
            <Typography
              className="vertical_heading"
              variant="h5"
              mb={2}
              px={2}
              gutterBottom
              sx={{ color: colors.greenAccent[400], textAlign: "left" }}
            >
              Value
            </Typography>
            <Box height="200px">
              <AreaCashflowChart />
            </Box>
            <Typography
              variant="h5"
              mt={1}
              gutterBottom
              sx={{ color: colors.greenAccent[400], textAlign: "center" }}
            >
              Year
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" align="left" pb={3} zIndex={1} gutterBottom>
            Growth Projections
          </Typography>

          <Card
            className="card_verticalHeading"
            sx={{
              height: "280px",
              padding: "20px 5px 20px 30px !important",
              marginBottom: "30px !important",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
              backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
            }}>
            <Typography
              className="vertical_heading"
              variant="h5"
              mb={2}
              px={2}
              gutterBottom
              sx={{ color: colors.greenAccent[400], textAlign: "left" }}
            >
              Value
            </Typography>
            <Box height="200px">
              <AreaGrowthChart />
            </Box>
            <Typography
              variant="h5"
              mt={1}
              gutterBottom
              sx={{ color: colors.greenAccent[400], textAlign: "center" }}
            >
              Year
            </Typography>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} columns={12}>
        <Grid item xs={6}>

          <Card
            sx={{
              padding: "20px 20px 20px 20px !important",
              marginBottom: "20px !important",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
              backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
            }}>
            <div className="flex rounded bg-green-800 py-2">
              <h3 className="mx-3 px-2 text-white">Before Tax</h3>
            </div>
            {cashIntervaldata.map((year) => (
              <div className="flex flex-col">
                <div className="flex py-2 gap-4 justify-start items-start">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      width: "50px",
                      textAlign: "left",
                    }}
                  >
                    Year {year.year}
                  </Typography>

                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ textAlign: "center" }}
                  >
                    {year.beforeTaxCashFlow}
                  </Typography>
                </div>
              </div>
            ))}
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card
            sx={{
              padding: "20px 20px 20px 20px !important",
              marginBottom: "20px !important",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
              backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
            }}>
            <div className="flex bg-yellow-800 rounded py-2">
              <h3 className="mx-3 px-2 text-white"> Growth</h3>
            </div>
            {dataAtSpecificYears.map((year) => (
              <div className="flex flex-col">
                <div className="flex py-2 gap-4 justify-start items-start">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      width: "50px",
                      textAlign: "left",
                    }}
                  >
                    Year {year.year}
                  </Typography>

                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ textAlign: "center" }}
                  >
                    {year.equity}
                  </Typography>
                </div>
              </div>
            ))}
          </Card>
        </Grid>
      </Grid>

      {/* <Box
        sx={{ margin: "198px 0px 0px 0px" }}
        display="flex"
        justifyContent="center"
      >
        <Button variant="contained" color="secondary">
          Submit
        </Button>
      </Box> */}

    </Box>
  );
};

export default CashFlowScenario;