// import { Amplify, API, Auth } from 'aws-amplify';
// import awsconfig from '../aws-exports';

// Amplify.configure(awsconfig);

// type ApiMethod = 'get' | 'post' | 'put' | 'delete' | 'head' | 'patch';

// class AuthenticatedAPI {
//   static async request(apiName: string = 'InvestmentAPI', method: ApiMethod, path: string, options: any = {}) {

//     // const token = (await Auth.currentSession()).getIdToken().getJwtToken();
//     const user = await Auth?.currentAuthenticatedUser()
//     console.log('user1111111', user.attributes.name)

//     const token = user?.signInUserSession?.idToken?.jwtToken;
//     console.log('token', token)
//     // console.log('token',token)
//     options.headers = {
//       ...options.headers,
//       Authorization: `Bearer ${token}`,
//     };
//     console.log('options', options)
//     return (API as any)[method](apiName, path, options);
//   }
// }

// export default AuthenticatedAPI;













import { Amplify, API, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

type ApiMethod = 'get' | 'post' | 'put' | 'del' | 'head' | 'patch';

class AuthenticatedAPI {
  static async request(apiName: string = 'InvestmentAPI', method: ApiMethod, path: string, options: any = {}, withAuth: boolean = true) {
    if (withAuth) {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return (API as any)[method](apiName, path, options);
  }
}

export default AuthenticatedAPI;
